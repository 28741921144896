import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableFooter,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { EditOutlined } from "@mui/icons-material";

import { Column } from "../../../common/common.interface";
import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { getUsers } from "../actions";
import { IUser } from "../interface";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100vh - 115px)",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
  title: {
    position: "relative",
    padding: "16px",
  },
  new: {
    float: "right",
  },
}));

const getCell = (
  columnsData: Column[],
  user: any,
  handleEditAccount: any
): JSX.Element[] => {
  return columnsData.map((column: any) => {
    const value: any = user?.[column.id];
    return column.id === "actions" ? (
      <TableCell key={column.id} align={column.align}>
        <IconButton aria-label="edit" onClick={() => handleEditAccount(user)}>
          <EditOutlined />
        </IconButton>
      </TableCell>
    ) : (
      <TableCell key={column.id} align={column.align}>
        {value}
      </TableCell>
    );
  });
};

const columns: Column[] = [
  { id: "firstName", label: "First Name", minWidth: 170 },
  { id: "lastName", label: "Last Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "userType", label: "User Type", minWidth: 100 },
  {
    id: "actions",
    label: "",
    minWidth: 50,
    align: "center",
  },
];
const User = (): JSX.Element => {
  const classes = useStyles();
  const { users, total } = useSelector((s: IRootReducer) => ({
    users: s.user.users,
    total: s.user.total,
  }));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    dispatch(getUsers(skip, newPage * rowsPerPage + rowsPerPage));
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(getUsers(0, newRowsPerPage));
  };

  const handleEditAccount = (user: IUser): void => {
    dispatch(push(`/user/${user.id}`));
  };
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <Paper className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span>Users</span>
        <Button
          component={Link}
          to="/user/new"
          variant="contained"
          color="primary"
          className={classes.new}
        >
          Add User
        </Button>
      </Typography>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length ? (
              users.map((user) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={user?.id}>
                    {getCell(columns, user, handleEditAccount)}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default User;
