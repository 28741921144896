import { Dispatch } from "redux";
import { GET_APP_SETTINGS } from "./constants";
import { get } from "../../utils/requests";

export const fetchAppSettings =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: GET_APP_SETTINGS.SUCCESS,
    });

    try {
      const response = await get("/app-settings");

      dispatch({
        type: GET_APP_SETTINGS.SUCCESS,
        payload: { settings: response.data },
      });

      return response;
    } catch (err) {
      dispatch({
        type: GET_APP_SETTINGS.FAILED,
      });
      throw err;
    }
  };
