import { Dispatch } from "redux";
import {
  MENU_LIST,
  SETTING_LIST,
  SET_TAB_INDEX,
  TOGGLE_TAB,
  USER_LIST,
  SET_SIMPLE_VALUE,
} from "./constants";

export const getNavItem = (path: string): any => {
  const item = [...MENU_LIST, ...SETTING_LIST, ...USER_LIST].find(
    (o) => o.path === path
  );
  return item || {};
};

export const setTabIndex = (index: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_TAB_INDEX,
    tabIndex: index,
  });
};

export const toggleTab =
  (toggle: boolean, data: any) => (dispatch: Dispatch) => {
    dispatch({
      type: TOGGLE_TAB,
      toggleTab: toggle,
      tabs: data,
    });
  };

export const setSimpleValue = (key: string, value: any): any => ({
  type: SET_SIMPLE_VALUE,
  payload: { key, value },
});
