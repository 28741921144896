import { IAccount } from "modules/Accounts/interface";
import { ICampaign } from "modules/Campaigns/interface";
import { IUser } from "modules/User/interface";

export enum PhoneNumberStatus {
  Available = "available",
  Assigned = "assigned",
  WaitingForConfirmation = "pending",
  Released = "released",
  Rejected = "rejected",
  Cancel = "cancelled",
}

export const PHONE_STATUS_LABELS = {
  [PhoneNumberStatus.Available]: "Approved",
  [PhoneNumberStatus.Assigned]: "Use",
  [PhoneNumberStatus.WaitingForConfirmation]: "Approve",
  [PhoneNumberStatus.Released]: "Release",
  [PhoneNumberStatus.Rejected]: "Reject",
  [PhoneNumberStatus.Cancel]: "Cancel",
};
export interface PhonePayload {
  addressRequirement?: string;
  beta?: boolean;
  smsEnabled?: boolean;
  mmsEnabled?: boolean;
  faxEnabled?: boolean;
  voiceEnabled?: boolean;
  contains?: string;
  numberType?: string[];
  countryCode?: string;
  maxResults?: number;
}

export interface IPhoneNumber {
  id?: string;
  addressRequirements?: string;
  beta?: boolean;
  capabilities?: {
    voice?: boolean;
    SMS?: boolean;
    MMS?: boolean;
    fax?: boolean;
  };
  friendlyName?: string;
  isoCountry?: string;
  lata?: string;
  latitude?: number;
  locality?: string;
  longitude?: number;
  phoneNumber?: string;
  postalCode?: string;
  rateCenter?: string;
  region?: string;
  status: PhoneNumberStatus;
  reason?: string;
  purchased?: boolean;
}

export enum PhoneGroupStatus {
  Draft = "draft",
  AwaitingConfirmation = "awaiting",
  PendingApproval = "pending",
  Approved = "approved",
  OnHold = "on_hold",
  Disabled = "disabled",
  InUse = "used",
  Free = "free",
}

export const STATUS_LABELS = {
  [PhoneGroupStatus.Draft]: "set this phone group to draft?",
  [PhoneGroupStatus.Approved]: "approve this phone group?",
  [PhoneGroupStatus.AwaitingConfirmation]: "activate this phone group?",
  [PhoneGroupStatus.OnHold]: "reject this phone group?",
  [PhoneGroupStatus.PendingApproval]: "activate this phone group?",
  [PhoneGroupStatus.Disabled]: "disable this phone group?",
  [PhoneGroupStatus.InUse]: "use this phone group?",
  [PhoneGroupStatus.Free]: "free this phone group?",
};
export enum UseCase {
  NotifyUsers = "notifications",
  MarketService = "marketing",
  VerifyUsers = "verification",
  EngageDiscussion = "discussion",
  PollSurvey = "poll",
  NotListed = "undeclared",
}
export enum HandleRequestOption {
  DeferSendersWebhook = "defer_senders_webhook",
  DropMessage = "drop_message",
  Webhook = "webhook",
}
export interface IPhoneGroup {
  phoneNumbersCount?: number;
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletedAt?: Date;
  deletorId?: string;
  accountId: string;
  name: string;
  description?: UseCase;
  useCase?: string;
  useCaseDescription?: string;
  message1?: string;
  message2: string;
  statusCallback?: string;
  handleRequestOption: HandleRequestOption;
  inboundMethod?: string;
  inboundRequestUrl?: string;
  fallbackMethod?: string;
  fallbackUrl?: string;
  content?: {
    embededLinks?: boolean;
    includePhoneNumbers?: boolean;
  };
  sid?: string;
  status?: PhoneGroupStatus;
  reason?: string;
  phoneNumbers?: IPhoneNumber[];
  isUs: boolean;
  creator: IUser;
  updator: IUser;
  account?: IAccount;
  campaigns?: ICampaign[];
}

export type IPhoneGroupKey = keyof IPhoneGroup;

export interface IPhoneState {
  available: IPhoneNumber[];
  data: IPhoneNumber[];
  fetching: boolean;
  saving: boolean;
  errors: any;
  searchPayload: PhonePayload;
  phoneGroups: IPhoneGroup[];
  phoneGroup: IPhoneGroup | null;
  phoneGroupChanges: IPhoneGroupKey[];
  total: number;
}
