import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { IRootReducer } from "../../../reducers/reducer.interface";
import RolesTable from "./RolesTable";
import * as roleActions from "../actions";

const Roles = (): JSX.Element => {
  const { selectedAccount, roles } = useSelector((state: IRootReducer) => ({
    selectedAccount: state.session.selectedAccount,
    roles: state.roles.roles,
  }));

  // redux actions declarations
  const { getRoles } = bindActionCreators({ ...roleActions }, useDispatch());

  useEffect(() => {
    if (isEmpty(roles)) getRoles(selectedAccount?.id);
  }, [selectedAccount]);

  return <RolesTable />;
};

export default Roles;
