import {
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Avatar,
  Divider,
  Button,
  IconButton,
  Badge,
  Theme,
  Typography,
  Box,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useState, MouseEvent, useEffect, SyntheticEvent } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { IRootReducer } from "reducers/reducer.interface";
import {
  PhoneGroupStatus,
  PhoneNumberStatus,
  PHONE_STATUS_LABELS,
  STATUS_LABELS,
} from "modules/Phone/interface";
import {
  getPhoneGroups,
  getPhoneNumbers,
  update,
  updatePhoneGroup,
} from "modules/Phone/actions";
import { setSimpleValue } from "modules/Main";
import { orderBy } from "lodash";
import AuthenticateDialog from "components/AuthenticateDialog/AuthenticateDialog";
import { updateCampaign } from "modules/Campaigns";
import {
  CampaignStatus,
  CAMPAIGN_STATUS_LABEL,
} from "modules/Campaigns/interface";
import { push } from "connected-react-router";
import { fetchAccount } from "modules/Accounts/actions";
import { setSelectedAccount } from "modules/Auth/actions";
import { formatDate } from "utils/utilities";
import { deleteNotification, fetchNotifications } from "../actions";
import { INotification, NotificationType } from "../interface";

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    padding: 0,
    minWidth: "350px",
  },
  actions: {},
  actionsContent: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
  },
}));

export const getType = (
  status: PhoneNumberStatus | PhoneGroupStatus | CampaignStatus
): "accept" | "reject" => {
  return [
    PhoneNumberStatus.Rejected,
    PhoneGroupStatus.OnHold,
    PhoneGroupStatus.Draft,
    CampaignStatus.Rejected,
  ].includes(status)
    ? "reject"
    : "accept";
};
interface IProps {
  isMobile?: boolean;
}
const Notification = ({ isMobile = false }: IProps): JSX.Element => {
  const [dialog, setDialog] = useState({});
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { total, notifications, selectedAccount, user } = useSelector(
    (s: IRootReducer) => ({
      selectedAccount: s.session.selectedAccount,
      user: s.user.profile,
      notifications: s.notifications.notifications,
      total: s.notifications.total,
    }),
    shallowEqual
  );
  const classes = useStyles();
  const [notifAnchorEl, setNotifAnchorEl] = useState<null | HTMLElement>(null);
  const notifPopoverId = `notification-popover${isMobile ? "-=mobile" : ""}`;
  const handleNotificationPopoverOpen = (
    event: MouseEvent<HTMLElement>
  ): void => {
    setNotifAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (): void => {
    setNotifAnchorEl(null);
  };
  const isNotifOpen = Boolean(notifAnchorEl);
  const fetchPhoneNumbers = async (accountId: string): Promise<void> => {
    try {
      const params = {
        numberType: ["local"],
        countryCode: "CA",
        smsEnabled: true,
        faxEnabled: true,
        mmsEnabled: true,
        voiceEnabled: true,
      };
      await dispatch(getPhoneNumbers(params, accountId, "purchased"));
    } catch (err) {
      dispatch(
        setSimpleValue("snackbar", {
          message: "No result found",
          type: "error",
        })
      );
    }
  };
  const updatePhoneStatus = (
    notification: INotification,
    status: PhoneNumberStatus
  ): void => {
    setDialog({
      confirmTitle: `${PHONE_STATUS_LABELS[status]} phone number?`,
      confirmMessage: `Are you sure you want to ${PHONE_STATUS_LABELS[
        status
      ]?.toLowerCase()} this phone number?`,
      onConfirm: async (e: any, reason: any = {}) => {
        try {
          if (!notification.recordId || !notification?.accountId) return;
          await dispatch(
            update(notification.accountId, notification.recordId, {
              status,
              ...reason,
            })
          );
          fetchPhoneNumbers(notification?.accountId);
          if (notification?.id) dispatch(deleteNotification(notification.id));
          setDialog({});
        } catch (err) {
          dispatch(setSimpleValue("snackbar", { message: err.message }));
        }
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
      type: getType(status),
    });
  };
  const updatePhoneGroupStatus = (
    notification: INotification,
    status: PhoneGroupStatus
  ): void => {
    setDialog({
      confirmTitle: `${STATUS_LABELS[status]}`,
      confirmMessage: `Are you sure you want to ${STATUS_LABELS[
        status
      ]?.toLowerCase()}`,
      onConfirm: async (e: any, reason: any = {}) => {
        try {
          if (!notification.recordId || !notification?.accountId) return;
          await dispatch(
            updatePhoneGroup(notification.accountId, notification.recordId, {
              status,
              ...reason,
            })
          );
          const statuses: any = Object.keys(PhoneGroupStatus);
          await dispatch(
            getPhoneGroups(notification.accountId, 0, 10, statuses)
          );
          // if (notification?.id) dispatch(deleteNotification(notification.id));
          setDialog({});
        } catch (err) {
          dispatch(setSimpleValue("snackbar", { message: err.message }));
        }
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
      type: getType(status),
    });
  };
  const updateCampaignStatus = (
    notification: INotification,
    status: CampaignStatus
  ): void => {
    setDialog({
      confirmTitle: `${CAMPAIGN_STATUS_LABEL[status]} campaign?`,
      confirmMessage: `Are you sure you want to ${CAMPAIGN_STATUS_LABEL[
        status
      ]?.toLowerCase()} this campaign?`,
      onConfirm: async (e: SyntheticEvent, reasonObj: any) => {
        try {
          if (!notification.recordId || !notification?.accountId) return;
          await dispatch(
            updateCampaign({
              accountId: notification.accountId || "",
              id: notification.recordId,
              status,
              ...reasonObj,
            })
          );
          if (notification?.id) dispatch(deleteNotification(notification.id));
          setDialog({});
        } catch (err) {
          dispatch(setSimpleValue("snackbar", { message: err.message }));
        }
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
      type: getType(status),
    });
  };
  const handleAccept = (notification: INotification) => () => {
    if (notification.type === NotificationType.PhoneCreation) {
      updatePhoneStatus(notification, PhoneNumberStatus.Available);
    } else if (notification.type === NotificationType.PhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.PendingApproval);
    } else if (notification.type === NotificationType.ApprovePhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.Approved);
    } else if (notification.type === NotificationType.CampaignCreation) {
      updateCampaignStatus(notification, CampaignStatus.Ready);
    }
  };
  const handleReject = (notification: INotification) => () => {
    if (notification.type === NotificationType.PhoneCreation) {
      updatePhoneStatus(notification, PhoneNumberStatus.Rejected);
    } else if (notification.type === NotificationType.PhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.OnHold);
    } else if (notification.type === NotificationType.ApprovePhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.Draft);
    } else if (notification.type === NotificationType.CampaignCreation) {
      updateCampaignStatus(notification, CampaignStatus.Rejected);
    }
  };
  const handleDelete = (notification: INotification): void => {
    if (notification?.id) dispatch(deleteNotification(notification.id));
  };
  const handleSelectNotification =
    (notification: INotification) => async () => {
      if (!notification.accountId || !notification.recordId) return;
      if (notification.accountId !== selectedAccount?.id) {
        const account: any = await dispatch(
          fetchAccount(notification.accountId)
        );
        dispatch(setSelectedAccount(account));
      }
      switch (notification.type) {
        case NotificationType.CampaignCreation:
        case NotificationType.ApproveCampaign:
        case NotificationType.RejectCampaign:
          dispatch(push(`/campaigns/${notification.recordId}`));
          break;
        case NotificationType.PhoneGroup:
        case NotificationType.ApprovePhoneGroup:
        case NotificationType.ApprovedPhoneGroup:
        case NotificationType.RejectPhoneGroup:
          dispatch(push(`/phone/groups/${notification.recordId}`));
          break;
        case NotificationType.PhoneCreation:
        case NotificationType.ApprovePhoneCreation:
        case NotificationType.RejectPhoneCreation:
        default:
          dispatch(push("/phone/purchased"));
      }
      handleMenuClose();
    };
  const handleScroll = (event: SyntheticEvent): void => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
      listboxNode.scrollHeight
    ) {
      const rowsPerPage = 10;
      const skip = (page + 1) * rowsPerPage;
      setPage(page + 1);
      dispatch(fetchNotifications(rowsPerPage, skip));
    }
  };

  const handleClearAll = async (): Promise<void> => {
    if (notifications.length > 0) {
      await dispatch(deleteNotification("all"));
      setPage(0);
      dispatch(fetchNotifications());

      dispatch(
        setSimpleValue("snackbar", {
          message: "Notifications cleared successfully",
          type: "success",
        })
      );
    }
  };

  useEffect(() => {
    if (notifications.length < 10 && total > 10) {
      const rowsPerPage = 10;
      dispatch(fetchNotifications(rowsPerPage, notifications.length));
    }
  }, [notifications]);
  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);
  return (
    <>
      <IconButton
        size="large"
        aria-label={`show ${total} new notifications`}
        color="inherit"
        aria-controls={notifPopoverId}
        arie-haspopup="true"
        onClick={handleNotificationPopoverOpen}
      >
        <Badge badgeContent={notifications?.length ? total : 0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        classes={{ paper: classes.popover }}
        id={notifPopoverId}
        open={isNotifOpen}
        anchorEl={notifAnchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 400,
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
          onScroll={handleScroll}
        >
          <li key="section">
            <ul>
              <ListSubheader component="div" id="nested-list-subheader">
                Notifications
                <div style={{ position: "absolute", right: "0", top: "5px" }}>
                  <Button onClick={handleClearAll} variant="text">
                    Clear All
                  </Button>
                  <Button
                    onClick={handleMenuClose}
                    component={Link}
                    to="/notifications"
                    variant="text"
                  >
                    View All
                  </Button>
                </div>
              </ListSubheader>
              {notifications.length ? (
                orderBy<INotification>(
                  notifications,
                  ["createdAt"],
                  ["desc"]
                ).map((notification: INotification) => {
                  const hasAction = [
                    NotificationType.PhoneCreation,
                    NotificationType.PhoneGroup,
                    NotificationType.ApprovePhoneGroup,
                    NotificationType.CampaignCreation,
                  ].includes(notification.type);
                  return (
                    <>
                      <Divider />
                      <ListItem
                        onClick={handleSelectNotification(notification)}
                        button
                        alignItems="flex-start"
                        style={{ paddingBottom: hasAction ? "35px" : "0px" }}
                        classes={{
                          root: classes.actions,
                        }}
                        secondaryAction={
                          <IconButton
                            size="small"
                            edge="end"
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(notification);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            {
                              [NotificationType.PhoneCreation]:
                                "Phone Creation",
                              [NotificationType.PhoneGroup]: "Phone Group",
                              [NotificationType.CampaignCreation]:
                                "Campaign Creation",
                              [NotificationType.ApprovePhoneCreation]:
                                "Approve Phone Creation",
                              [NotificationType.ApprovePhoneGroup]:
                                "Approve Phone Group",
                              [NotificationType.ApprovedPhoneGroup]:
                                "Approved Phone Group",
                              [NotificationType.ApproveCampaign]:
                                "Approve Campaign",
                              [NotificationType.RejectPhoneCreation]:
                                "Reject Phone Creation",
                              [NotificationType.RejectPhoneGroup]:
                                "Reject Phone Group",
                              [NotificationType.RejectCampaign]:
                                "Reject Campaign",
                            }[notification.type]
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {notification.mentioned?.firstName}{" "}
                                {notification.mentioned?.lastName}
                              </Typography>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: notification.message,
                                }}
                              />{" "}
                              {`${formatDate(
                                notification?.createdAt
                              ).fromNow()}`}
                            </>
                          }
                        />
                        {hasAction && (
                          <Box className={classes.actionsContent}>
                            <Button
                              size="small"
                              color="error"
                              onClick={handleReject(notification)}
                            >
                              Reject
                            </Button>
                            <Button
                              size="small"
                              onClick={handleAccept(notification)}
                            >
                              Accept
                            </Button>
                          </Box>
                        )}
                      </ListItem>
                    </>
                  );
                })
              ) : (
                <ListItem
                  sx={{
                    padding: "35px 40px",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{ display: "block" }}
                    variant="h6"
                    color="text.primary"
                  >
                    No Notification Yet
                  </Typography>
                  <Typography
                    sx={{ display: "block" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Stay tuned! Notifications about your activity will show up
                    here.
                  </Typography>
                </ListItem>
              )}
            </ul>
          </li>
        </List>
      </Popover>
      <AuthenticateDialog {...dialog} />
    </>
  );
};

export default Notification;
