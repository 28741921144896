import { Dispatch } from "redux";
import { LOADING_ACCOUNT, GET_ACCOUNTS } from "./constants";
import { del, get, patch, post } from "../../utils/requests";
import { IAccount, IAccountAction } from "./interface";

export const setAccount = (account: IAccount | null): IAccountAction => ({
  type: LOADING_ACCOUNT.SUCCESS,
  payload: { account },
});
export const createAccount =
  (account: Partial<IAccount>) =>
  async (dispatch: Dispatch): Promise<IAccount> => {
    dispatch({
      type: LOADING_ACCOUNT.REQUEST,
    });

    try {
      const response = await post("/accounts", account);
      dispatch(setAccount(response.data));
      return response.data;
    } catch (err) {
      dispatch({
        type: LOADING_ACCOUNT.FAILED,
      });
      throw err;
    }
  };
export const updateAccount =
  (id: string, account: Partial<IAccount>) =>
  async (dispatch: Dispatch): Promise<IAccount> => {
    dispatch({
      type: LOADING_ACCOUNT.REQUEST,
    });

    try {
      const response = await patch(`/accounts/${id}`, account);
      dispatch(setAccount(response.data));
      return response.data;
    } catch (err) {
      dispatch({
        type: LOADING_ACCOUNT.FAILED,
      });
      throw err;
    }
  };
export const deleteAccount =
  (id: string) =>
  async (dispatch: Dispatch): Promise<IAccount> => {
    dispatch({
      type: LOADING_ACCOUNT.REQUEST,
    });

    try {
      const response = await del(`/accounts/${id}`);
      return response.data;
    } catch (err) {
      dispatch({
        type: LOADING_ACCOUNT.FAILED,
      });
      throw err;
    }
  };
export const fetchAccount =
  (id: string) =>
  async (dispatch: Dispatch): Promise<IAccount> => {
    dispatch({
      type: LOADING_ACCOUNT.REQUEST,
    });

    try {
      const response = await get(`/accounts/${id}`);
      dispatch(setAccount(response.data));
      return response.data;
    } catch (err) {
      dispatch({
        type: LOADING_ACCOUNT.FAILED,
      });
      throw err;
    }
  };

export const getAccounts =
  (skip = 0, take = 10) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ACCOUNTS.REQUEST,
    });
    try {
      const params = { skip, take };
      const response = await get("/accounts", params);

      dispatch({
        type: GET_ACCOUNTS.SUCCESS,
        payload: { data: response.data },
      });
    } catch (err) {
      dispatch({
        type: GET_ACCOUNTS.FAILED,
      });
      throw err;
    }
  };
