import { CommonAction } from "common/common.interface";
import { pick } from "lodash";
// import moment from "moment-timezone";
import { IRootReducer } from "reducers/reducer.interface";
import { Dispatch } from "redux";
import { patch, get, post, del } from "../../utils/requests";
import {
  FETCH_NEXT_CAMPAIGN,
  FETCH_CAMPAIGN,
  FETCH_CAMPAIGNS,
  SAVING,
  DELETE_CAMPAIGN,
  SET_CHILDREN,
  SET_TEMPLATES,
  SET_CAMPAIGN_CHANGES,
} from "./constants";
import { CampaignType, ICampaign, ICampaignAction } from "./interface";

export const setCampaigns = (
  data: ICampaign[],
  total: number
): ICampaignAction => ({
  type: FETCH_CAMPAIGNS.SUCCESS,
  payload: {
    data,
    total,
  },
});

export const setCampaign = (
  data?: ICampaign,
  clearChanges = false,
  isParent = false
): ICampaignAction => ({
  type: FETCH_CAMPAIGN.SUCCESS,
  payload: {
    data,
    isParent,
    clearChanges,
  },
});
export const setNextCampaign = (data: ICampaign): ICampaignAction => ({
  type: FETCH_NEXT_CAMPAIGN.SUCCESS,
  payload: {
    data,
  },
});
export const removeCampaign = (id: string): ICampaignAction => ({
  type: DELETE_CAMPAIGN.SUCCESS,
  payload: {
    id,
  },
});

export const fetchCampaigns =
  ({
    accountId = "all",
    take = 10,
    skip = 0,
    campaignType = CampaignType.OnDemand,
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FETCH_CAMPAIGNS.REQUEST });
      const response = await get(`/accounts/${accountId}/campaigns`, {
        take,
        skip,
        campaignType,
      });

      dispatch(setCampaigns(response.data.results, response.data.total));
    } catch {
      dispatch({ type: FETCH_CAMPAIGNS.FAILED });
    }
  };

export const setChildren = (
  id: string,
  childId: string,
  campaigns: ICampaign[]
): ICampaignAction => ({
  type: SET_CHILDREN.SUCCESS,
  payload: { id, childId, campaigns },
});

export const getTemplates =
  () =>
  async (dispatch: Dispatch): Promise<ICampaign[] | null> => {
    try {
      dispatch({ type: SET_TEMPLATES.REQUEST });
      const response = await get(`/campaign-templates`);

      dispatch({
        type: SET_TEMPLATES.SUCCESS,
        payload: { data: response.data },
      });
      return response.data;
    } catch {
      dispatch({ type: SET_TEMPLATES.FAILED });
      return null;
    }
  };
export const getChildren =
  (accountId: string, rootId: string, id: string) =>
  async (dispatch: Dispatch): Promise<ICampaign[] | null> => {
    try {
      dispatch({ type: SET_CHILDREN.REQUEST });
      const response = await get(
        `/accounts/${accountId}/campaigns/${id}/children`
      );

      dispatch(setChildren(rootId, id, response.data));
      return response.data;
    } catch {
      dispatch({ type: SET_CHILDREN.FAILED });
      return null;
    }
  };

export const setChanges = (changes: any): CommonAction => ({
  type: SET_CAMPAIGN_CHANGES,
  payload: { data: changes },
});
export const fetchCampaign =
  (accountId: string, id: string, isParent = false) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FETCH_CAMPAIGN.REQUEST });
      const response = await get(`/accounts/${accountId}/campaigns/${id}`);

      dispatch(setCampaign(response.data, true, isParent));
    } catch {
      dispatch({ type: FETCH_CAMPAIGN.FAILED });
    }
  };

export const fetchNextCampaign =
  (accountId: string, id: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FETCH_NEXT_CAMPAIGN.REQUEST });
      const response = await get(
        `/accounts/${accountId}/campaigns/${id}/nextstep`
      );

      dispatch(setNextCampaign(response.data));
    } catch {
      dispatch({ type: FETCH_NEXT_CAMPAIGN.FAILED });
    }
  };

export const createCampaign =
  (campaign: ICampaign) =>
  async (dispatch: Dispatch): Promise<ICampaign | null> => {
    try {
      dispatch({ type: SAVING.REQUEST });
      const response = await post(
        `/accounts/${campaign.accountId}/campaigns`,
        campaign
      );

      dispatch(setCampaign(response.data, true));
      return response.data;
    } catch (err) {
      dispatch({ type: SAVING.FAILED, payload: { errors: err.data } });
      throw err;
    }
  };

export const updateCampaign =
  (campaign: Partial<ICampaign>) =>
  async (
    dispatch: Dispatch,
    getState: () => IRootReducer
  ): Promise<ICampaign | null> => {
    try {
      const { campaignChanges } = getState().campaigns;
      const data = pick(campaign, [...campaignChanges, "reason", "status"]);
      if (data.startDate === null) {
        data.endDate = null;
      }
      // if (data.endDate) {
      //   data.endDate = moment(data.endDate, moment.tz.guess()).toDate();
      // }
      dispatch({ type: SAVING.REQUEST });
      const response = await patch(
        `/accounts/${campaign.accountId}/campaigns/${campaign.id}`,
        data
      );

      dispatch(setCampaign(response.data, true));
      return response.data;
    } catch (err) {
      dispatch({ type: SAVING.FAILED, payload: { errors: err.data } });
      throw err;
    }
  };

export const deleteCampaign =
  (accountId: string, id: string) =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      if (id) {
        dispatch({ type: DELETE_CAMPAIGN.REQUEST });
        await del(`/accounts/${accountId}/campaigns/${id}`);

        dispatch(removeCampaign(id));
        return true;
      }
      return false;
    } catch (err) {
      dispatch({ type: DELETE_CAMPAIGN.FAILED, payload: { errors: err.data } });
      return false;
    }
  };

export const savingFailed =
  (payload: Record<string, any>) => (dispatch: Dispatch) =>
    dispatch({ type: SAVING.FAILED, payload: { errors: payload } });
