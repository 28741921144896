import { useState, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { formatDate } from "utils/utilities";
import { Column } from "../../../common/common.interface";
import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { fetchBlackListed } from "../actions";
import { useWindowSize } from "../../../utils/useWindowSize";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
}));

const List = (): JSX.Element => {
  const classes = useStyles();
  const size = useWindowSize();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { blacklisted, total, loading, error } = useSelector(
    (state: IRootReducer) => state.blacklisted
  );
  const dispatch = useDispatch();
  const { selectedAccount } = useSelector((state: IRootReducer) => ({
    selectedAccount: state.session.selectedAccount,
  }));

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    dispatch(
      fetchBlackListed({
        skip,
        take: rowsPerPage,
        accountId: selectedAccount?.id,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(
      fetchBlackListed({
        skip: 0,
        take: newRowsPerPage,
        accountId: selectedAccount?.id,
      })
    );
  };

  useEffect(() => {
    if (selectedAccount?.id) {
      dispatch(
        fetchBlackListed({
          skip: 0,
          take: rowsPerPage,
          accountId: selectedAccount.id,
        })
      );
    }
  }, [selectedAccount, dispatch]);
  const getCell = (columnsData: Column[], row: any): JSX.Element[] => {
    return columnsData.map((column: any) => {
      let value: any = row?.[column.id];

      if (column.id === "createDateTime")
        value = formatDate(row.createDateTime).format("MM/DD/YYYY HH:mm:ss");

      if (column.id === "creator")
        value =
          row.creator && `${row.creator?.firstName} ${row.creator?.lastName}`;
      return (
        <TableCell key={column.id} align={column.align}>
          {value}
        </TableCell>
      );
    });
  };

  const columns: Column[] = [
    { id: "phoneNumber", label: "Phone Number", minWidth: 170 },
    { id: "createdAt", label: "Date Created", minWidth: 100 },
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {blacklisted.length ? (
              blacklisted.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                    {getCell(columns, row)}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default List;
