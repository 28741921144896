import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";

function Transition(props: any): JSX.Element {
  return <Slide direction="up" {...props} />;
}

interface ConfirmDialogProps {
  confirmTitle?: string;
  confirmMessage?: string;
  onConfirm?: (e: SyntheticEvent) => Promise<any> | any;
  onClose?: (boo: boolean) => void;
  isDialogOpen?: boolean;
  hideButton?: boolean;
  acceptText?: string;
  cancelText?: string;
  className?: string;
  onCancel?: () => void;
}
const ConfirmDialog = ({
  confirmTitle,
  confirmMessage,
  onConfirm,
  onClose,
  isDialogOpen,
  hideButton,
  acceptText,
  cancelText,
  className,
  onCancel,
}: ConfirmDialogProps): JSX.Element | null => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (!isDialogOpen) {
    return null;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isDialogOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="confirm-dialog"
      aria-describedby="confirm-dialog"
      className={className}
    >
      <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog">
          {confirmMessage}
        </DialogContentText>
      </DialogContent>
      {!hideButton && (
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (onCancel) onCancel();
              else if (onClose) {
                onClose(true);
              }
            }}
          >
            {cancelText}
          </Button>

          {onConfirm && (
            <Button
              disabled={loading}
              onClick={async (e: SyntheticEvent) => {
                e.stopPropagation();
                const promise = onConfirm && onConfirm(e);

                if (promise?.then) {
                  setLoading(true);
                  await promise;
                  setLoading(false);
                }
              }}
            >
              {acceptText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  confirmTitle: "",
  confirmMessage: "",
  onConfirm: undefined,
  onClose: undefined,
  isDialogOpen: false,
  hideButton: false,
  acceptText: "Yes",
  cancelText: "Cancel",
  className: undefined,
  onCancel: undefined,
};

export default ConfirmDialog;
