import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment-timezone";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JSONViewer from "../../../components/JSONViewer";
import { useWindowSize } from "../../../utils/useWindowSize";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { fetchCampaignRequests } from "../actions";

import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";
import { Column } from "../../../common/common.interface";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
}));

interface Param {
  id?: string;
}

interface IProps {
  logType?: "inbounds" | "outbounds";
}

const CampaignRequests = ({ logType }: IProps): JSX.Element => {
  const params: Param = useParams();
  const [dialog] = useState({});
  const classes = useStyles();
  const size = useWindowSize();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageSize = Math.floor(((size?.height || 0) - 220) / 52);
  const {
    campaignRequests = [],
    selectedAccount,
    total,
  } = useSelector(
    (state: IRootReducer) => ({
      campaignRequests: state.campaignRequest.data,
      selectedAccount: state.session.selectedAccount,
      total: state.campaignRequest.total,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const columns: Column[] = [
    {
      id: "RecipientPhoneNumber",
      label: "Phone Number",
      width: 200,
      align: "right",
    },
    { id: "parameters", label: "Parameters", minWidth: 200, align: "right" },
    { id: "referenceNo", label: "Reference No.", width: 180, align: "right" },
    {
      id: "currentCampaignStep",
      label: "Current Process",
      width: 180,
      align: "right",
    },
    { id: "createDateTime", label: "Date Created", width: 160, align: "right" },
    { id: "status", label: "Status", width: 160, align: "right" },
    {
      id: "action",
      label: "Action",
      width: 160,
      align: "right",
    },
  ];

  const handleChangePage = (event: unknown, newPage: number): void => {
    if (!selectedAccount?.id) return;
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    dispatch(
      fetchCampaignRequests({
        accountId: selectedAccount.id,
        campaignId: params.id,
        take: rowsPerPage,
        skip,
      })
    );
  };
  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    if (!selectedAccount?.id) return;
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(
      fetchCampaignRequests({
        skip: 0,
        take: newRowsPerPage,
        accountId: selectedAccount?.id,
        logType,
      })
    );
  };
  useEffect(() => {
    if (selectedAccount?.id && pageSize > 0 && params.id) {
      dispatch(
        fetchCampaignRequests({
          accountId: selectedAccount.id,
          campaignId: params.id,
          take: pageSize,
        })
      );
    } else if (selectedAccount?.id && logType && pageSize > 0) {
      dispatch(
        fetchCampaignRequests({
          accountId: selectedAccount.id,
          logType,
          take: pageSize,
        })
      );
    }
  }, [selectedAccount, logType, pageSize]);
  const getCell = (
    columnsData: Column[],
    webhookRequest: any
  ): JSX.Element[] => {
    return columnsData.map((column: any) => {
      let value: any = webhookRequest?.[column.id];

      if (column.id === "createDateTime")
        value = moment(webhookRequest.createDateTime).format(
          "MM/DD/YYYY HH:mm:ss"
        );

      if (column.id === "creator")
        value =
          webhookRequest.creator &&
          `${webhookRequest.creator?.firstName} ${webhookRequest.creator?.lastName}`;

      if (["parameters"].includes(column.id)) {
        return (
          <TableCell key={column.id} align={column.align}>
            <JSONViewer value={JSON.stringify(value, undefined, 2)} />
          </TableCell>
        );
      }
      return (
        <TableCell key={column.id} align={column.align}>
          {value}
        </TableCell>
      );
    });
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {campaignRequests.length ? (
              campaignRequests.map((webhookRequest) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={webhookRequest?.id}
                  >
                    {getCell(columns, webhookRequest)}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows colSpan={7} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmDialog {...dialog} />
    </Paper>
  );
};

CampaignRequests.defaultProps = {
  logType: undefined,
};

export default CampaignRequests;
