import { ChangeEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import moment from "moment-timezone";
import { startCase } from "lodash";
import JSONViewer from "../../../components/JSONViewer";
import { Column } from "../../../common/common.interface";
import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { fetchEchoWebhooks } from "../actions";
import { useWindowSize } from "../../../utils/useWindowSize";
import { MessageType } from "../interface";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
}));

const List = (): JSX.Element => {
  const classes = useStyles();
  const size = useWindowSize();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data, total, loading, error } = useSelector(
    (state: IRootReducer) => state.echowebhooks
  );
  const dispatch = useDispatch();

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    dispatch(
      fetchEchoWebhooks({
        skip,
        take: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(
      fetchEchoWebhooks({
        skip: 0,
        take: newRowsPerPage,
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchEchoWebhooks({
        skip: 0,
        take: rowsPerPage,
      })
    );
  }, [dispatch]);

  const getCell = (
    columnsData: Column[],
    webhookRequest: any
  ): JSX.Element[] => {
    return columnsData.map((column: any) => {
      let value: any = webhookRequest?.[column.id];

      if (column.id === "createDateTime") {
        value = moment(webhookRequest.createDateTime).format(
          "MM/DD/YYYY HH:mm:ss"
        );
      }

      if (column.id === "messageType") {
        value = startCase(
          MessageType[webhookRequest.messageType]
        ).toUpperCase();
      }

      if (column.id === "message") {
        return (
          <TableCell key={column.id} align={column.align}>
            <JSONViewer value={JSON.stringify(value, undefined, 2)} />
          </TableCell>
        );
      }
      return (
        <TableCell key={column.id} align={column.align}>
          {value}
        </TableCell>
      );
    });
  };
  const columns: Column[] = [
    { id: "smsPlanId", label: "SMS PlanId", minWidth: 100 },
    { id: "messageType", label: "Message Type", minWidth: 100 },
    { id: "message", label: "Message", minWidth: 400 },
    { id: "createDateTime", label: "createDateTime", minWidth: 100 },
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map((logs) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={logs.id}>
                    {getCell(columns, logs)}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows colSpan={7} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        colSpan={7}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default List;
