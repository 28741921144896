import {
  CircularProgress,
  Typography,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Dialog,
} from "@mui/material";
import { instance } from "utils/requests";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setSimpleValue } from "modules/Main";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { IRootReducer } from "reducers/reducer.interface";
import CustomInput from "components/CustomInput";
import { KeyValue } from "common/common.interface";
import { getContacts, set } from "../actions";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 115px)",
    display: "flex",
    flexDirection: "column",
    margin: 24,
  },
  container: {
    flex: "1 1 0%",
    width: "500px",
    height: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dropzone: {
    padding: "24px",
    border: "1px dashed #CCC",
    textAlign: "center",
    height: "100%",
    width: "100%",
  },
  new: {
    float: "right",
    marginLeft: 8,
  },
});

const UploadContact = ({
  id,
  label,
}: {
  id?: string;
  label?: string;
}): JSX.Element => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<KeyValue>({});
  const [formData, setFormData] = useState<any>({});
  const [index, setIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { selectedAccount } = useSelector((state: IRootReducer) => ({
    selectedAccount: state.session.selectedAccount,
  }));
  const accountId = selectedAccount?.id || "all";
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    accept: ".csv, text/csv",
    maxSize: 5000000,
    onDrop: async (files: Array<File>) => {
      setFormData((prev: any) => ({ ...prev, file: files[0] }));
    },
  });
  const download = (e: any): void => {
    e.stopPropagation();
    const element = document.createElement("a");

    element.setAttribute(
      "href",
      `data:text/csv;charset=utf-8,${encodeURIComponent(
        "firstName,lastName,phoneNumber,email\ncontact1,contact1,09167812741,contact1@ccds.ca\ncontact2,contact2,09167812742,contact2@ccds.ca\ncontact3,contact3,09167812743,contact3@ccds.ca\ncontact4,contact4,09167812744,contact4@ccds.ca\ncontact5,contact5,09167812745,contact5@ccds.ca\ncontact6,contact6,09167812746,contact6@ccds.ca\ncontact7,contact7,09167812747,contact7@ccds.ca\ncontact8,contact8,09167812748,contact8@ccds.ca\ncontact9,contact9,09167812749,contact9@ccds.ca\ncontact10,contact10,091678127410,contact210@ccds.ca"
      )}`
    );
    element.setAttribute("download", "sample.csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  const handleCancel = (): void => {
    setOpen(false);
    setErrors({});
    setFormData({});
    setIndex(0);
  };

  const handleUpload = (): void => {
    setOpen(true);
  };

  const handleSave = async (): Promise<void> => {
    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const fd = new FormData();
      if (id) fd.set("id", id);
      fd.set("file", formData.file);
      fd.set("name", formData.name);
      fd.set("description", formData?.description || "");
      setUploading(true);
      const response = await instance.post(
        `accounts/${accountId}/list/upload`,
        fd,
        config
      );
      setUploading(false);
      dispatch(set(response.data));

      dispatch(getContacts(accountId));
      dispatch(
        setSimpleValue("snackbar", {
          message: "Uploaded successfully",
          type: "success",
        })
      );
      setOpen(false);
      setErrors({});
      setFormData({});
      setIndex(0);
    } catch (err) {
      setUploading(false);
      dispatch(
        setSimpleValue("snackbar", {
          message: err?.response?.data?.message || "Upload Failed",
          type: "error",
        })
      );
      setErrors(err);
    }
  };
  const steps = [
    {
      btnNextTitle: id ? "Save" : "Next",
      btnNext: id
        ? handleSave
        : () => {
            setIndex(1);
          },
      disabled: () => !formData.file || uploading,
      title: "Upload CSV File",
    },
    {
      btnNextTitle: "Save",
      btnNext: handleSave,
      disabled: () => !formData.name,
      title: "List Details",
    },
  ];
  const handleOnChange =
    (name: string) =>
    ({ target }: any) => {
      const { value } = target || {};
      setFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    };
  const renderContent = (): JSX.Element => {
    switch (index) {
      case 1:
        return (
          <DialogContent dividers>
            <section className={classes.container}>
              <CustomInput
                size="small"
                label="Friendly Name"
                placeholder="My list"
                type="text"
                value={formData?.name}
                fullWidth
                onChange={handleOnChange("name")}
                error={!!errors?.name}
                helperText={`${errors?.name || ""}`}
                style={{ marginBottom: 8 }}
              />
              <CustomInput
                size="small"
                label="Description"
                placeholder="Add description here..."
                type="text"
                value={formData?.description}
                fullWidth
                onChange={handleOnChange("description")}
                error={!!errors?.description}
                helperText={`${errors?.description || ""}`}
                multiline
                rows={8}
              />
            </section>
          </DialogContent>
        );
      default:
        return (
          <DialogContent dividers>
            <section className={classes.container}>
              {!formData.file ? (
                <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  <p>
                    Drag &apos;n&apos; drop some file here, or click to select
                    file
                  </p>
                  {isDragAccept && (
                    <p>Release the file to start uploading...</p>
                  )}
                  {isDragReject && <p>This file is invalid</p>}
                  {!isDragActive && <p>(Only *.csv file will be accepted)</p>}
                  {!isDragActive && (
                    <p>
                      <strong>Maximum File size is 5MB</strong>
                    </p>
                  )}
                </div>
              ) : (
                <section className={classes.container}>
                  {" "}
                  <Typography>File Selected: {formData.file.name}</Typography>
                  <Button
                    onClick={() =>
                      setFormData((prev: any) => ({ ...prev, file: null }))
                    }
                  >
                    Clear Selected File
                  </Button>
                </section>
              )}
            </section>
          </DialogContent>
        );
    }
  };

  return (
    <>
      <Button variant="contained" onClick={download}>
        Download Sample File
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.new}
        onClick={handleUpload}
      >
        {label || "New Contact List"}
      </Button>
      <Dialog
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCancel}
        >
          <FontAwesomeIcon
            icon={faFileCsv}
            size="1x"
            fixedWidth
            style={{ marginRight: 8 }}
          />
          {steps[index].title}
        </BootstrapDialogTitle>
        {renderContent()}
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 8 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Typography style={{ fontWeight: "bold", flex: "1 1 0%" }} />
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 8 }}
            onClick={steps[index].btnNext}
            disabled={steps[index].disabled() || uploading}
            startIcon={
              uploading && <CircularProgress color="success" size="20px" />
            }
          >
            {uploading ? "Uploading" : steps[index].btnNextTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadContact;
