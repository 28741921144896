import { Action } from "redux";
import { Payload } from "common/common.interface";
import { IAccountUserPermission } from "../AccountUserPermissions/interface";
import { IAccount } from "../Accounts/interface";
import { IRole } from "../Roles/interface";
import { IUser } from "../User/interface";

export enum AccountUserType {
  User = "user",
  Manager = "manager",
}
export interface IAccountUser {
  id?: string;
  createDateTime?: Date;
  creatorId?: string;
  userId?: string;
  accountId: string;
  roleId: string;
  isActive?: boolean;
  isOwner?: boolean;
  role?: IRole;
  userType: AccountUserType;
  reportingId?: string;
  phoneNumber?: string;
  account?: IAccount;
  user?: IUser | undefined;
  accountUserPermissions?: IAccountUserPermission[];
}

export interface IAccountUsersState {
  accountUsers: IAccountUser[];
  accountUser: IAccountUser | null;
  isFetching: boolean;
}

export interface IAccountUsersAction extends Action {
  payload: Payload;
}
