import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Box,
  Alert,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MouseEvent, useState, useRef, useEffect, SyntheticEvent } from "react";
import { IRootReducer } from "reducers/reducer.interface";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AccountUserType } from "modules/AccountUsers/interface";
import { Role } from "modules/Permissions/interface";
import { ManagerPermissions } from "modules/ManagerBasedPermissions/interface";
import { useElementSize } from "usehooks-ts";
import AuthenticateDialog from "components/AuthenticateDialog/AuthenticateDialog";
import { setSimpleValue } from "modules/Main/actions";
import { uniq } from "lodash";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import {
  CampaignStatus,
  CampaignType,
  CAMPAIGN_STATUS_CONFIRM_TITLE,
  CAMPAIGN_STATUS_LABEL2,
  ICampaign,
} from "../interface";
import {
  deleteCampaign,
  createCampaign,
  setChanges,
  updateCampaign,
} from "../actions";

interface IProps {
  onSave: () => void;
  disabled: boolean;
  activatable: boolean;
  campaignFromList?: ICampaign;
  handleReload?: () => void;
}
const CampaignAction = ({
  onSave,
  disabled,
  activatable,
  campaignFromList,
  handleReload,
}: IProps): JSX.Element => {
  const params = useParams<{ [key: string]: any }>();
  const [dialog, setDialog] = useState({});
  const dispatch = useDispatch();
  const { campaign, accountId, user, saving, changing, campaignChanges } =
    useSelector(
      (s: IRootReducer) => ({
        campaign: campaignFromList || s.campaigns.campaign,
        saving: s.campaigns.saving,
        changing: s.campaigns.changing,
        accountId: s.session.selectedAccount?.id || "all",
        user: s.user.profile,
        campaignChanges: s.campaigns.campaignChanges,
      }),
      shallowEqual
    );
  const [currentStatus, setCurrentStatus] = useState(campaign?.status);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [anchorRef, { width }] = useElementSize();
  const buttonGroupRef = useRef<any>(null);

  const userAccount = user?.userAccounts?.find(
    (account) => account.accountId === accountId
  );
  const isManager =
    userAccount?.userType === AccountUserType.Manager || userAccount?.isOwner;
  const userRequiresPermission = !!userAccount?.accountUserPermissions?.find(
    (accountUserPermission) =>
      accountUserPermission?.managerPermission?.name ===
      ManagerPermissions.purchase_phone_numbers
  )?.value;
  const isAdmin = user?.userType === Role.Admin;
  const isDraft =
    !currentStatus ||
    (currentStatus &&
      [CampaignStatus.Draft, CampaignStatus.Rejected].includes(currentStatus));
  const isPaused = currentStatus === CampaignStatus.Paused;
  const isSent = currentStatus === CampaignStatus.Sent;
  const isError = currentStatus === CampaignStatus.Error;
  const isReady =
    currentStatus &&
    [
      CampaignStatus.Ready,
      CampaignStatus.Active,
      CampaignStatus.Queued,
    ].includes(currentStatus);
  const isEnded = currentStatus === CampaignStatus.Ended;
  let defaultButtonText = "Save";
  let defaultButtonHandler = onSave;
  let defaultButtonType: "error" | "success" | "warning" = "success";
  let options = [disabled ? "Activate" : "Save & Activate"];

  const campaignListing = `/campaigns?campaignType=${
    campaign?.campaignType || CampaignType.OnDemand
  }`;

  const updateCampaignStatus = (newStatus: CampaignStatus): void => {
    const isEndingOrDraft =
      [CampaignStatus.Ended, CampaignStatus.Draft].indexOf(newStatus) >= 0;

    const confirmTitle = `${CAMPAIGN_STATUS_CONFIRM_TITLE[newStatus]}`;

    setDialog({
      confirmTitle,
      confirmMessage: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ marginBottom: 8 }}>
            Are you sure you want to{" "}
            {CAMPAIGN_STATUS_LABEL2[newStatus].toLowerCase()}
          </Typography>
          {moment(campaign?.startDate).isBefore(moment()) &&
          !isEndingOrDraft ? (
            <Alert severity="error">
              The Start Date/Time is before the current Date/Time. Are you sure
              you want to start this campaign immediately?
            </Alert>
          ) : null}
        </Box>
      ),
      onConfirm: async (e: SyntheticEvent, reasonObj: any) => {
        try {
          dispatch(setChanges(uniq([...campaignChanges, "status", "reason"])));
          const result: any = campaign?.id
            ? await dispatch(
                updateCampaign({
                  ...campaign,
                  accountId,
                  id: campaign?.id,
                  status: newStatus,
                  ...reasonObj,
                })
              )
            : await dispatch(
                createCampaign({
                  ...campaign,
                  status: newStatus,
                  ...reasonObj,
                })
              );
          setDialog({});
          // if (result?.id && params.id !== result?.id) {
          //   // dispatch(push(campaignListing));
          // }
          if (newStatus === CampaignStatus.Ended) {
            dispatch(push(campaignListing));
          }
          if (handleReload) handleReload();
        } catch (err: any) {
          dispatch(
            setSimpleValue("snackbar", {
              message: err?.data?.message || err?.message,
            })
          );
          setDialog({});
        }
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
      type: [CampaignStatus.Rejected].includes(newStatus) ? "reject" : "accept",
    });
  };
  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event): void => {
    if (buttonGroupRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleCancelRequest = (): void => {
    updateCampaignStatus(CampaignStatus.Draft);
  };
  const handleApproveRequest = (): void => {
    updateCampaignStatus(CampaignStatus.Ready);
  };
  const handlePause = (): void => {
    updateCampaignStatus(CampaignStatus.Paused);
  };
  const handleDelete = (): any => {
    setDialog({
      confirmTitle: "Delete Campaign",
      confirmMessage: "Are you sure you want to delete this campaign?",
      onConfirm: async () => {
        if (campaign?.id)
          await dispatch(deleteCampaign(accountId, campaign.id));
        setDialog({});
        dispatch(
          push(
            `/campaigns?campaignType=${
              campaign?.campaignType || CampaignType.OnDemand
            }`
          )
        );
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
    });
  };
  const handleActivate = (): void => {
    updateCampaignStatus(CampaignStatus.Ready);
  };
  const isDraftFromList =
    currentStatus === CampaignStatus.Draft && campaignFromList;
  const isActive = CampaignStatus.Active === currentStatus;

  switch (currentStatus) {
    case CampaignStatus.Sent:
      defaultButtonHandler = () => updateCampaignStatus(CampaignStatus.Ended);
      defaultButtonText = "End";
      defaultButtonType = "warning";
      options = [];
      break;
    case CampaignStatus.Rejected:
      options.push("Delete");
      break;
    case CampaignStatus.Draft:
    case CampaignStatus.Error:
      if (isDraftFromList) {
        defaultButtonHandler = handleActivate;
        defaultButtonText = "Activate";
        defaultButtonType = "success";
        options = ["Delete"];
      } else {
        options.push("Delete");
      }
      break;
    case CampaignStatus.Ended:
      defaultButtonHandler = handleDelete;
      defaultButtonText = "Delete";
      defaultButtonType = "error";
      break;
    case CampaignStatus.AwaitingApproval: {
      if (userRequiresPermission) {
        defaultButtonHandler = handleCancelRequest;
        defaultButtonText = "Cancel Request";
        defaultButtonType = "error";
      } else if (isManager || isAdmin) {
        defaultButtonHandler = handleApproveRequest;
        defaultButtonText = "Approve";
        defaultButtonType = "success";
        options = ["Reject"];
      }
      break;
    }
    case CampaignStatus.Ready:
    case CampaignStatus.Active:
    case CampaignStatus.Queued:
      if (isActive) {
        defaultButtonHandler = handleCancelRequest;
        defaultButtonText = "Cancel";
        defaultButtonType = "error";
      } else {
        defaultButtonHandler = handlePause;
        defaultButtonText = "Pause";
        defaultButtonType = "warning";
      }
      options = ["End"];
      break;
    case CampaignStatus.Paused:
      defaultButtonHandler = handleApproveRequest;
      defaultButtonText = "Resume";
      defaultButtonType = "success";
      options = ["Save", "End"];
      break;
    default:
  }

  const handleMenuItemClick = (
    event: MouseEvent<HTMLLIElement>,
    index: number
  ): void => {
    setSelectedIndex(index);
    setOpen(false);
    let newStatus;
    if (["Activate", "Save & Activate"].includes(options[index])) {
      newStatus = CampaignStatus.Ready;
    } else if (["Reject"].includes(options[index])) {
      newStatus = CampaignStatus.Rejected;
    } else if (["End"].includes(options[index])) {
      newStatus = CampaignStatus.Ended;
    }
    if (["Save"].includes(options[index])) {
      onSave();
    } else if (["Delete"].includes(options[index])) {
      handleDelete();
    } else if (newStatus) {
      updateCampaignStatus(newStatus);
    }
  };
  useEffect(() => {
    setCurrentStatus(campaign?.status);
  }, [campaign?.status]);
  const isDisabled =
    (defaultButtonText === "Activate" && !activatable) ||
    changing ||
    saving ||
    (isDraft && !isDraftFromList ? disabled : false);
  const readyState = isActive ? "error" : "warning";

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={(node) => {
          anchorRef(node);
          if (node !== null) buttonGroupRef.current = node;
        }}
        aria-label="split button"
      >
        <Button
          color={defaultButtonType}
          onClick={defaultButtonHandler}
          disabled={isDisabled}
          size="small"
        >
          {defaultButtonText}
        </Button>
        {!isEnded && !isSent && (
          <Button
            color={isReady ? readyState : "success"}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            disabled={
              saving || isDraft || isPaused || isReady || isError
                ? false
                : !isManager
            }
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={buttonGroupRef.current}
        role={undefined}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" sx={{ minWidth: width }}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={
                        ["Activate", "Save & Activate"].includes(option) &&
                        isDraft &&
                        !activatable
                      }
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <AuthenticateDialog {...dialog} />
    </>
  );
};

export default CampaignAction;
