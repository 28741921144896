import {
  GET_ROLES,
  CREATE_ROLE,
  INITIAL_STATE,
  DELETE_ROLE,
  UPDATE_ROLE,
  FETCH_ROLE,
  SET_ROLE,
  UPDATE_PERMISSION,
  CREATE_PERMISSION,
} from "./constants";
import { IRoleState, IRoleAction } from "./interface";

export const updatePermission = (
  state: IRoleState,
  action: IRoleAction
): IRoleState => {
  if (state.role) {
    const permissions = [...(state.role.permissions || [])];
    const index = permissions.findIndex((o) => o.id === action.payload.id);
    if (index >= 0) {
      permissions[index].value = action.payload.value;
    }
    return { ...state, role: { ...state.role, permissions } };
  }
  return state;
};
export const createPermission = (
  state: IRoleState,
  action: IRoleAction
): IRoleState => {
  const permissions = [...(state.role?.permissions || [])];
  const index = permissions.findIndex(
    (o) => o.permissionId === action.payload.permissionId
  );
  if (index >= 0) {
    permissions[index].value = action.payload.value;
  } else {
    permissions.push({
      permissionId: action.payload.permissionId,
      value: action.payload.value,
    });
  }
  return {
    ...state,
    role: {
      ...(state.role || {
        name: "",
        label: "",
        description: "",
        roleId: action.payload.roleId,
        accountId: action.payload.accountId,
      }),
      permissions,
    },
  };
};

export const reducer = (
  state: IRoleState = INITIAL_STATE,
  action: IRoleAction
): IRoleState => {
  switch (action.type) {
    case SET_ROLE:
      return {
        ...state,
        role: action.payload.role,
      };
    case GET_ROLES.REQUEST:
      return { ...state, isFetching: true };
    case GET_ROLES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        roles: action.payload.roles,
      };
    case GET_ROLES.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_ROLE.REQUEST:
      return { ...state, isCreating: true };
    case CREATE_ROLE.SUCCESS:
      return {
        ...state,
        isCreating: false,
        roles: [...state.roles, action.payload.role],
      };
    case CREATE_ROLE.FAILED:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_ROLE.REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_ROLE.SUCCESS:
      return {
        ...state,
        isDeleting: false,
        roles: [
          ...state.roles.filter((value) => value.id !== action.payload.roleId),
        ],
      };
    case DELETE_ROLE.FAILED:
      return {
        ...state,
        isDeleting: false,
      };
    case FETCH_ROLE.REQUEST:
      return { ...state, isFetching: true };
    case FETCH_ROLE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        role: action.payload.role,
      };
    case FETCH_ROLE.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_ROLE.REQUEST:
      return { ...state, isCreating: true };
    case UPDATE_ROLE.SUCCESS:
      return {
        ...state,
        isCreating: false,
        roles: [
          ...state.roles.map((role) => {
            if (action.payload.role.id === role.id) {
              return action.payload.role;
            }
            return role;
          }),
        ],
      };
    case UPDATE_ROLE.FAILED:
      return {
        ...state,
        isCreating: false,
      };
    case UPDATE_PERMISSION:
      return updatePermission(state, action);
    case CREATE_PERMISSION:
      return createPermission(state, action);
    default:
      return state;
  }
};
