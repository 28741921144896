import { DELETE, FETCH, GET, INITIAL_STATE, UPDATE } from "./constants";
import { IFilesAction, IFilesState } from "./interface";

export const reducer = (
  state: IFilesState = INITIAL_STATE,
  action: IFilesAction
): IFilesState => {
  switch (action.type) {
    case GET.REQUEST:
      return { ...state, isFetching: true };
    case GET.SUCCESS:
      return {
        ...state,
        isFetching: false,
        files: action.payload.append
          ? [...state.files, ...action.payload.files]
          : action.payload.files,
        total: action.payload.total,
      };
    case GET.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH.SUCCESS:
      return {
        ...state,
        file: action.payload.file,
      };
    case DELETE.SUCCESS: {
      const files = [...state.files];
      files.splice(files.findIndex((o) => o.id === action.payload.id));
      return { ...state, files };
    }
    case UPDATE.SUCCESS: {
      const files = [...state.files];
      const index = files.findIndex((o) => o.id === action.payload.file.id);
      files[index] = action.payload.file;
      return { ...state, files };
    }
    default:
      return state;
  }
};
