import { ChangeEvent, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Box, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import Roles from "../../Roles";
import Users from "./Users";

interface LinkTabProps {
  label: string;
  to: string;
}

const useStyles = makeStyles(() => ({
  tabs: {
    alignItems: "center",
    background: "#FFFFFF",
    color: "#000000",
    height: 48,
  },
}));

const LinkTab = ({ to, ...props }: LinkTabProps): JSX.Element => {
  return (
    <Tab
      component={Link}
      {...props}
      onClick={(event: any) => {
        event.stopPropagation();
      }}
      to={to}
    />
  );
};

enum Page {
  UsersPage = "/user",
  RolesPage = "/roles",
}
enum TabDef {
  UsersPage = 0,
  RolesPage = 1,
}
const Landing = (): JSX.Element => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  useEffect(() => {
    const selected: Page =
      pathname === Page.UsersPage ? Page.UsersPage : Page.RolesPage;
    setValue(
      {
        [Page.UsersPage]: TabDef.UsersPage,
        [Page.RolesPage]: TabDef.RolesPage,
      }[selected]
    );
  }, [pathname]);
  const appBar = document.getElementById("app-bar");
  const renderContent = (): JSX.Element => {
    switch (value) {
      case TabDef.RolesPage:
        return <Roles />;
      default:
        return <Users />;
    }
  };

  return (
    <Box
      sx={{ width: "100%", height: "calc(100vh - 160px)", marginTop: "48px" }}
    >
      {appBar &&
        createPortal(
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            centered
            aria-label="campaigns type"
          >
            <LinkTab label="Users" to={Page.UsersPage} />
            <LinkTab label="User Roles" to={Page.RolesPage} />
          </Tabs>,
          appBar
        )}
      {renderContent()}
    </Box>
  );
};

export default Landing;
