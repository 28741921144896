import { createActionTypes } from "../../utils/utilities";
import { IUserState } from "./interface";

export const NAME = "user";
export const SET_PROFILE = createActionTypes(`${NAME}/SET_PROFILE`);
export const SET_FETCHING = `${NAME}/SET_FETCHING`;
export const UPDATE_USER = createActionTypes(`${NAME}/UPDATE_USER`);
export const NEW_USER = createActionTypes(`${NAME}/NEW_USER`);
export const GET_USERS = createActionTypes(`${NAME}/GET_USERS`);
export const FETCH_USER = createActionTypes(`${NAME}/FETCH_USER`);

export const INITIAL_STATE: IUserState = {
  profile: undefined,
  user: undefined,
  users: [],
  total: 0,
  fetchingUser: true,
  isFetching: false,
};
