import { Action } from "redux";
import { Elements } from "react-flow-renderer";
import { Payload } from "common/common.interface";
import { IList } from "modules/Contacts/interface";
import { IFile } from "modules/Files/interface";
import { IPhoneGroup } from "modules/Phone/interface";
import { IUser } from "modules/User/interface";
import { IAccount } from "../Accounts/interface";

export enum CampaignType {
  OnDemand = "on-demand",
  Conversation = "conversation",
}
export interface Parameter {
  [key: string]: string | number | string[] | number[];
}
export enum ExecutionStatus {
  NotReady = 0,
  Ready = 1,
  PendingAuth = 2,
  Executing = 3,
  Paused = 4,
  Completed = 5,
  End = 6,
  ERROR = 7,
}

export interface ICampaignContactList {
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletorId?: string;
  deletedAt?: Date;
  executionId: string;
  listId: string;
  list?: IList;
}

export interface ReplyKeyword {
  keywordId: number | string;
  replyKeyword: string;
}
export enum KeywordType {
  STOP = 1,
  UNSTOP = 2,
  OTHER = 3,
}
export interface ICampaignReply {
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletorId?: string;
  deletedAt?: Date;
  campaignId: string;
  replyKeywords: ReplyKeyword[];
  replyMessage: string;
  keywordType: KeywordType;
  replyMessageId?: number;
}

export interface ICampaignExecution {
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletorId?: string;
  deletedAt?: Date;
  campaignId?: string;
  smsExecutionId?: number;
  status?: ExecutionStatus;
  schedule: Date;
  contacts?: ICampaignContactList[];
}

export interface ICampaignMediaFile {
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletorId?: string;
  deletedAt?: Date;
  campaignId: string;
  fileId: string;
  file: IFile;
}

export enum CampaignStatus {
  Draft = "draft",
  AwaitingApproval = "awaiting_approval",
  Rejected = "rejected",
  Ready = "ready",
  Paused = "paused",
  Ended = "ended",
  Active = "active",
  Queued = "queued",
  Sent = "sent",
  Error = "error",
}

export const CAMPAIGN_STATUS_LABEL = {
  [CampaignStatus.Draft]: "Draft",
  [CampaignStatus.AwaitingApproval]: "Awaiting Approval",
  [CampaignStatus.Rejected]: "Rejected",
  [CampaignStatus.Ready]: "Running",
  [CampaignStatus.Paused]: "Paused",
  [CampaignStatus.Ended]: "Ended",
  [CampaignStatus.Active]: "Active",
  [CampaignStatus.Queued]: "Queued",
  [CampaignStatus.Sent]: "All Sent",
  [CampaignStatus.Error]: "Error",
};

export const CAMPAIGN_STATUS_CONFIRM_TITLE = {
  [CampaignStatus.Draft]: "Draft",
  [CampaignStatus.AwaitingApproval]: "Awaiting Approval",
  [CampaignStatus.Rejected]: "Reject",
  [CampaignStatus.Ready]: "Activate",
  [CampaignStatus.Paused]: "Pause",
  [CampaignStatus.Ended]: "End",
  [CampaignStatus.Active]: "Active",
  [CampaignStatus.Queued]: "Queue",
  [CampaignStatus.Sent]: "All Sent",
  [CampaignStatus.Error]: "Error",
};

export const CAMPAIGN_STATUS_LABEL2 = {
  [CampaignStatus.Draft]: "set this campaign to draft?",
  [CampaignStatus.AwaitingApproval]: "send this for approval?",
  [CampaignStatus.Rejected]: "reject this campaign?",
  [CampaignStatus.Ready]: "activate this campaign?",
  [CampaignStatus.Paused]: "pause this campaign?",
  [CampaignStatus.Ended]: "end this campaign?",
  [CampaignStatus.Active]: "set this campaign to active?",
  [CampaignStatus.Queued]: "send this campaign to queue?",
  [CampaignStatus.Sent]: "send this campaign?",
  [CampaignStatus.Error]: "set to error?",
};
export interface ICampaign {
  id?: string;
  isArchived?: boolean;
  createDateTime?: Date;
  creatorId?: string;
  updatorId?: string;
  parentId?: string;
  phoneGroupId?: string;
  accountId: string;
  name: string;
  parameters: Parameter;
  delayCount?: string;
  delayUnit?: string;
  message?: string;
  requestFrom: string;
  webhookResponsePayload: object;
  replyMessage: Parameter;
  flowChart?: {
    elements: Elements;
    position: [number, number];
    zoom: number;
  };
  lastChangedDateTime?: Date;
  account?: IAccount;
  mediaFiles?: IFile[];
  templateId?: string;
  campaignType: CampaignType;
  executions?: ICampaignExecution[];
  smsPlanId?: number;
  phoneGroup?: IPhoneGroup;
  replies?: ICampaignReply[];
  files?: ICampaignMediaFile[];
  status: CampaignStatus;
  reason?: string;
  creator?: IUser;
  updator?: IUser;
  startDate?: Date | null;
  endDate?: Date | null;
}

interface ICampaignTemplate {
  id: string;
  name: string;
  flowChart: any;
}

export type ICampaignKey = keyof ICampaign;
export interface ICampaignState {
  data: ICampaign[];
  total: number;
  children: {
    [id: string]: ICampaign[];
  };
  campaign: ICampaign | null;
  nextCampaign: ICampaign | null;
  parentCampaign: ICampaign | null;
  saving: boolean;
  changing: boolean;
  fetching: boolean;
  fetchingNext?: boolean;
  fetchingCampaign?: boolean;
  errors: any;
  templates: ICampaignTemplate[];
  campaignChanges: ICampaignKey[];
}

export interface ICampaignAction extends Action {
  payload: Payload;
}
