export enum MessageType {
  Outbound = 1,
  Inbound = 2,
  StatusUpdate = 3,
  STOP = 4,
  UNSTOP = 5,
}

export interface ICampaignWebhookRequest {
  id?: string;
  createDateTime?: Date;
  apiKey?: string;
  apiSecret: string;
  smsPlanId: number;
  messageType: MessageType;
  message: object;
}

export interface IWebhookRequestLogs {
  data: ICampaignWebhookRequest[];
  total: number;
  loading: boolean;
  error: boolean;
}
