import {
  DELETE_ACCOUNT_USER,
  FETCH_ACCOUNT_USER,
  GET_ACCOUNT_USERS,
  INITIAL_STATE,
} from "./constants";
import { IAccountUsersAction, IAccountUsersState } from "./interface";

export const reducer = (
  state: IAccountUsersState = INITIAL_STATE,
  action: IAccountUsersAction
): IAccountUsersState => {
  switch (action.type) {
    case GET_ACCOUNT_USERS.REQUEST:
      return { ...state, isFetching: true };
    case GET_ACCOUNT_USERS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountUsers: action.payload.accountUsers,
      };
    case GET_ACCOUNT_USERS.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_ACCOUNT_USER.SUCCESS:
      return {
        ...state,
        accountUser: action.payload.accountUser,
      };
    case DELETE_ACCOUNT_USER.SUCCESS: {
      const accountUsers = [...state.accountUsers];
      accountUsers.splice(
        accountUsers.findIndex((o) => o.id === action.payload.id)
      );
      return { ...state, accountUsers };
    }
    default:
      return state;
  }
};
