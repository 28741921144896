import { createActionTypes } from "../../utils/utilities";
import { IFilesState } from "./interface";

export const NAME = "files";

export const GET = createActionTypes(`${NAME}/GET`);
export const FETCH = createActionTypes(`${NAME}/FETCH`);
export const DELETE = createActionTypes(`${NAME}/DELETE`);
export const UPDATE = createActionTypes(`${NAME}/UPDATE`);

export const INITIAL_STATE: IFilesState = {
  files: [],
  total: 0,
  file: null,
  isFetching: false,
};
