import { Action } from "redux";
/* eslint-disable camelcase */

export enum Role {
  Admin = "admin",
  User = "user",
}
export interface IPermission {
  id?: string;
  name: string;
  label: string;
  description: string;
  parentId?: string;
  boolean?: boolean;
}

export interface FormattedPermission {
  id?: string;
  name: string;
  label: string;
  description: string;
  parentId?: string;
  boolean?: boolean;
  permissions: FormattedPermission[];
}

export enum Permissions {
  account_management = "account_management",
  user_management = "user_management",
  campaign_management = "campaign_management",
  user_group_management = "user_group_management",
  contact_management = "contact_management",
  files_management = "files_management",
  access_reports = "access_reports",
  access_logs = "access_logs",
  edit_account = "edit_account",
  edit_files = "edit_files",
  edit_users = "edit_users",
  edit_roles = "edit_roles",
  edit_campaign = "edit_campaign",
  edit_contacts = "edit_contacts",
  read_files = "read_files",
  read_account = "read_account",
  read_users = "read_users",
  read_roles = "read_roles",
  read_campaign = "read_campaign",
  read_contacts = "read_contacts",
  create_campaign = "create_campaign",
  simulate_campaign = "simulate_campaign",
  access_dashboard = "access_dashboard",
  access_campaign_stats = "access_campaign_stats",
  access_billing = "access_billing",
  access_portal_logs = "access_portal_logs",
  inbound_api_logs = "inbound_api_logs",
  outbound_api_logs = "outbound_api_logs",
  inbound_sms = "inbound_sms",
  outbound_sms = "outbound_sms",
  read_phone_group_numbers = "read_phone_group_numbers",
}

export interface IPermissionState {
  permissions: IPermission[];
  isFetching: boolean;
}

export interface IPermissionAction extends Action {
  isFetching: boolean;
  permissions: IPermission[];
}
