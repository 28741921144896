import { useEffect, ChangeEvent, useState, useRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    "& input::placeholder": {
      fontSize: "13px",
      lineHeight: "1",
    },
  },
  label: {
    fontWeight: 500,
  },
}));

const CustomInput = ({
  className,
  id,
  name,
  type,
  label,
  title,
  helperText,
  value,
  error,
  rows,
  variant = "outlined",
  onChange,
  multiline,
  disabled,
  ...others
}: TextFieldProps): JSX.Element => {
  const [state, setState] = useState<{
    value: any;
  }>({ value: null });
  const classes = useStyles();
  const timeout = useRef<any>(undefined);
  const throttledOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (onChange) onChange(e);
    }, 300);
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
    throttledOnChange(e);
  };
  const handleBlur = (e: ChangeEvent<any>): void => {
    if (onChange) onChange(e);
  };
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      value,
    }));
  }, [value]);

  return (
    <TextField
      className={classnames(classes.input, className)}
      InputProps={{
        className: classes.root,
        ...others.InputProps,
      }}
      InputLabelProps={{ shrink: !!state.value, className: classes.label }}
      id={id}
      type={type}
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      value={state.value || ""}
      title={title}
      error={error}
      helperText={helperText}
      name={name}
      rows={rows}
      multiline={multiline}
      variant={variant}
      disabled={disabled}
      size="small"
      {...others}
    />
  );
};

export default CustomInput;
