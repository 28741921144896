import { useState, useEffect, useRef, MouseEvent } from "react";
import { IRootReducer } from "reducers/reducer.interface";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useElementSize } from "usehooks-ts";

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AuthenticateDialog from "components/AuthenticateDialog/AuthenticateDialog";
import { AccountUserType } from "modules/AccountUsers/interface";
import { getType } from "modules/Notification/components/Notification";
import { IPhoneNumber, PhoneNumberStatus } from "../interface";

import { update, remove } from "../actions";

interface IProps {
  phone?: IPhoneNumber;
  reFetchPhoneNumbers?: () => void;
}

const PhoneActions = ({
  phone,
  reFetchPhoneNumbers,
}: IProps): JSX.Element | null => {
  const [dialog, setDialog] = useState({});
  const [phoneStatus, setPhoneStatus] = useState(phone?.status);
  const [anchorRef, { width }] = useElementSize();
  const buttonGroupRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const dispatch = useDispatch();
  const options = ["Reject"];

  const { accountId, user } = useSelector(
    (s: IRootReducer) => ({
      accountId: s.session.selectedAccount?.id || "all",
      user: s.user.profile,
    }),
    shallowEqual
  );

  const userAccount = user?.userAccounts?.find(
    (account) => account.accountId === accountId
  );
  const isManager =
    userAccount?.userType === AccountUserType.Manager || userAccount?.isOwner;

  const handleClose = (event: Event): void => {
    if (buttonGroupRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const updateStatus = async (
    phoneData: any,
    status: PhoneNumberStatus,
    additionalInfo: any = {}
  ): Promise<void> => {
    await dispatch(
      update(accountId, phoneData.id, {
        status,
        ...additionalInfo,
      })
    );
    if (reFetchPhoneNumbers) {
      reFetchPhoneNumbers();
    }
  };

  const release = async (phoneData: any): Promise<void> => {
    await dispatch(remove(accountId, phoneData.id));
    if (reFetchPhoneNumbers) {
      reFetchPhoneNumbers();
    }
  };

  const handleMenuItemClick = (
    event: MouseEvent<HTMLLIElement>,
    index: number
  ): void => {
    setSelectedIndex(index);

    if (["Reject"].includes(options[index])) {
      setDialog({
        confirmTitle: "Reject Phone Number",
        confirmMessage: "Are you sure you want to reject this phone number?",
        onConfirm: async (e: any, value: any) => {
          await updateStatus(phone, PhoneNumberStatus.Rejected, value);
          setDialog({});
        },
        onClose: () => setDialog({}),
        isDialogOpen: true,
        hideButton: false,
        acceptText: "Yes",
        cancelText: "Cancel",
        onCancel: () => setDialog({}),
        type: getType(PhoneNumberStatus.Rejected),
      });
    }
  };

  useEffect(() => {
    setPhoneStatus(phone?.status);
  }, [phone?.status]);

  const generateButton = (): JSX.Element | null => {
    switch (phoneStatus) {
      case PhoneNumberStatus.Assigned:
        return (
          <Button
            key={`delete-${phone?.id}`}
            color="error"
            variant="contained"
            disabled
          >
            Delete
          </Button>
        );

      case PhoneNumberStatus.Available:
      case PhoneNumberStatus.Cancel:
      case PhoneNumberStatus.Rejected:
        return (
          <Button
            key={`delete-${phone?.id}`}
            color="error"
            variant="contained"
            onClick={() =>
              setDialog({
                confirmTitle: "Delete Phone Number",
                confirmMessage:
                  "Are you sure you want to delete this phone number?",
                onConfirm: async () => {
                  release(phone);
                  setDialog({});
                },
                onClose: () => setDialog({}),
                isDialogOpen: true,
                hideButton: false,
                acceptText: "Yes",
                cancelText: "Cancel",
                onCancel: () => setDialog({}),
              })
            }
          >
            Delete
          </Button>
        );
      case PhoneNumberStatus.WaitingForConfirmation:
        return isManager ? (
          <>
            <Button
              key={`approve-${phone?.id}`}
              color="success"
              variant="contained"
              onClick={() =>
                setDialog({
                  confirmTitle: "Approve Phone Number",
                  confirmMessage:
                    "Are you sure you want to approve this phone number?",
                  onConfirm: async () => {
                    updateStatus(phone, PhoneNumberStatus.Available);
                    setDialog({});
                  },
                  onClose: () => setDialog({}),
                  isDialogOpen: true,
                  hideButton: false,
                  acceptText: "Yes",
                  cancelText: "Cancel",
                  onCancel: () => setDialog({}),
                })
              }
            >
              Approve
            </Button>
            <Button
              color="success"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </>
        ) : (
          <Button
            key={`cancel-${phone?.id}`}
            color="error"
            variant="contained"
            onClick={() =>
              setDialog({
                confirmTitle: "Cancel Phone Number Request",
                confirmMessage:
                  "Are you sure you want to cancel this phone number request?",
                onConfirm: async () => {
                  updateStatus(phone, PhoneNumberStatus.Cancel);
                  setDialog({});
                },
                onClose: () => setDialog({}),
                isDialogOpen: true,
                hideButton: false,
                acceptText: "Yes",
                cancelText: "Cancel",
                onCancel: () => setDialog({}),
                type: getType(PhoneNumberStatus.Cancel),
              })
            }
          >
            Cancel Request
          </Button>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={(node) => {
          anchorRef(node);
          if (node !== null) buttonGroupRef.current = node;
        }}
        aria-label="split button"
      >
        {generateButton()}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={buttonGroupRef.current}
        role={undefined}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList dense id="split-button-menu" sx={{ minWidth: width }}>
                  {options.map((option, index) => (
                    <MenuItem
                      dense
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <AuthenticateDialog {...dialog} />
    </>
  );
};

export default PhoneActions;
