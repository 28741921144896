import { createSlice } from "@reduxjs/toolkit";
import { NAME } from "./constants";
import { ILogsState } from "./interface";

const initialState: ILogsState = {
  logs: [],
  total: 0,
  loading: false,
  error: false,
};

export const logsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    startLoading: (state: ILogsState) => {
      state.loading = true;
    },
    addNewLog: (state: ILogsState, action) => {
      state.logs = [action.payload, ...state.logs];
    },
    logsFetched: (state: ILogsState, action) => {
      state.loading = false;
      state.logs = action.payload.results;
      state.total = action.payload.total;
    },
    logsFetchedWithError: (state: ILogsState, action) => {
      state.loading = false;
      state.logs = [];
      state.error = action.payload;
    },
  },
});

// Actions
export const { startLoading, logsFetched, logsFetchedWithError, addNewLog } =
  logsSlice.actions;

// Reducer
export const { reducer } = logsSlice;
