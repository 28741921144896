import { ChangeEvent, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../reducers";
import { TabProps } from "../interface";

/* Actions */
import * as mainAction from "../actions";

const usePrevious = <T extends any>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const TabHeader = (): JSX.Element => {
  const location = useLocation();
  const { tabIndex, tabs, showTab } = useSelector((state: RootState) => ({
    tabIndex: state.main.tabIndex,
    tabs: state.main.tabs,
    showTab: state.main.toggleTab,
  }));

  // redux actions declarations
  const { setTabIndex, toggleTab } = bindActionCreators(
    { ...mainAction },
    useDispatch()
  );

  const handleChange = (event: ChangeEvent<any>, newValue: number): void => {
    setTabIndex(newValue);
  };

  const prevLocation = usePrevious(location.pathname);

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      toggleTab(false, []);
    }
  }, [location]);

  return (
    <>
      {showTab && (
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="inherit"
          onChange={handleChange}
          centered
          variant="fullWidth"
        >
          {tabs.map(({ label, key }: TabProps) => (
            <Tab key={key} label={label} />
          ))}
        </Tabs>
      )}
    </>
  );
};

export default TabHeader;
