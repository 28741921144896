import {
  SET_FETCHING,
  SET_PROFILE,
  INITIAL_STATE,
  GET_USERS,
  FETCH_USER,
} from "./constants";
import { IUserState, UserAction } from "./interface";

export const reducer = (
  state: IUserState = INITIAL_STATE,
  action: UserAction
): IUserState => {
  switch (action.type) {
    case SET_PROFILE.REQUEST:
      return { ...state, fetchingUser: true };
    case SET_PROFILE.SUCCESS:
      return { ...state, profile: action.payload.profile, fetchingUser: false };
    case SET_PROFILE.FAILED:
      return { ...state, profile: undefined, fetchingUser: false };
    case FETCH_USER.REQUEST:
      return { ...state, fetchingUser: true };
    case FETCH_USER.SUCCESS:
      return { ...state, user: action.payload.user, fetchingUser: false };
    case FETCH_USER.FAILED:
      return { ...state, user: undefined, fetchingUser: false };
    case SET_FETCHING:
      return { ...state, fetchingUser: action.payload.fetching };
    case GET_USERS.REQUEST:
      return { ...state, isFetching: true };
    case GET_USERS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.data.results,
        total: action.payload.data.total,
      };
    case GET_USERS.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
