import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose, Store } from "redux";
import { routerMiddleware } from "connected-react-router";
import { persistStore } from "redux-persist";
import createRootReducer from "../reducers";

export const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

let store: Store;

const configureStore = (preloadedState: any): any => {
  const debug =
    window.localStorage.getItem("debug") ||
    process.env.NODE_ENV === "development";
  const composeEnhancer =
    (debug && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const enhancers = applyMiddleware(routerMiddleware(history), thunk);
  store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(enhancers)
  );

  const persistor = persistStore(store);

  return { store, persistor };
};

export const getStore = (): Store => store;

export default configureStore;
