import { ISessionState } from "./auth.interface";

export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SET_CURRENT_URL = "SET_CURRENT_URL";

export const INITIAL_STATE: ISessionState = {
  token: undefined,
  selectedAccount: undefined,
};
