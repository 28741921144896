import { useState } from "react";
import { Parser } from "html-to-react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    background: "inherit",
    border: "none",
    borderRadius: "initial",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    whiteSpace: "pre",
    counterReset: "line",
    maxHeight: "400px",
    overflow: "auto",
  },
  codeline: {
    display: "block",
    width: "100%",
    "&:before": {
      background: "white",
      counterIncrement: "line",
      content: "counter(line)",
      display: "inline-block",
      padding: "0 8px",
      marginRight: "16px",
      color: "gray",
      textAlign: "right",
      fontSize: "12px",
      lineHeight: "21px",
    },
    "&>.json-key": {
      color: "red",
    },
    "&>.json-string": {
      color: "blue",
    },
    "&>.json-boolean,&>.json-number,&>.json-null": {
      color: "green",
    },
  },
}));
interface IProps {
  value: string;
}
export const JsonViewer = ({ value }: IProps): JSX.Element => {
  const classes = useStyles();
  const [state, setState] = useState({ copy: { start: true, end: false } });
  /**
   * Track mouse down (e.g. copy)
   * Execute property handleMouseDown if provided
   *
   * @function mouseDown
   * @return {Void}
   * */
  const mouseDown = (): void => {
    setState({
      copy: {
        start: true,
        end: false,
      },
    });
  };

  /**
   * Track mouse move (e.g. copy)
   * Execute property handleMouseMove if provided
   *
   * @function mouseMove
   * @return {Void}
   * */
  const mouseMove = (): void => {
    setState({
      copy: {
        start: true,
        end: false,
      },
    });
  };

  /**
   * Do not propagate click event if copy is going on
   * Execute onClick property if provided
   *
   * @function mouseClick
   * @param {Object} e
   * @return {Void}
   * */
  const mouseClick = (e: any): void => {
    const {
      copy: { start, end },
    } = state;

    if (start && !end) {
      e.preventDefault();
      e.stopPropagation();
    }
    setState({
      copy: {
        ...state.copy,
        start: false,
        end: true,
      },
    });
  };

  /**
   * @function getGutterWidth
   * @param {Number} length
   * @param {String} width in pixel
   * */
  const getGutterWidth = (length: number): string => {
    if (length < 10) {
      return "24px";
    }
    if (length < 100) {
      return "34px";
    }
    if (length < 1000) {
      return "44px";
    }
    return "54px";
  };

  const syntaxHighlight = (json: string): string => {
    const newJson = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    const regx =
      // eslint-disable-next-line max-len
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g;

    return newJson.replace(regx, (match) => {
      let cls = "number";

      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return `<em class="json-${cls}">${match}</em>`;
    });
  };

  const htmlToReact = new Parser();

  const json = syntaxHighlight(value).split(/\r\n|\r|\n/);

  const width = getGutterWidth(json.length);
  const handleKeydown = (): void => {
    //
  };
  const handleFocus = (): void => {
    //
  };
  return (
    <pre className={classes.root}>
      {json.map((code, i) => (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        <Typography
          className={classes.codeline}
          // eslint-disable-next-line react/no-array-index-key
          key={`json-${i}`}
          style={{ width }}
          onClick={mouseClick}
          onMouseDown={mouseDown}
          onMouseMove={mouseMove}
          onKeyDown={handleKeydown}
          role="button"
          onFocus={handleFocus}
        >
          {htmlToReact.parse(code)}
        </Typography>
      ))}
    </pre>
  );
};

export default JsonViewer;
