import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MouseEvent, useState, useRef, useEffect, SyntheticEvent } from "react";
import { IRootReducer } from "reducers/reducer.interface";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AccountUserType } from "modules/AccountUsers/interface";
import { Role } from "modules/Permissions/interface";
import { ManagerPermissions } from "modules/ManagerBasedPermissions/interface";
import { useElementSize } from "usehooks-ts";
import AuthenticateDialog from "components/AuthenticateDialog/AuthenticateDialog";
import { setSimpleValue } from "modules/Main/actions";
import { orderBy, uniq } from "lodash";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { IPhoneGroup, PhoneGroupStatus, STATUS_LABELS } from "../interface";

import {
  createPhoneGroup,
  deletePhoneGroup,
  setChanges,
  updatePhoneGroup,
} from "../actions";

interface IProps {
  onSave: () => void;
  disabled: boolean;
  activatable: boolean;
  phoneGroupFromList?: IPhoneGroup;
  onReload?: () => void;
}
const PhoneGroupAction = ({
  onSave,
  disabled,
  activatable,
  phoneGroupFromList,
  onReload,
}: IProps): JSX.Element | null => {
  const params = useParams<{ [key: string]: any }>();

  const [dialog, setDialog] = useState({});
  const dispatch = useDispatch();
  const { phoneGroup, accountId, user, saving, phoneGroupChanges } =
    useSelector(
      (s: IRootReducer) => ({
        phoneGroup: phoneGroupFromList || s.phone.phoneGroup,
        saving: s.phone.saving,
        accountId: s.session.selectedAccount?.id || "all",
        user: s.user.profile,
        phoneGroupChanges: s.phone.phoneGroupChanges,
      }),
      shallowEqual
    );
  const [currentStatus, setCurrentStatus] = useState(phoneGroup?.status);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [anchorRef, { width }] = useElementSize();
  const buttonGroupRef = useRef<any>(null);

  const userAccount = user?.userAccounts?.find(
    (account) => account.accountId === accountId
  );
  const isManager =
    userAccount?.userType === AccountUserType.Manager || userAccount?.isOwner;
  const userRequiresPermission = !!userAccount?.accountUserPermissions?.find(
    (accountUserPermission) =>
      accountUserPermission?.managerPermission?.name ===
      ManagerPermissions.purchase_phone_numbers
  )?.value;
  const isAdmin = user?.userType === Role.Admin;
  const isDraft =
    !currentStatus ||
    (currentStatus &&
      [PhoneGroupStatus.Draft, PhoneGroupStatus.OnHold].includes(
        currentStatus
      ));
  const isApproved = currentStatus === PhoneGroupStatus.Approved;
  let defaultButtonText = "Save";
  let defaultButtonHandler = onSave;
  let defaultButtonType: "error" | "success" | "warning" = "success";
  let options = [disabled ? "Activate" : "Save & Activate"];
  const updatePhoneGroupStatus = (newStatus: PhoneGroupStatus): void => {
    setDialog({
      confirmTitle: `${STATUS_LABELS[newStatus]}`,
      confirmMessage: `Are you sure you want to ${STATUS_LABELS[
        newStatus
      ]?.toLowerCase()}`,
      onConfirm: async (e: SyntheticEvent, reasonObj: any) => {
        try {
          dispatch(
            setChanges(uniq([...phoneGroupChanges, "status", "reason"]))
          );
          const result: any = phoneGroup?.id
            ? await dispatch(
                updatePhoneGroup(accountId, phoneGroup.id, {
                  ...phoneGroup,
                  accountId,
                  id: phoneGroup?.id,
                  status: newStatus,
                  ...reasonObj,
                })
              )
            : await dispatch(
                createPhoneGroup(accountId, {
                  ...phoneGroup,
                  status: newStatus,
                  ...reasonObj,
                })
              );
          setDialog({});
          if (result?.id && params.id !== result?.id) {
            // dispatch(push(`/phone/groups/${result?.id}`));
            if (onReload) {
              onReload();
            } else {
              window.location.reload();
            }
          }
        } catch (err) {
          dispatch(setSimpleValue("snackbar", { message: err.message }));
          setDialog({});
        }
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
      type: [PhoneGroupStatus.OnHold, PhoneGroupStatus.Draft].includes(
        newStatus
      )
        ? "reject"
        : "accept",
    });
  };
  const handleActivate = (): void => {
    const newStatus = PhoneGroupStatus.PendingApproval;
    updatePhoneGroupStatus(newStatus);
  };
  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event): void => {
    if (buttonGroupRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleCancelRequest = (): void => {
    updatePhoneGroupStatus(PhoneGroupStatus.Draft);
  };
  const handleApproveRequest = (): void => {
    let newStatus = PhoneGroupStatus.PendingApproval;
    switch (currentStatus) {
      case PhoneGroupStatus.AwaitingConfirmation:
        if (isAdmin) newStatus = PhoneGroupStatus.Approved;
        break;
      case PhoneGroupStatus.PendingApproval:
        if (isAdmin) newStatus = PhoneGroupStatus.Approved;
        break;
      default:
    }
    updatePhoneGroupStatus(newStatus);
  };
  const handleDelete = (): any => {
    setDialog({
      confirmTitle: "Delete Phone Group",
      confirmMessage: "Are you sure you want to delete this phone group?",
      onConfirm: async () => {
        if (phoneGroup?.id)
          await dispatch(deletePhoneGroup(accountId, phoneGroup.id));
        if (onReload) {
          onReload();
        }

        setDialog({});
        dispatch(push(`/phone/groups`));
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
    });
  };
  const handleRelease = (): any => {
    setDialog({
      confirmTitle: "Free Phone Group",
      confirmMessage: (
        <Box>
          <Typography>
            Are you sure you want to free this phone group?
          </Typography>
          {orderBy(
            phoneGroup?.campaigns || [],
            ["createDateTime"],
            ["desc"]
          ).map((campaign) => {
            return (
              <Typography key={`status-${campaign.id}`}>
                {campaign.name}: {campaign.status}
              </Typography>
            );
          })}
        </Box>
      ),
      onConfirm: async () => {
        if (phoneGroup?.id) {
          dispatch(setChanges(uniq([...phoneGroupChanges, "status"])));
          await dispatch(
            updatePhoneGroup(accountId, phoneGroup.id, {
              ...phoneGroup,
              accountId,
              id: phoneGroup?.id,
              status: PhoneGroupStatus.Free,
            })
          );
        }
        if (onReload) {
          onReload();
        }

        setDialog({});
        dispatch(push(`/phone/groups`));
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
    });
  };
  const isDraftFromList =
    currentStatus === PhoneGroupStatus.Draft && phoneGroupFromList;
  const isAdminEditing = isAdmin && isApproved && !phoneGroupFromList;

  useEffect(() => {
    setCurrentStatus(phoneGroup?.status);
  }, [phoneGroup?.status]);
  switch (currentStatus) {
    case PhoneGroupStatus.OnHold:
    case PhoneGroupStatus.Draft:
      if (phoneGroupFromList) {
        defaultButtonHandler = handleActivate;
        defaultButtonText = "Activate";
        defaultButtonType = "success";
        options = ["Delete"];
        if (isAdmin) {
          options.push("Activate");
        }
      } else {
        options.push("Delete");
      }
      break;
    case PhoneGroupStatus.PendingApproval:
    case PhoneGroupStatus.AwaitingConfirmation: {
      if (userRequiresPermission) {
        defaultButtonHandler = handleCancelRequest;
        defaultButtonText = "Cancel Request";
        defaultButtonType = "error";
      } else if (
        (PhoneGroupStatus.AwaitingConfirmation === currentStatus &&
          isManager) ||
        isAdmin
      ) {
        defaultButtonHandler = handleApproveRequest;
        defaultButtonText = "Approve";
        defaultButtonType = "success";
        options = ["Reject"];
      } else {
        return null;
      }
      break;
    }
    case PhoneGroupStatus.Approved:
      if (isAdminEditing) {
        defaultButtonHandler = onSave;
        defaultButtonText = "Save";
        defaultButtonType = "success";
        options = ["Delete"];
      } else {
        defaultButtonHandler = handleDelete;
        defaultButtonText = "Delete";
        defaultButtonType = "error";
        options = [];
      }
      break;
    case PhoneGroupStatus.InUse: {
      defaultButtonHandler = handleRelease;
      defaultButtonText = "Release";
      defaultButtonType = "error";
      options = [];
      break;
    }
    default:
  }

  const handleMenuItemClick = (
    event: MouseEvent<HTMLLIElement>,
    index: number
  ): void => {
    setSelectedIndex(index);
    setOpen(false);
    let newStatus;
    if (["Activate", "Save & Activate"].includes(options[index])) {
      newStatus = PhoneGroupStatus.PendingApproval;
    } else if (["Reject"].includes(options[index])) {
      newStatus = PhoneGroupStatus.OnHold;
    }
    if (["Save"].includes(options[index])) {
      onSave();
    } else if (["Delete"].includes(options[index])) {
      handleDelete();
    } else if (newStatus) {
      updatePhoneGroupStatus(newStatus);
    }
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={(node) => {
          anchorRef(node);
          if (node !== null) buttonGroupRef.current = node;
        }}
        aria-label="split button"
      >
        <Button
          color={defaultButtonType}
          onClick={defaultButtonHandler}
          disabled={
            saving ||
            (defaultButtonText === "Save" && !phoneGroupChanges.length) ||
            (defaultButtonText === "Approve" && !activatable)
          }
          size="small"
        >
          {defaultButtonText}
        </Button>
        {((isDraft && !isDraftFromList) ||
          isAdminEditing ||
          (!isApproved &&
            phoneGroup?.status &&
            ((!isManager &&
              ![
                PhoneGroupStatus.AwaitingConfirmation,
                PhoneGroupStatus.PendingApproval,
              ].includes(phoneGroup?.status)) ||
              isManager ||
              isAdmin))) &&
          options.length > 0 && (
            <Button
              color={isApproved && !isAdminEditing ? "error" : "success"}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              disabled={
                saving ||
                (isDraft || isApproved ? false : !isManager && !isAdmin)
              }
            >
              <ArrowDropDownIcon />
            </Button>
          )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={buttonGroupRef.current}
        role={undefined}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList dense id="split-button-menu" sx={{ minWidth: width }}>
                  {options.map((option, index) => (
                    <MenuItem
                      dense
                      key={option}
                      disabled={
                        isDraft &&
                        ["Activate", "Save & Activate"].includes(option) &&
                        !activatable
                      }
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <AuthenticateDialog {...dialog} />
    </>
  );
};

export default PhoneGroupAction;
