import { useEffect, useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { makeStyles } from "@mui/styles";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";

import { Link } from "react-router-dom";
import { Delete, EditOutlined } from "@mui/icons-material";
import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { Column } from "../../../common/common.interface";
import { deleteAccountUser, getAccountUsers } from "../actions";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";

const columns: Column[] = [
  { id: "firstName", label: "First Name", minWidth: 170 },
  { id: "lastName", label: "Last Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "isOwner", label: "Account Owner", minWidth: 100 },
  { id: "role", label: "Role", minWidth: 100 },
  {
    id: "actions",
    label: "",
    minWidth: 50,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "calc(100vh - 160px)",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
  title: {
    position: "relative",
    padding: "16px",
  },
  new: {
    float: "right",
  },
});

const getCell = (
  columnsData: Column[],
  accountUser: any,
  onEdit: any,
  onDelete: any
): JSX.Element[] => {
  return columnsData.map((column: any) => {
    let value: any = accountUser?.user?.[column.id];
    const isOwner = accountUser?.isOwner ? "True" : "False";
    if (column.id === "role") {
      value = accountUser?.role?.name;
    }
    return column.id === "actions" ? (
      <TableCell key={column.id} align={column.align}>
        <Tooltip title="Edit Account" placement="top">
          <IconButton aria-label="edit" onClick={() => onEdit(accountUser)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Account" placement="top">
          <IconButton
            disabled={accountUser.isOwner}
            aria-label="edit"
            onClick={() => onDelete(accountUser)}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    ) : (
      <TableCell key={column.id} align={column.align}>
        {column.id === "isOwner" ? isOwner : value}
      </TableCell>
    );
  });
};

const AccountUsers = (): JSX.Element => {
  const [dialog, setDialog] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { selectedAccount, accountUsers } = useSelector(
    (state: IRootReducer) => ({
      accountUsers: state.accountUsers.accountUsers,
      selectedAccount: state.session.selectedAccount,
    })
  );

  const fetch = (skip = 0, take = 10): void => {
    if (selectedAccount?.id) {
      dispatch(getAccountUsers(selectedAccount.id, skip, take));
    }
  };
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    fetch(skip, newPage * rowsPerPage + rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetch(0, newRowsPerPage);
  };

  const handleEditAccount = (accountUser: any): void => {
    dispatch(push(`/user/${accountUser.id}`));
  };
  const handleDelete = (accountUser: any): void => {
    setDialog({
      confirmTitle: "Delete Account User",
      confirmMessage: "Are you sure you want to delete this account user?",
      onConfirm: async () => {
        await dispatch(
          deleteAccountUser(accountUser.id, accountUser.accountId)
        );
        setDialog({});
        fetch();
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
    });
  };

  useEffect(() => {
    fetch();
  }, [selectedAccount]);
  return (
    <Paper className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span>Account Users</span>
        <Button
          component={Link}
          to="/user/new"
          variant="contained"
          color="primary"
          className={classes.new}
        >
          Invite User
        </Button>
      </Typography>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accountUsers.length ? (
              accountUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((accountUser) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={accountUser?.id}
                    >
                      {getCell(
                        columns,
                        accountUser,
                        handleEditAccount,
                        handleDelete
                      )}
                    </TableRow>
                  );
                })
            ) : (
              <TableRowNoRows colSpan={5} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={accountUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmDialog {...dialog} />
    </Paper>
  );
};

export default AccountUsers;
