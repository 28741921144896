import { Suspense, SyntheticEvent, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import configureStore, { history } from "./redux/store";
import Routes from "./routes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import GlobalTheme from "./theme";

const { store, persistor } = configureStore({});

const App = (): JSX.Element => {
  const [showReload, setShowReload] = useState(false);
  // const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
  //   null
  // );
  // const onSWUpdate = (registration: ServiceWorkerRegistration): void => {
  //   setShowReload(true);
  //   setWaitingWorker(registration.waiting);
  // };

  useEffect(() => {
    // serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    serviceWorkerRegistration.unregister();
  }, []);

  const reloadPage = (e: SyntheticEvent): void => {
    e.preventDefault();
    // waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={GlobalTheme}>
            <>
              <Suspense
                fallback={
                  <Backdrop open>
                    <CircularProgress />
                    Loading...
                  </Backdrop>
                }
              >
                <Routes />
              </Suspense>
              <Snackbar
                open={showReload}
                message="A new version is available!"
                onClick={reloadPage}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                action={
                  <Button color="inherit" size="small" onClick={reloadPage}>
                    Reload
                  </Button>
                }
              />
            </>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
