import { useSelector } from "react-redux";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import classnames from "classnames";
import {
  useTheme,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "@mui/material/styles";
import { useState } from "react";
import Layout from "routes/components/Layouts";
import { MainRoute } from "routes/routes";
import { getWindow } from "utils/utilities";
import { IRootReducer } from "reducers/reducer.interface";
import Page404Internal from "components/Page404Internal/Page404Internal";
import { SnackbarProvider } from "notistack";
import { QuestionAnswer, Dashboard } from "@mui/icons-material";
import packageJSON from "../../../../package.json";
import { MENU_LIST } from "../constants";
import Header from "./Header";
import { hasAccess } from "../../Permissions/helpers";
import { Role } from "../../Permissions/interface";

const drawerWidth = 60;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    overflow: "hidden",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#f8f9fe",
  },
  noAccount: {
    marginLeft: "0 !important",
    width: "100% !important",
  },
  fullSize: {
    padding: 0,
  },
  list: {
    fontSize: "20px",
    padding: 0,
  },
  version: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
}));

const Main = (): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { profile, selectedAccount } = useSelector((state: IRootReducer) => ({
    profile: state.user.profile,
    selectedAccount: state.session.selectedAccount,
  }));
  const isAdmin = profile?.userType === Role.Admin;
  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };
  const { version } = packageJSON;
  const menuList = MENU_LIST.filter(
    (menu) =>
      menu.visible !== false &&
      hasAccess(
        profile?.userAccounts?.find((o) => o.accountId === selectedAccount?.id),
        menu.permission,
        profile?.userType
      ) &&
      (!menu.accountSelectedRequired ||
        (menu.accountSelectedRequired && selectedAccount))
  );

  const MenuIcons = (item: any, selected: boolean): JSX.Element => {
    switch (item.key) {
      case "campaigns":
        return (
          <QuestionAnswer style={{ color: selected ? "blue" : "unset" }} />
        );
      case "dashboard":
        return <Dashboard style={{ color: selected ? "blue" : "unset" }} />;
      default:
        return (
          <FontAwesomeIcon
            color={selected ? "blue" : "unset"}
            icon={item.icon}
            size="1x"
            fixedWidth
          />
        );
    }
  };
  // const settingsList = SETTING_LIST.filter(
  //   (menu) =>
  //     hasAccess(
  //       profile?.userAccounts?.find((o) => o.accountId === selectedAccount?.id),
  //       menu.permission,
  //       profile?.userType
  //     ) &&
  //     (!menu.accountSelectedRequired ||
  //       (menu.accountSelectedRequired && selectedAccount))
  // );
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />

      {menuList.length > 0 && (
        <>
          <List className={classes.list}>
            {menuList.map((item) => {
              const phoneGroupsPath = ["/phone/purchased", "/phone/search"];

              let urlPath = location.pathname.split("/").slice(0, 2).join("/");

              if (location.pathname === "/roles") {
                urlPath = "/user";
              }

              const currentPath = phoneGroupsPath.includes(urlPath)
                ? "/phone/groups"
                : urlPath;
              const itemPath = item.path.split("/").slice(0, 2).join("/");

              const isSelected = currentPath === itemPath;
              return (
                <Tooltip title={item.name} placement="right" key={item.key}>
                  <ListItem
                    button
                    selected={isSelected}
                    key={item.key}
                    component={Link}
                    to={item.path}
                  >
                    <ListItemIcon>{MenuIcons(item, isSelected)}</ListItemIcon>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
          <Divider />
        </>
      )}

      {/* <List className={classes.list}>
        {settingsList.map((item) => (
          <Tooltip title={item.name} placement="right">
            <ListItem
              button
              selected={location.pathname === item.path}
              key={item.key}
              component={Link}
              to={item.path}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={item.icon} size="1x" fixedWidth />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        ))}
      </List> */}
      <Typography className={classes.version} variant="caption">
        v{version}
      </Typography>
    </div>
  );

  const container =
    window !== undefined ? () => getWindow.document.body : undefined;
  const mainRoutes = MainRoute.filter(
    (route) =>
      hasAccess(
        profile?.userAccounts?.find((o) => o.accountId === selectedAccount?.id),
        route.permissions,
        profile?.userType
      ) &&
      ((selectedAccount?.id ? route.hasAccount : !route.hasAccount) ||
        route.hasAccount === undefined)
  );

  if (isAdmin) {
    const notifRoutes = MainRoute.filter((x) => x.path === "/notifications");
    mainRoutes.push(...notifRoutes);
  }

  return (
    <div className={classes.root}>
      <SnackbarProvider maxSnack={5}>
        <CssBaseline />
        <Header
          appBarStyle={classnames(classes.appBar, {
            [classes.noAccount]: !selectedAccount && !isAdmin,
          })}
          menuButtonStyle={classes.menuButton}
          onMenuClick={handleDrawerToggle}
          hiddenMenu={!!selectedAccount || isAdmin}
        />
        {(selectedAccount || isAdmin) && (
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with
            js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        )}
        <Switch>
          {mainRoutes.map(
            ({ fullSize, path, exact, component, key }: any, id: number) => (
              <Layout
                key={key || id}
                path={path}
                exact={exact}
                component={component}
                fullSize={fullSize}
                noAccount={!selectedAccount && !isAdmin}
              />
            )
          )}

          <Route path="*" component={Page404Internal} />
        </Switch>
      </SnackbarProvider>
    </div>
  );
};

export default Main;
