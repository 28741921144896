import { PropertyCategory } from "./interface";

export const NAME = "contactProperty";

export const ADD_PROPERTY = `${NAME}/ADD_PROPERTY`;
export const UPDATE_PROPERTY = `${NAME}/UPDATE_PROPERTY`;
export const DELETE_PROPERTY = `${NAME}/DELETE_PROPERTY`;

export enum Properties {
  address1 = "address1",
  address2 = "address2",
  city = "city",
  state = "state",
  zip = "zip",
  custom1 = "custom1",
  custom2 = "custom2",
  custom3 = "custom3",
  custom4 = "custom4",
  custom5 = "custom5",
  custom6 = "custom6",
  custom7 = "custom7",
  custom8 = "custom8",
  custom9 = "custom9",
  custom10 = "custom10",
  phoneNumber = "phoneNumber",
  email = "email",
  sourceId = "sourceId",
}

export const propertyOptions = {
  [PropertyCategory.Name]: [],
  [PropertyCategory.Identifier]: [
    Properties.sourceId,
    Properties.phoneNumber,
    Properties.email,
  ],
  [PropertyCategory.Address]: [
    Properties.address1,
    Properties.address2,
    Properties.city,
    Properties.state,
    Properties.zip,
  ],
  [PropertyCategory.Others]: [
    Properties.custom1,
    Properties.custom2,
    Properties.custom3,
    Properties.custom4,
    Properties.custom5,
    Properties.custom6,
    Properties.custom7,
    Properties.custom8,
    Properties.custom9,
    Properties.custom10,
  ],
};
