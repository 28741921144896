import { GET_PERMISSIONS, INITIAL_STATE } from "./constants";
import { IPermissionState, IPermissionAction } from "./interface";

export const reducer = (
  state: IPermissionState = INITIAL_STATE,
  action: IPermissionAction
): IPermissionState => {
  switch (action.type) {
    case GET_PERMISSIONS.REQUEST:
      return { ...state, isFetching: true };
    case GET_PERMISSIONS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        permissions: action.permissions,
      };
    case GET_PERMISSIONS.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
