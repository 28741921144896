import { createActionTypes } from "../../utils/utilities";
import { ICampaignState } from "./interface";

export const NAME = "campaigns";
export const FETCH_CAMPAIGNS = createActionTypes(`${NAME}/FETCH_CAMPAIGNS`);
export const FETCH_CAMPAIGN = createActionTypes(`${NAME}/FETCH_CAMPAIGN`);
export const FETCH_NEXT_CAMPAIGN = createActionTypes(
  `${NAME}/FETCH_NEXT_CAMPAIGN`
);
export const SAVING = createActionTypes(`${NAME}/SAVING`);
export const DELETE_CAMPAIGN = createActionTypes(`${NAME}/DELETE_CAMPAIGN`);
export const SET_CHILDREN = createActionTypes(`${NAME}/SET_CHILDREN`);
export const SET_TEMPLATES = createActionTypes(`${NAME}/SET_TEMPLATES`);
export const SET_CAMPAIGN_CHANGES = `${NAME}/SET_CAMPAIGN_CHANGES`;

export const INITIAL_STATE: ICampaignState = {
  data: [],
  children: {},
  saving: false,
  changing: false,
  fetching: false,
  campaign: null,
  nextCampaign: null,
  parentCampaign: null,
  errors: null,
  templates: [],
  total: 0,
  campaignChanges: [],
};

export const REQUEST_FROM_OPTIONS = [
  { value: "api", label: "API" },
  { value: "sms", label: "SMS" },
  { value: "webhook", label: "Webhook" },
];

export const DELAY_OPTIONS = [
  { value: "second", label: "Second" },
  { value: "minute", label: "Minute" },
  { value: "hour", label: "Hour" },
];

export const TYPES = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "YES,NO", label: "Options" },
];

export const STEPS = [
  "Campaing Options",
  "Contacts",
  "Reply Settings",
  "Media Files",
];
