import {
  SET_TAB_INDEX,
  INITIAL_STATE,
  TOGGLE_TAB,
  SET_SIMPLE_VALUE,
} from "./constants";
import { IMainState, MainAction } from "./interface";

export const reducer = (
  state: IMainState = INITIAL_STATE,
  action: MainAction
): IMainState => {
  switch (action.type) {
    case SET_TAB_INDEX:
      return { ...state, tabIndex: action.tabIndex || 0 };
    case TOGGLE_TAB:
      return {
        ...state,
        toggleTab: action.toggleTab,
        tabs: action.tabs,
      };
    case SET_SIMPLE_VALUE:
      return {
        ...state,
        simpleValues: {
          ...state.simpleValues,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
