import { CommonAction, Payload } from "common/common.interface";
import {
  FETCH_PHONE_GROUP,
  FETCH_PHONE_GROUPS,
  FETCH_PHONE_NUMBERS,
  INITIAL_STATE,
  PURCHASE_PHONE_NUMBER,
  RESET_STATE,
  SAVE_PHONE_GROUPS,
  SEARCH_PAYLOAD,
  SET_PHONE_GROUP_CHANGES,
} from "./constants";
import { IPhoneState, PhoneNumberStatus } from "./interface";

const setPurchased = (state: IPhoneState, payload: Payload): IPhoneState => {
  const data = [...state.data];
  const index = data.findIndex((o) => o.phoneNumber === payload.phoneNumber);
  if (index >= 0) {
    data[index].purchased = true;
  }
  return { ...state, data };
};
export const reducer = (
  state: IPhoneState = INITIAL_STATE,
  action: CommonAction
): IPhoneState => {
  switch (action.type) {
    case FETCH_PHONE_NUMBERS.REQUEST:
      return { ...state, fetching: true };
    case FETCH_PHONE_NUMBERS.FAILED:
      return { ...state, data: [], fetching: false, errors: action.errors };
    case FETCH_PHONE_NUMBERS.SUCCESS: {
      const { status } = action.payload;
      return {
        ...state,
        fetching: false,
        [status === PhoneNumberStatus.Available ? "available" : "data"]:
          action.payload.data,
        total: action.payload.total,
      };
    }
    case PURCHASE_PHONE_NUMBER.SUCCESS:
      return setPurchased(state, action.payload);
    case SEARCH_PAYLOAD:
      return { ...state, searchPayload: action.payload };
    case FETCH_PHONE_GROUPS.REQUEST:
      return { ...state, fetching: true };
    case FETCH_PHONE_GROUPS.FAILED:
      return {
        ...state,
        phoneGroups: [],
        fetching: false,
        errors: action.errors,
      };
    case FETCH_PHONE_GROUPS.SUCCESS:
      return {
        ...state,
        fetching: false,
        phoneGroups: action.payload.data,
        total: action.payload.total,
      };
    case FETCH_PHONE_GROUP.SUCCESS:
      return { ...state, phoneGroup: action.payload.data };
    case SET_PHONE_GROUP_CHANGES:
      return { ...state, phoneGroupChanges: action.payload.data };
    case SAVE_PHONE_GROUPS.REQUEST:
      return { ...state, saving: true };
    case SAVE_PHONE_GROUPS.FAILED:
      return { ...state, saving: false, errors: action.errors };
    case SAVE_PHONE_GROUPS.SUCCESS:
      return {
        ...state,
        phoneGroup: action.payload.data,
        phoneGroupChanges: [],
        saving: false,
      };
    case RESET_STATE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
