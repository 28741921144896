import { CommonAction } from "common/common.interface";
import { pick } from "lodash";
import { IRootReducer } from "reducers/reducer.interface";
import { Dispatch } from "redux";
import { del, get, patch, post } from "../../utils/requests";
import {
  DELETE_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER,
  FETCH_PHONE_NUMBERS,
  SEARCH_PAYLOAD,
  FETCH_PHONE_GROUPS,
  SAVE_PHONE_GROUPS,
  FETCH_PHONE_GROUP,
  SET_PHONE_GROUP_CHANGES,
  DELETE_PHONE_GROUP,
  PURCHASE_PHONE_NUMBER,
} from "./constants";
import {
  IPhoneGroup,
  IPhoneNumber,
  PhoneGroupStatus,
  PhonePayload,
} from "./interface";

export const getPhoneNumbers =
  (payload: any, accountId: any, type?: "purchased" | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: FETCH_PHONE_NUMBERS.REQUEST });
      const response = await get(
        `accounts/${accountId || "all"}/phone-numbers`,
        {
          ...payload,
          type,
        }
      );
      dispatch({
        type: FETCH_PHONE_NUMBERS.SUCCESS,
        payload: {
          data: type === "purchased" ? response.data.results : response.data,
          total: type === "purchased" ? response.data.total : 0,
          status: payload.status,
        },
      });
      return response;
    } catch (err) {
      dispatch({ type: FETCH_PHONE_NUMBERS.FAILED, errors: err });
      throw err;
    }
  };

export const buyPhoneNumber =
  (
    payload: {
      phoneNumber: string;
      friendlyName: string;
      capabilities: string;
      addressRequirement: string;
    },
    accountId: any
  ) =>
  async (dispatch: Dispatch): Promise<IPhoneNumber> => {
    try {
      const response = await post(
        `accounts/${accountId}/phone-numbers`,
        payload
      );
      dispatch({ type: PURCHASE_PHONE_NUMBER.SUCCESS, payload });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

export const massBuyPhoneNumber =
  (
    payload: {
      phoneNumbers: [];
    },
    accountId: any
  ) =>
  async (dispatch: Dispatch): Promise<unknown> => {
    try {
      const response = await post(
        `accounts/${accountId}/phone-numbers/buy-multiple`,
        payload
      );

      response?.data?.forEach((phone: any) => {
        dispatch({ type: PURCHASE_PHONE_NUMBER.SUCCESS, payload: phone });
      });

      return response.data;
    } catch (err) {
      throw err;
    }
  };

export const update =
  (accountId: string, id: string, payload: Partial<IPhoneNumber>) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: UPDATE_PHONE_NUMBER.REQUEST });
      const response = await patch(
        `accounts/${accountId}/phone-numbers/${id}`,
        payload
      );
      dispatch({
        type: UPDATE_PHONE_NUMBER.SUCCESS,
        payload: { data: response.data },
      });
      return response;
    } catch (err) {
      dispatch({ type: UPDATE_PHONE_NUMBER.FAILED, errors: err });
      throw err;
    }
  };

export const remove =
  (accountId: string, id: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: DELETE_PHONE_NUMBER.REQUEST });
      const response = await del(`accounts/${accountId}/phone-numbers/${id}`);
      dispatch({
        type: DELETE_PHONE_NUMBER.SUCCESS,
        payload: { data: response.data },
      });
      return response;
    } catch (err) {
      dispatch({ type: DELETE_PHONE_NUMBER.FAILED, errors: err });
      throw err;
    }
  };

export const updateSearchPayload =
  (payload: PhonePayload) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: SEARCH_PAYLOAD,
      payload,
    });
  };

export const getPhoneGroups =
  (
    accountId: any,
    skip = 0,
    take = 10,
    status?: PhoneGroupStatus[],
    sortByField = ""
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: FETCH_PHONE_GROUPS.REQUEST });
      const response = await get(
        `accounts/${accountId || "all"}/phone-groups`,
        {
          status,
          skip,
          take,
          sortByField,
        }
      );
      dispatch({
        type: FETCH_PHONE_GROUPS.SUCCESS,
        payload: {
          data: response.data.results,
          total: response.data.total,
        },
      });
      return response;
    } catch (err) {
      dispatch({ type: FETCH_PHONE_GROUPS.FAILED, errors: err });
      throw err;
    }
  };

export const createPhoneGroup =
  (accountId: string, payload: Partial<IPhoneGroup>) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: SAVE_PHONE_GROUPS.REQUEST });
      const { phoneNumbers, ...data } = payload;
      const response = await post(`accounts/${accountId}/phone-groups`, {
        ...data,
        phoneNumbers: phoneNumbers?.map((phone: IPhoneNumber) => ({
          id: phone.id,
        })),
      });
      dispatch({
        type: SAVE_PHONE_GROUPS.SUCCESS,
        payload: { data: response.data },
      });
      return response.data;
    } catch (err) {
      dispatch({ type: SAVE_PHONE_GROUPS.FAILED, errors: err.data });
      throw err;
    }
  };
export const updatePhoneGroup =
  (
    accountId: string,
    id: string,
    payload: Partial<IPhoneGroup>,
    fromNotification = false
  ) =>
  async (dispatch: Dispatch, getState: () => IRootReducer): Promise<any> => {
    try {
      const { phoneGroupChanges } = getState().phone;
      dispatch({ type: SAVE_PHONE_GROUPS.REQUEST });
      const response = await patch(
        `accounts/${accountId}/phone-groups/${id}`,
        fromNotification ? payload : pick(payload, [...phoneGroupChanges])
      );
      dispatch({
        type: SAVE_PHONE_GROUPS.SUCCESS,
        payload: { data: response.data },
      });
      return response.data;
    } catch (err) {
      dispatch({ type: SAVE_PHONE_GROUPS.FAILED, errors: err.data });
      throw err?.data;
    }
  };
export const deletePhoneGroup =
  (accountId: string, id: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: DELETE_PHONE_GROUP.REQUEST });
      const response = await del(`accounts/${accountId}/phone-groups/${id}`);
      dispatch({
        type: DELETE_PHONE_GROUP.SUCCESS,
        payload: { data: response.data },
      });
      return response.data;
    } catch (err) {
      dispatch({ type: DELETE_PHONE_GROUP.FAILED, errors: err });
      throw err;
    }
  };

export const setChanges = (changes: any): CommonAction => ({
  type: SET_PHONE_GROUP_CHANGES,
  payload: { data: changes },
});
export const setPhoneGroup = (
  phoneGroup?: Partial<IPhoneGroup>
): CommonAction => ({
  type: FETCH_PHONE_GROUP.SUCCESS,
  payload: { data: phoneGroup },
});

export const fetchPhoneGroup =
  (accountId: string, id: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: FETCH_PHONE_GROUP.REQUEST });
      const response = await get(`accounts/${accountId}/phone-groups/${id}`);
      dispatch(setPhoneGroup(response.data));
      return response.data;
    } catch (err) {
      dispatch({ type: FETCH_PHONE_GROUP.FAILED, errors: err });
      throw err;
    }
  };

export const deletePhoneGroupPhoneNumber =
  (
    accountId: string,
    id: string,
    phoneId: string,
    phoneNumbers?: IPhoneNumber[]
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: FETCH_PHONE_GROUP.REQUEST });
      const response = await patch(
        `accounts/${accountId}/phone-groups/${id}/phone-numbers/${phoneId}`,
        { phoneNumbers }
      );
      dispatch(setPhoneGroup(response.data));
      return response.data;
    } catch (err) {
      dispatch({ type: FETCH_PHONE_GROUP.FAILED, errors: err });
      throw err;
    }
  };
export const addPhoneGroupPhoneNumber =
  (
    accountId: string,
    id: string,
    phoneId: string,
    phoneNumbers?: IPhoneNumber[]
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: FETCH_PHONE_GROUP.REQUEST });
      const response = await post(
        `accounts/${accountId}/phone-groups/${id}/phone-numbers/${phoneId}`,
        { phoneNumbers }
      );
      dispatch(setPhoneGroup(response.data));
      return response.data;
    } catch (err) {
      dispatch({ type: FETCH_PHONE_GROUP.FAILED, errors: err });
      throw err;
    }
  };
