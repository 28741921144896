import { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { IRootReducer } from "reducers/reducer.interface";
import { Role } from "../../../Permissions/interface";

import { setSelectedAccount } from "../../../Auth/actions";
import { IAccount } from "../../interface";

const AccountSelect = (): JSX.Element | null => {
  const { selectedAccount, profile } = useSelector(
    (state: IRootReducer) => ({
      selectedAccount: state.session.selectedAccount,
      profile: state.user.profile,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleClose = (): void => setOpen(false);
  const handleToggle = (): void => setOpen(!open);
  const handleListKeyDown = (e: KeyboardEvent): void => {
    if (e.key === "Tab") {
      e.preventDefault();
      setOpen(false);
    }
  };

  const handleselectAccount = (account?: IAccount): void => {
    dispatch(setSelectedAccount(account));
    handleClose();
  };
  const clearSelected = (): void => {
    handleselectAccount();
  };

  const redirectToNewAccount = (): void => {
    handleToggle();
    dispatch(push("/accounts/new"));
  };

  useEffect(() => {
    if (!selectedAccount && profile && profile.userType !== Role.Admin) {
      handleselectAccount(profile.userAccounts?.[0]?.account);
    }
  }, [selectedAccount, profile]);
  return (
    <div>
      <Typography style={{ display: "inline", fontSize: "12px" }}>
        Selected Account:
      </Typography>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
      >
        {selectedAccount?.name || "Select account"}
        {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {profile?.userAccounts
                    ?.filter(
                      (userAccount) =>
                        userAccount?.account?.id !== selectedAccount?.id
                    )
                    .map((userAccount) => (
                      <MenuItem
                        key={userAccount.id}
                        onClick={() => handleselectAccount(userAccount.account)}
                      >
                        {userAccount?.account?.name}
                      </MenuItem>
                    ))}
                  <MenuItem onClick={redirectToNewAccount}>
                    Add new account
                  </MenuItem>
                  {profile?.userType === Role.Admin && (
                    <MenuItem onClick={clearSelected}>Clear Selected</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AccountSelect;
