import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { replace, push } from "connected-react-router";

import { Backdrop, CircularProgress } from "@mui/material";

import { setNewNotification } from "modules/Notification/actions";
import { disconnectSocket, initiateSocket, subscribe } from "utils/socket";
import { setCurrentUrl } from "modules/Auth";
import { fetchAppSettings } from "../modules/AppSettings";
import { getProfile, setProfileFetching } from "../modules/User";
import { IRootReducer } from "../reducers/reducer.interface";

import { routes } from "./routes";

const Routes = (): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const {
    currentUrl,
    rehydrated,
    profile,
    account,
    token,
    pathname,
    search,
    fetching,
  } = useSelector(
    (s: IRootReducer) => ({
      account: s.session.selectedAccount,
      profile: s.user.profile,
      fetching: s.user.fetchingUser,
      token: s.session.token,
      pathname: s.router.location.pathname,
      search: s.router.location.search,
      rehydrated: s.session._persist?.rehydrated,
      currentUrl: s.session.currentUrl,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const fetchProfile = async (): Promise<void> => {
    if (pathname.startsWith("/api")) return;
    // Note: What will happen if user doesnt have an account
    // Bug white page from signin to signup with user

    if (!profile && token) {
      if (pathname === "/account/new") {
        setLoading(true);
        await dispatch(getProfile());
        setLoading(false);
      } else if (!loading) {
        setLoading(true);
        await dispatch(getProfile());
        setLoading(false);

        if (profile && account && pathname === "/signin") {
          dispatch(push("/"));
        }
      }
    } else if (profile && !account && profile.userType !== "admin") {
      // only redirect if in signin page

      if (pathname === "/signin") {
        // eslint-disable-next-line max-len
        const message =
          "The User that you are trying to signin is not associated to any account. Create your own account.";
        dispatch(
          push("/account/new", {
            message,
          })
        );
      }
    } else if (profile && pathname === "/signin") {
      if (currentUrl) {
        dispatch(setCurrentUrl(null));
      }
      const redirectToUrl = currentUrl === "/signin" ? "/" : currentUrl;
      dispatch(replace(redirectToUrl || "/"));
    } else if (
      !fetching &&
      !token &&
      !profile &&
      ![
        "/signin",
        "/login-success",
        "/account/new",
        "/change-password",
        "/forgot-password",
      ].some((el) => pathname.includes(el))
    ) {
      dispatch(setCurrentUrl(`${pathname}${search}`));
      // Redirect to signin page if not signed in
      dispatch(replace("/signin"));
    } else {
      dispatch(setProfileFetching(false));
    }
  };

  useEffect(() => {
    if (profile?.id) {
      dispatch(fetchAppSettings());
      initiateSocket("/users", profile?.id);
      subscribe("/users", "joinedRoom", (notification) => {
        console.log("connected", { notification });
      });
      subscribe("/users", "notification", (notification) => {
        if (notification) {
          dispatch(setNewNotification(notification));
        }
      });
    }
    return () => {
      disconnectSocket("/users");
    };
  }, [profile]);
  useEffect(() => {
    if (rehydrated) fetchProfile();
  }, [rehydrated, fetching, profile, token, pathname]);

  const filteredRoutes = routes.filter((route) =>
    profile && (profile.userType === "admin" || account)
      ? route.isPrivate
      : !route.isPrivate
  );

  if (fetching) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Switch>
      {filteredRoutes.map(({ path, exact, component, key }, id) => (
        <Route
          key={key || id}
          path={path}
          exact={exact}
          component={component}
        />
      ))}
    </Switch>
  );
};

export default Routes;
