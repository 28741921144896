import { ICampaignRequestAction, ICampaignRequestState } from "./interface";
import {
  DELETE_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_REQUESTS,
  INITIAL_STATE,
  SAVING,
} from "./constants";

const deleteCampaign = (
  state: ICampaignRequestState,
  id: string
): ICampaignRequestState => {
  const data = [...state.data];
  const index = data.findIndex((campaign) => campaign.id === id);
  data.splice(index, 1);
  return { ...state, data };
};

export const reducer = (
  state: ICampaignRequestState = INITIAL_STATE,
  action: ICampaignRequestAction
): ICampaignRequestState => {
  switch (action.type) {
    case FETCH_CAMPAIGN_REQUESTS.REQUEST:
      return { ...state, fetching: true };
    case FETCH_CAMPAIGN_REQUESTS.FAILED:
      return { ...state, fetching: false };
    case FETCH_CAMPAIGN_REQUESTS.SUCCESS:
      return { ...state, data: action.payload.data, fetching: false };
    case FETCH_CAMPAIGN_REQUEST.REQUEST:
      return { ...state, fetching: true, errors: null };
    case FETCH_CAMPAIGN_REQUEST.FAILED:
      return { ...state, fetching: false };
    case FETCH_CAMPAIGN_REQUEST.SUCCESS:
      return {
        ...state,
        campaignRequest: action.payload.data,
        fetching: false,
        errors: null,
      };
    case SAVING.SUCCESS:
      return { ...state, errors: null };
    case SAVING.FAILED:
      return { ...state, errors: action.payload.errors };
    case DELETE_CAMPAIGN_REQUEST.SUCCESS:
      return deleteCampaign(state, action.payload.id);
    default:
      return state;
  }
};
