import { createActionTypes } from "../../utils/utilities";
import { IManagerBasedPermissionState } from "./interface";

export const NAME = "managerPermissions";
export const FETCH_LIST = createActionTypes(`${NAME}/FETCH_LIST`);

export const INITIAL_STATE: IManagerBasedPermissionState = {
  permissions: [],
  isFetching: false,
};
