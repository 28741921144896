import { Dispatch } from "redux";
import {
  GET_ACCOUNT_USERS,
  CREATE_ACCOUNT_USER,
  UPDATE_ACCOUNT_USER,
  FETCH_ACCOUNT_USER,
  DELETE_ACCOUNT_USER,
} from "./constants";
import { del, get, patch, post } from "../../utils/requests";
import {
  AccountUserType,
  IAccountUser,
  IAccountUsersAction,
} from "./interface";

export const createAccountUser =
  (user: IAccountUser, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<IAccountUser> => {
    dispatch({
      type: CREATE_ACCOUNT_USER.REQUEST,
    });

    try {
      const response = await post(`/accounts/${accountId}/users`, user);

      dispatch({
        type: CREATE_ACCOUNT_USER.SUCCESS,
        payload: { accountUser: response.data },
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: CREATE_ACCOUNT_USER.FAILED,
      });
      throw err;
    }
  };

export const updateAccountUser =
  (id: string, user: IAccountUser, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: UPDATE_ACCOUNT_USER.SUCCESS,
    });

    try {
      const response = await patch(`/accounts/${accountId}/users/${id}`, user);

      dispatch({
        type: UPDATE_ACCOUNT_USER.SUCCESS,
        payload: { accountUser: response.data },
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: UPDATE_ACCOUNT_USER.FAILED,
      });
      throw err;
    }
  };

export const setAccountUser = (
  accountUser?: IAccountUser
): IAccountUsersAction => ({
  type: FETCH_ACCOUNT_USER.SUCCESS,
  payload: { accountUser },
});
export const fetchAccountUser =
  (id: string, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: FETCH_ACCOUNT_USER.REQUEST,
    });

    try {
      const response = await get(`/accounts/${accountId}/users/${id}`);

      dispatch(setAccountUser(response.data));

      return response;
    } catch (err) {
      dispatch({
        type: FETCH_ACCOUNT_USER.FAILED,
      });
      throw err;
    }
  };
export const deleteAccountUser =
  (id: string, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: DELETE_ACCOUNT_USER.REQUEST,
    });

    try {
      const response = await del(`/accounts/${accountId}/users/${id}`);

      dispatch({
        type: DELETE_ACCOUNT_USER.SUCCESS,
        payload: { id },
      });

      return response;
    } catch (err) {
      dispatch({
        type: DELETE_ACCOUNT_USER.FAILED,
      });
      throw err;
    }
  };

export const getAccountUsers =
  (
    accountId: string | undefined,
    skip = 0,
    take = 10,
    userType?: AccountUserType
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET_ACCOUNT_USERS.REQUEST,
    });

    try {
      const params = { skip, take, userType };
      const response = await get(`/accounts/${accountId}/users`, params);

      dispatch({
        type: GET_ACCOUNT_USERS.SUCCESS,
        payload: { accountUsers: response.data.results },
      });
    } catch (err) {
      dispatch({
        type: GET_ACCOUNT_USERS.FAILED,
        errors: err,
      });
    }
  };
