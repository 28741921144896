import { createSlice } from "@reduxjs/toolkit";
import { NAME } from "./constants";
import { IOutboundsState } from "./interface";

const initialState: IOutboundsState = {
  outbounds: [],
  total: 0,
  loading: false,
  error: false,
};

export const outboundsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    startLoading: (state: IOutboundsState) => {
      state.loading = true;
    },
    outboundsFetched: (state: IOutboundsState, action) => {
      state.loading = false;
      state.outbounds = action.payload.results;
      state.total = action.payload.total;
    },
    outboundsFetchedWithError: (state: IOutboundsState, action) => {
      state.loading = false;
      state.outbounds = [];
      state.error = action.payload;
    },
  },
});

// Actions
export const { startLoading, outboundsFetched, outboundsFetchedWithError } =
  outboundsSlice.actions;

// Reducer
export const { reducer } = outboundsSlice;
