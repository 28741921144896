import moment, { Moment, MomentInput } from "moment-timezone";
import { getStore } from "redux/store";

interface ActionTypes {
  REQUEST: string;
  SUCCESS: string;
  FAILED: string;
}
export const createActionTypes = (name: string): ActionTypes => ({
  REQUEST: `${name}/REQUEST`,
  SUCCESS: `${name}/SUCCESS`,
  FAILED: `${name}/FAILED`,
});

export const getWindow: any = typeof window === "undefined" ? {} : window;

export const base64 = (str: string): string | null => {
  try {
    return Buffer.from(str).toString("base64");
  } catch (err) {
    return null;
  }
};

const fallbackCopyTextToClipboard = (text: string): boolean => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    return !!successful;
  } catch (err) {
    return false;
  }
};
export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return new Promise((resolve) =>
    navigator.clipboard.writeText(text).then(
      () => {
        resolve(true);
      },
      () => {
        resolve(false);
      }
    )
  );
};

export const validateUrl = (value: string): boolean => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

export const formatDate = (date: MomentInput): Moment => {
  const state = getStore().getState();
  const timeZone = state.session.selectedAccount?.timezone || moment.tz.guess();
  return moment.tz(date, timeZone);
};

// export const sortingBy = (fields: string, sortBy: string): string => {
//   const containsDirections = fields.includes("-");
//   const sortByField = containsDirections ? fields.split("-")[1] : fields;
//   if (sortByField === sortBy) {
//     if (containsDirections) {
//       return fields.replace("-", "");
//     }
//     return `-${fields}`;
//   }
//   return fields;
// };
