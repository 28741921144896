import { createActionTypes } from "../../utils/utilities";
import { IRolePermissionState } from "./interface";

export const NAME = "role/permission";
export const NEW_ROLE_PERMISSIONS = createActionTypes(
  `${NAME}/NEW_ROLE_PERMISSIONS`
);

export const INITIAL_STATE: IRolePermissionState = {
  rolePermissions: [],
  isFetching: false,
};
