/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { North, South, SwapVert } from "@mui/icons-material";

const SortingFields = ({
  label = "",
  onClick,
  currentSortField = "",
  field = "",
  exclude = [],
}: any): JSX.Element => {
  const signDirection = `${currentSortField}`?.includes("-") ? (
    <South style={{ fontSize: "10px", color: "red", marginTop: "5px" }} />
  ) : (
    <North style={{ fontSize: "10px", color: "green", marginTop: "5px" }} />
  );

  return exclude.includes(field)
    ? label || ""
    : label && (
        <span
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
          onClick={onClick}
        >
          {label}
          {currentSortField !== "" &&
          currentSortField.replace("-", "") === field ? (
            signDirection
          ) : (
            <SwapVert style={{ fontSize: "14px", marginTop: "5px" }} />
          )}
        </span>
      );
};

export default SortingFields;
