import { getStore } from "redux/store";
import io, { Socket } from "socket.io-client";

const socket: {
  [route: string]: Socket | null;
} = {};

export const initiateSocket = (
  route: string,
  room: string,
  event = "join"
): Socket | null => {
  if (socket[route]) return null;
  socket[route] = io(route, {
    auth: {
      token: getStore().getState().session.token,
    },
  });
  console.log(`Connecting socket...`, { socket, room, event });
  if (socket[route] && room) {
    socket[route]?.emit(event, room);
    return socket[route];
  }
  return null;
};
export const disconnectSocket = (route: string): void => {
  console.log("Disconnecting socket...");
  if (socket[route]) {
    socket[route]?.disconnect();
    socket[route] = null;
  }
};
export const emit = (route: string, event: string, room: string): void => {
  console.log("Emit socket...", { route, event, room });
  socket[route]?.emit(event, room);
};
export const subscribe = (
  route: string,
  room: string,
  cb: (message: any) => void
): void => {
  console.log("Subscribe socket...", { route, room });
  socket[route]?.on(room, (msg: any) => {
    console.log("Websocket event received!", { room, msg });
    return cb(msg);
  });
};
