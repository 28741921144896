import { IContact } from "modules/Contacts/interface";
import { IUser } from "modules/User/interface";

export enum PropertyCategory {
  Identifier = "indentifier",
  Name = "name",
  Address = "address",
  Others = "others",
}

export interface IContactProperty {
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletedAt?: Date;
  deletorId?: string;
  contactId?: string;
  name: string;
  value: string;
  type: PropertyCategory;
  contact?: IContact;
  creator?: IUser;
  updator?: IUser;
}
