import { Dispatch } from "redux";
import { GET, FETCH, DELETE, UPDATE } from "./constants";
import { del, get, patch } from "../../utils/requests";
import { IFile, IFilesAction } from "./interface";

export const set = (file?: Partial<IFile>): IFilesAction => ({
  type: FETCH.SUCCESS,
  payload: { file },
});

export const fetch =
  (id: string, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: FETCH.REQUEST,
    });

    try {
      const response = await get(`/accounts/${accountId}/files/${id}`);

      dispatch(set(response.data));

      return response;
    } catch (err) {
      dispatch({
        type: FETCH.FAILED,
      });
      throw err;
    }
  };

export const remove =
  (id: string, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: DELETE.REQUEST,
    });

    try {
      const response = await del(`/accounts/${accountId}/files/${id}`);

      dispatch({
        type: DELETE.SUCCESS,
        payload: { id },
      });

      return response;
    } catch (err) {
      dispatch({
        type: DELETE.FAILED,
      });
      throw err;
    }
  };
export const update =
  (id: string, accountId: string | undefined, filename: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: UPDATE.REQUEST,
    });

    try {
      const response = await patch(`/accounts/${accountId}/files/${id}`, {
        filename,
      });

      dispatch({
        type: UPDATE.SUCCESS,
        payload: { file: response.data },
      });

      return response;
    } catch (err) {
      dispatch({
        type: UPDATE.FAILED,
      });
      throw err;
    }
  };

export const getFiles =
  (accountId: string | undefined, skip = 0, take = 10, append = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET.REQUEST,
    });

    try {
      const params = { skip, take };
      const response = await get(`/accounts/${accountId}/files`, params);

      dispatch({
        type: GET.SUCCESS,
        payload: {
          files: response.data.results,
          total: response.data.total,
          append,
        },
      });
    } catch (err) {
      dispatch({
        type: GET.FAILED,
        errors: err,
      });
    }
  };
