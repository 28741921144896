import { ICampaign, ICampaignAction, ICampaignState } from "./interface";
import {
  DELETE_CAMPAIGN,
  FETCH_NEXT_CAMPAIGN,
  FETCH_CAMPAIGN,
  FETCH_CAMPAIGNS,
  INITIAL_STATE,
  SAVING,
  SET_CHILDREN,
  SET_TEMPLATES,
  SET_CAMPAIGN_CHANGES,
} from "./constants";

const deleteCampaign = (state: ICampaignState, id: string): ICampaignState => {
  const data = [...state.data];
  const index = data.findIndex((campaign) => campaign.id === id);
  data.splice(index, 1);
  return { ...state, data };
};
const setChildren = (
  state: ICampaignState,
  id: string,
  childId: string,
  campaigns: ICampaign[]
): ICampaignState => {
  const children = {
    ...state.children,
    [id]: [...(childId === id ? [] : state.children?.[id] || []), ...campaigns],
  };
  return { ...state, children };
};

export const reducer = (
  state: ICampaignState = INITIAL_STATE,
  action: ICampaignAction
): ICampaignState => {
  switch (action.type) {
    case FETCH_CAMPAIGNS.REQUEST:
      return { ...state, fetching: true };
    case FETCH_CAMPAIGNS.FAILED:
      return { ...state, fetching: false };
    case FETCH_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        fetching: false,
        total: action.payload.total,
      };
    case FETCH_CAMPAIGN.REQUEST:
      return { ...state, fetchingCampaign: true, errors: null };
    case FETCH_CAMPAIGN.FAILED:
      return { ...state, fetchingCampaign: false };
    case FETCH_CAMPAIGN.SUCCESS:
      return {
        ...state,
        [action.payload.isParent ? "parentCampaign" : "campaign"]:
          action.payload.data,
        fetchingCampaign: false,
        errors: null,
        campaignChanges: action.payload.clearChanges
          ? []
          : state.campaignChanges,
        saving: false,
        changing: !!action.payload.clearChanges,
      };
    case FETCH_NEXT_CAMPAIGN.REQUEST:
      return { ...state, fetchingNext: true };
    case FETCH_NEXT_CAMPAIGN.FAILED:
      return { ...state, fetchingNext: false };
    case FETCH_NEXT_CAMPAIGN.SUCCESS:
      return {
        ...state,
        nextCampaign: action.payload.data,
        fetchingNext: false,
      };
    case SAVING.REQUEST:
      return { ...state, saving: true };
    case SAVING.SUCCESS:
      return { ...state, errors: null, saving: false };
    case SAVING.FAILED:
      return { ...state, errors: action.payload.errors, saving: false };
    case DELETE_CAMPAIGN.SUCCESS:
      return deleteCampaign(state, action.payload.id);
    case SET_CHILDREN.SUCCESS:
      return setChildren(
        state,
        action.payload.id,
        action.payload.childId,
        action.payload.campaigns
      );
    case SET_TEMPLATES.SUCCESS:
      return { ...state, templates: action.payload.data };
    case SET_CAMPAIGN_CHANGES:
      return {
        ...state,
        campaignChanges: action.payload.data,
        changing: false,
      };
    default:
      return state;
  }
};
