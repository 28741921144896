import { Dispatch } from "redux";
import { GET_PERMISSIONS } from "./constants";
import { get } from "../../utils/requests";

export const getPermissions =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET_PERMISSIONS.REQUEST,
    });

    const response = await get(`/permissions`);
    try {
      dispatch({
        type: GET_PERMISSIONS.SUCCESS,
        permissions: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_PERMISSIONS.FAILED,
        errors: err,
      });
    }
  };
