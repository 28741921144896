import { NEW_ROLE_PERMISSIONS, INITIAL_STATE } from "./constants";
import { IRolePermissionState, IRolePermissionAction } from "./interface";

export const reducer = (
  state: IRolePermissionState = INITIAL_STATE,
  action: IRolePermissionAction
): IRolePermissionState => {
  switch (action.type) {
    case NEW_ROLE_PERMISSIONS.REQUEST:
      return { ...state, isFetching: true };
    case NEW_ROLE_PERMISSIONS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        rolePermissions: action.rolePermissions,
      };
    case NEW_ROLE_PERMISSIONS.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
