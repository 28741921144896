export const NAME = "session";
export const SET_TOKEN = `${NAME}/SET_TOKEN`;
export const ERROR_TOKEN = `${NAME}/ERROR_TOKEN`;
export const FACEBOOK_COLOR = "#3b5999";
export const LINKEDIN_COLOR = "#0077B5";
export const GOOGLE_COLOR = "#DB4437";
export const APPLE_COLOR = "#A3AAAE";
export const MICROSOFT = "#00A4EF";

interface SocialPalleteConfig {
  [key: string]: string;
}
export const SOCIAL_COLOR_PALLETE: SocialPalleteConfig = {
  facebook: "#3b5999",
  google: "#DB4437",
  apple: "#A3AAAE",
  microsoft: "#00A4EF",
  linkedin: "#0077B5",
};
