import { useEffect, SyntheticEvent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useTheme,
  useMediaQuery,
  Typography,
  Box,
  Grid,
  Theme,
} from "@mui/material";
import CustomInput from "components/CustomInput";

import { makeStyles } from "@mui/styles";

interface ConfirmDialogProps {
  confirmTitle?: string;
  confirmMessage?: string;
  onConfirm?: (e: SyntheticEvent, value: any) => Promise<any> | any;
  onClose?: (boo: boolean) => void;
  isDialogOpen?: boolean;
  hideButton?: boolean;
  acceptText?: string;
  cancelText?: string;
  className?: string;
  onCancel?: () => void;
  type?: string | null;
}

function Transition(props: any): JSX.Element {
  return <Slide direction="up" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    margin: "70px auto",
  },
  fullWidth: {
    width: "100%",
  },
  customTextField: {
    margin: theme.spacing(0),
    "& input::placeholder": {
      fontSize: "13px",
      lineHeight: "1",
    },
    width: "100%",
  },
  customLabel: {
    fontWeight: 500,
  },
  rootSelect: {
    width: "100%",
  },
  select: {
    padding: "8.5px 14px",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

const AuthenticateDialog = ({
  confirmTitle,
  confirmMessage,
  onConfirm,
  onClose,
  isDialogOpen,
  hideButton,
  acceptText,
  cancelText,
  className,
  onCancel,
  type,
}: ConfirmDialogProps): JSX.Element | null => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fieldValue, setFieldValue]: any = useState({});
  useEffect(() => {
    setFieldValue("");
  }, [isDialogOpen]);

  if (!isDialogOpen) {
    return null;
  }
  const CustomField = (): JSX.Element | null => {
    switch (type) {
      case "reject":
        return (
          <Grid sx={{ marginTop: "20px" }}>
            <Typography variant="subtitle2" align="left" gutterBottom>
              <Box fontWeight={600} color="rgb(97, 97, 97)">
                Please state your reason.
              </Box>
            </Typography>
            <CustomInput
              variant="outlined"
              classes={{ root: classes.customTextField }}
              InputLabelProps={{ className: classes.customLabel }}
              size="small"
              type="text"
              name="reason"
              value={fieldValue?.reason || ""}
              onChange={(e) =>
                setFieldValue({ [e.target.name]: e.target.value })
              }
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isDialogOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="confirm-dialog"
      aria-describedby="confirm-dialog"
      className={className}
    >
      <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog">
          {confirmMessage}

          {CustomField()}
        </DialogContentText>
      </DialogContent>
      {!hideButton && (
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              if (onCancel) onCancel();
              else if (onClose) {
                onClose(true);
              }
            }}
          >
            {cancelText}
          </Button>

          {onConfirm && (
            <Button
              disabled={loading}
              variant="contained"
              onClick={async (e: SyntheticEvent) => {
                e.stopPropagation();
                const promise = onConfirm && onConfirm(e, fieldValue);

                if (promise?.then) {
                  setLoading(true);
                  await promise;
                  setLoading(false);
                }
              }}
            >
              {acceptText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

AuthenticateDialog.defaultProps = {
  confirmTitle: "",
  confirmMessage: "",
  onConfirm: undefined,
  onClose: undefined,
  isDialogOpen: false,
  hideButton: false,
  acceptText: "Yes",
  cancelText: "Cancel",
  className: undefined,
  onCancel: undefined,
  type: null,
};

export default AuthenticateDialog;
