import { Payload } from "common/common.interface";
import {
  ADD_PROPERTY,
  DELETE_PROPERTY,
  UPDATE_PROPERTY,
} from "modules/ContactProperties/constants";
import {
  DEFAULT_CONTACT,
  DELETE,
  FETCH,
  GET,
  GET_CONTACTS,
  INITIAL_STATE,
} from "./constants";
import { IListsAction, IContactsState } from "./interface";

export const addContactProperty = (
  state: IContactsState,
  payload: Payload
): IContactsState => {
  const { contact = { ...DEFAULT_CONTACT(state.contact?.accountId || "") } } =
    state;
  contact?.properties?.push({
    type: payload.type,
    name: "",
    value: "",
  });

  return { ...state, contact };
};

export const updateContactProperty = (
  state: IContactsState,
  payload: Payload
): IContactsState => {
  const { contact = { ...DEFAULT_CONTACT(state.contact?.accountId || "") } } =
    state;
  const properties = [...(contact?.properties || [])];
  const { property, value, field } = payload;
  const index = properties?.findIndex((p) => p.name === property?.name);
  if (contact && properties?.[index]) {
    properties[index] = {
      ...properties?.[index],
      [field]: value,
    };
    if (properties.filter((p) => p.name === value).length > 1) {
      properties[index] = property;
      throw new Error("Duplicate");
    } else {
      contact.properties = properties;
    }
  }

  return { ...state, contact };
};
export const deleteContactProperty = (
  state: IContactsState,
  payload: Payload
): IContactsState => {
  const { contact = { ...DEFAULT_CONTACT(state.contact?.accountId || "") } } =
    state;
  const properties = [...(contact?.properties || [])];
  const index = properties?.findIndex((p) => p.name === payload.property?.name);
  if (contact && properties?.[index]) {
    properties.splice(index, 1);
    contact.properties = properties;
  }

  return { ...state, contact };
};

export const reducer = (
  state: IContactsState = INITIAL_STATE,
  action: IListsAction
): IContactsState => {
  switch (action.type) {
    case GET.REQUEST:
      return { ...state, isFetching: true };
    case GET.SUCCESS:
      return {
        ...state,
        isFetching: false,
        lists: action.payload.lists,
        total: action.payload.total,
      };
    case GET.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case GET_CONTACTS.REQUEST:
      return { ...state, isFetchingContacts: true };
    case GET_CONTACTS.SUCCESS:
      return {
        ...state,
        isFetchingContacts: false,
        contacts:
          action.payload?.params?.skip === 0
            ? action.payload.contacts
            : [...state.contacts, ...action.payload.contacts],
      };
    case GET_CONTACTS.FAILED:
      return {
        ...state,
        isFetchingContacts: false,
      };
    case FETCH.SUCCESS:
      return {
        ...state,
        list: action.payload.list,
      };
    case DELETE.SUCCESS: {
      const lists = [...state.lists];
      lists.splice(lists.findIndex((o) => o.id === action.payload.id));
      return { ...state, lists };
    }
    case ADD_PROPERTY:
      return addContactProperty(state, action.payload);
    case UPDATE_PROPERTY:
      return updateContactProperty(state, action.payload);
    case DELETE_PROPERTY:
      return deleteContactProperty(state, action.payload);
    default:
      return state;
  }
};
