import { createActionTypes } from "../../utils/utilities";

export const NAME = "app";

export const GET_APP_SETTINGS = createActionTypes(`${NAME}/GET_APP_SETTINGS`);

export const INITIAL_STATE = {
  settings: [],
  fetching: true,
};
