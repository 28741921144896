import { CommonAction } from "common/common.interface";
import { replace } from "connected-react-router";
import { Dispatch } from "redux";
import { post } from "utils/requests";
import { IAccount } from "../Accounts/interface";
import { setProfile } from "../User";
import { SessionAction } from "./auth.interface";
import {
  REMOVE_TOKEN,
  SET_CURRENT_URL,
  SET_SELECTED_ACCOUNT,
} from "./constants";

export const setSelectedAccount = (account?: IAccount): SessionAction => ({
  type: SET_SELECTED_ACCOUNT,
  payload: { account },
});

export const logout =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    await post("/users/logout");
    dispatch({
      type: REMOVE_TOKEN,
    });
    dispatch(setProfile());
    dispatch(setSelectedAccount());
    dispatch(replace("/"));
    window.location.reload();
  };

export const setCurrentUrl = (currentUrl: string | null): CommonAction => ({
  type: SET_CURRENT_URL,
  payload: { currentUrl },
});
