import { Theme } from "@mui/material/styles";
import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface LoaderProps {
  display?: "inline" | "block";
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: { color: "#fff", zIndex: theme.zIndex.drawer + 1 },
}));

const Loader = ({ display, open }: LoaderProps): JSX.Element => {
  const classes = useStyles();

  return display === "block" ? (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress />
    </Backdrop>
  ) : (
    <CircularProgress />
  );
};

Loader.defaultProps = {
  display: "block",
};

export default Loader;
