import { Alert, Snackbar } from "@mui/material";
import { Theme } from "@mui/material/styles";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { SyntheticEvent } from "react";
import { setSimpleValue } from "../../modules/Main/actions";
import { IRootReducer } from "../../reducers/reducer.interface";

export interface RouteParam {
  path: string;
  exact: boolean;
  component: any;
  fullSize: boolean;
  noAccount: boolean;
}
const drawerWidth = 60;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    overflow: "hidden",
  },
  content: {
    height: "calc(100vh - 64px)",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#f8f9fe",
  },
  noAccount: {
    marginLeft: "0 !important",
    width: "100% !important",
  },
  fullSize: {
    padding: 0,
  },
  list: {
    fontSize: "20px",
    padding: 0,
  },
  version: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
}));

const Layouts = ({
  path,
  exact,
  component: Component,
  fullSize,
  noAccount,
}: RouteParam): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state: IRootReducer) => ({
    selectedAccount: state.session.selectedAccount,
    snackbar: state.main.simpleValues.snackbar,
  }));

  const handleClose = (event?: SyntheticEvent, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSimpleValue("snackbar", null));
  };
  return (
    <main
      className={classnames(classes.content, {
        [classes.noAccount]: noAccount,
        [classes.fullSize]: fullSize,
      })}
    >
      <Route
        path={path}
        exact={exact}
        render={(props) => <Component {...props} />}
      />
      <Snackbar open={!!snackbar} autoHideDuration={2000} onClose={handleClose}>
        {snackbar && (
          <Alert onClose={handleClose} severity={snackbar?.type || "error"}>
            {snackbar?.message}
          </Alert>
        )}
      </Snackbar>
    </main>
  );
};

export default Layouts;
