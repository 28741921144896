import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { replace } from "connected-react-router";
import { setCurrentUrl, setSelectedAccount } from "modules/Auth/actions";
import { REMOVE_TOKEN } from "modules/Auth/constants";
import { setProfile } from "modules/User/actions";
import { getStore } from "../redux/store";

export const instance = axios.create({
  baseURL: "/api",
  headers: {},
});

const myInterceptor = instance.interceptors.request.use(
  (config: AxiosRequestConfig = {}) => {
    // get token from persist
    const { token } = getStore().getState().session;

    let headers = { ...config.headers };

    if (config.url?.includes("/signin")) {
      headers = { ...headers };
    }

    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    return {
      ...config,
      headers,
    };
  },
  (error) => Promise.reject(error)
);

const createRequestMethod = async (
  method: string,
  url: string,
  payload: any
): Promise<any> => {
  try {
    let request;

    const params = payload || {};
    switch (method) {
      case "get":
        request = instance.get(url, { params, cancelToken: params?.token });
        break;

      case "post":
        request = instance.post(url, params);
        break;

      case "put":
        request = instance.put(url, params);
        break;

      case "patch":
        request = instance.patch(url, params);
        break;

      case "delete":
        request = instance.delete(url, params);
        break;

      default:
        break;
    }

    return await request;
  } catch ({ status, response }) {
    // InvalidSessionToken
    if (response?.status === 401) {
      const { pathname, search } = getStore().getState().router.location;
      getStore().dispatch(setCurrentUrl(`${pathname}${search}`));

      getStore().dispatch({
        type: REMOVE_TOKEN,
      });
      getStore().dispatch(setProfile());
      getStore().dispatch(setSelectedAccount());
      return getStore().dispatch(replace("/"));
    }
    throw response;
  }
};

interface IResponse {
  status: number;
  data: any;
}

const callRequest = async (
  method: string,
  url: string,
  payload?: any
): Promise<IResponse> => {
  try {
    const response: AxiosResponse = await createRequestMethod(
      method,
      url,
      payload
    );
    if (response?.status >= 200 && response?.status <= 400) {
      return {
        status: response?.status,
        data: response.data,
      };
    }
    throw response;
  } catch (err) {
    throw err;
  }
};

export const get = (url: string, payload?: any): Promise<IResponse> => {
  return callRequest("get", url, payload);
};

export const post = (url: string, payload?: any): Promise<IResponse> => {
  return callRequest("post", url, payload);
};

export const put = (url: string, payload?: any): Promise<IResponse> => {
  return callRequest("put", url, payload);
};

export const patch = (url: string, payload?: any): Promise<IResponse> => {
  return callRequest("patch", url, payload);
};

export const del = (url: string, payload?: any): Promise<IResponse> => {
  return callRequest("delete", url, payload);
};

export const ejectInterceptor = (): void => {
  axios.interceptors.request.eject(myInterceptor);
};
