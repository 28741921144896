import { Action } from "redux";
/* eslint-disable camelcase */

export enum Role {
  Admin = "admin",
  User = "user",
}
export interface IManagerBasedPermission {
  id?: string;
  name: string;
  label: string;
  description: string;
  parentId?: string;
  boolean?: boolean;
}

export interface FormattedPermission {
  id?: string;
  name: string;
  label: string;
  description: string;
  parentId?: string;
  boolean?: boolean;
  permissions: FormattedPermission[];
}

export enum ManagerPermissions {
  purchase_phone_numbers = "purchase_phone_numbers",
  approve_phone_groups = "approve_phone_groups",
  start_campaigns = "start_campaigns",
}

export interface IManagerBasedPermissionState {
  permissions: IManagerBasedPermission[];
  isFetching: boolean;
}

export interface IManagerBasedPermissionAction extends Action {
  isFetching: boolean;
  permissions: IManagerBasedPermission[];
}
