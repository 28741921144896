import { createActionTypes } from "utils/utilities";
import { INotificationsState } from "./interface";

export const NAME = "notifications";
export const SET_NEW_NOTIFICATION = `${NAME}/SET_NEW_NOTIFICATION`;
export const FETCH_NOTIFICATIONS = createActionTypes(
  `${NAME}/FETCH_NOTIFICATIONS`
);
export const DELETE_NOTIFICATION = createActionTypes(
  `${NAME}/DELETE_NOTIFICATION`
);
export const GET_NOTIFICATION = createActionTypes(`${NAME}/GET_NOTIFICATION`);

export const CLEAR_NOTIFICATIONS = `${NAME}/CLEAR_NOTIFICATIONS`;
export const INITIAL_STATE: INotificationsState = {
  notifications: [],
  approvals: [],
  total: 0,
  fetching: false,
};

export const TABLE_HEADERS = [
  {
    label: "User",
    key: "profile-pic-notification",
  },
  {
    label: "Message",
    key: "message-notification",
  },
  {
    label: "Actions",
    key: "actions-notification",
  },
];
