import { ChangeEvent, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IRootReducer } from "reducers/reducer.interface";
import Loader from "components/Loader";
import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Listing from "./Listing";
import SearchOptions from "./SearchOptions";
import PhoneGroups from "./PhoneGroups";
import { SAVE_PHONE_GROUPS } from "../constants";

interface LinkTabProps {
  label: string;
  to: string;
}

const LinkTab = ({ to, ...props }: LinkTabProps): JSX.Element => {
  return (
    <Tab
      component={Link}
      {...props}
      onClick={(event: any) => {
        event.stopPropagation();
      }}
      to={to}
    />
  );
};

const useStyles = makeStyles(() => ({
  tabs: {
    alignItems: "center",
    background: "#FFFFFF",
    color: "#000000",
    height: 48,
  },
}));
enum Page {
  Search = "/phone/search",
  Purchased = "/phone/purchased",
  Groups = "/phone/groups",
}
enum TabDef {
  Purchased = 1,
  Search = 2,
  Groups = 0,
}
const PhoneLanding = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { fetching } = useSelector((state: IRootReducer) => ({
    fetching: state.phone.fetching,
  }));
  const { pathname } = useLocation();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    dispatch({ type: SAVE_PHONE_GROUPS.FAILED, errors: {} });
  }, []);

  const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  const renderContent = (): JSX.Element | null => {
    if (value === TabDef.Search) {
      return (
        <>
          <SearchOptions />
          <Listing />
        </>
      );
    }
    if (value === TabDef.Purchased) {
      return <Listing type="purchased" />;
    }
    if (value === TabDef.Groups) {
      return <PhoneGroups />;
    }
    return null;
  };

  const getPage = (pn: string): Page => {
    switch (pn) {
      case Page.Purchased:
        return Page.Purchased;
      case Page.Search:
        return Page.Search;
      case Page.Groups:
        return Page.Groups;
      default:
        return Page.Purchased;
    }
  };

  useEffect(() => {
    const selected: Page = getPage(pathname);

    setValue(
      {
        [Page.Groups]: TabDef.Groups,
        [Page.Purchased]: TabDef.Purchased,
        [Page.Search]: TabDef.Search,
      }[selected]
    );
  }, [pathname]);
  const appBar = document.getElementById("app-bar");

  return (
    <Box
      sx={{ width: "100%", height: "calc(100vh - 160px)", marginTop: "48px" }}
    >
      <Loader open={fetching} display="block" />
      {appBar &&
        createPortal(
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            centered
            aria-label="campaigns type"
            className={classes.tabs}
          >
            <LinkTab label="Phone Groups" to={Page.Groups} />
            <LinkTab label="Purchased" to={Page.Purchased} />
            <LinkTab label="Search" to={Page.Search} />

            <div
              id="button-container"
              style={{ position: "absolute", right: "12px" }}
            />
          </Tabs>,
          appBar
        )}
      {renderContent()}
    </Box>
  );
};

export default PhoneLanding;
