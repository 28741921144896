import { Dispatch } from "redux";
import { Role } from "../Permissions/interface";
import { IRootReducer } from "../../reducers/reducer.interface";
import { get, patch, post } from "../../utils/requests";
import { setSelectedAccount } from "../Auth/actions";
import { REMOVE_TOKEN } from "../Auth/constants";
import {
  GET_USERS,
  SET_PROFILE,
  SET_FETCHING,
  UPDATE_USER,
  NEW_USER,
  FETCH_USER,
} from "./constants";

import { IUser, UserAction } from "./interface";

export const setProfile = (profile?: IUser): UserAction => ({
  type: SET_PROFILE.SUCCESS,
  payload: { profile },
});

export const getProfile =
  () =>
  async (dispatch: Dispatch, getState: () => IRootReducer): Promise<void> => {
    try {
      dispatch({
        type: SET_PROFILE.REQUEST,
      });
      const response = await get("/profile");
      const { selectedAccount } = getState().session;
      const user = response.data;
      if (!selectedAccount && user.userType !== Role.Admin) {
        dispatch(setSelectedAccount(user.userAccounts?.[0]?.account));
      }

      dispatch(setProfile(user));
    } catch (err) {
      dispatch({
        type: SET_PROFILE.FAILED,
        errors: err,
      });
      dispatch({
        type: REMOVE_TOKEN,
        errors: err,
      });
    }
  };

export const getUsers =
  (skip = 0, take = 10) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: GET_USERS.REQUEST,
      });
      const params = { skip, take };
      const response = await get("/users", params);

      dispatch({
        type: GET_USERS.SUCCESS,
        payload: {
          data: response.data,
        },
      });
    } catch (err) {
      dispatch({
        type: GET_USERS.FAILED,
        errors: err,
      });
    }
  };

export const setProfileFetching = (fetching: boolean): UserAction => ({
  type: SET_FETCHING,
  payload: {
    fetching,
  },
});

export const createUser =
  (payload: Partial<IUser>) =>
  async (dispatch: Dispatch, getState: () => IRootReducer): Promise<any> => {
    try {
      dispatch({
        type: NEW_USER.REQUEST,
      });
      const currentUser = getState().user.profile;

      const response = await post(
        currentUser ? "/users" : "/users/register",
        payload
      );
      const user = response.data;

      if (!currentUser) {
        dispatch(setProfile(user));
      }

      return user;
    } catch (err) {
      dispatch({
        type: NEW_USER.FAILED,
      });
      throw err;
    }
  };

export const updateUser =
  (payload: Partial<IUser>, userId: any) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: UPDATE_USER.REQUEST,
    });
    try {
      const response: any = await patch(`/users/${userId}`, payload);
      dispatch({
        type: UPDATE_USER.SUCCESS,
        data: response?.data,
      });
      return response;
    } catch (err) {
      dispatch({
        type: UPDATE_USER.FAILED,
      });
      throw err;
    }
  };
export const setUser = (user?: IUser): UserAction => ({
  type: FETCH_USER.SUCCESS,
  payload: { user },
});
export const fetchUser =
  (userId: any) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: FETCH_USER.REQUEST,
    });
    try {
      const response: any = await get(`/users/${userId}`);
      dispatch(setUser(response?.data));
      return response;
    } catch (err) {
      dispatch({
        type: FETCH_USER.FAILED,
      });
      throw err;
    }
  };

//
export const findEmail = (email: string) => async (): Promise<IUser> => {
  try {
    const response = await get(`/users/find/email`, { email });

    return response.data;
  } catch (err) {
    throw err;
  }
};
