import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/requests";
import {
  logsFetched,
  logsFetchedWithError,
  startLoading,
  addNewLog,
} from "./reducer";

export const fetchLogs = createAsyncThunk(
  "logs/get",
  async (
    { accountId = "all", take = 10, skip = 0, url = "" }: any,
    thunkApi
  ) => {
    thunkApi.dispatch(startLoading());
    try {
      const response = await get(`/accounts/${accountId}/logs`, {
        take,
        skip,
        url,
      });
      const { data } = response;

      if (data) {
        thunkApi.dispatch(logsFetched(data));
      } else {
        throw new Error("Failed to load logs.");
      }
    } catch (error) {
      thunkApi.dispatch(logsFetchedWithError(error));
    }
  }
);

export const newLog = createAsyncThunk(
  "logs/new",
  async ({ log }: any, thunkApi) => {
    console.log(log);
    thunkApi.dispatch(addNewLog(log));
  }
);
