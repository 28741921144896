import { Payload } from "common/common.interface";
import { IContactProperty } from "modules/ContactProperties/interface";
import { Action } from "redux";
import { IAccount } from "../Accounts/interface";
import { IUser } from "../User/interface";

export enum ContactsType {
  User = "user",
  Manager = "manager",
}

export enum ContactStatus {
  Active = "active",
}

export interface IContact {
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletedAt?: Date;
  deletorId?: string;
  accountId?: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  status?: ContactStatus;
  account?: IAccount;
  creator?: IUser;
  updator?: IUser;
  properties?: IContactProperty[];
}

export enum ListStatus {
  Draft = "draft",
  Active = "active",
  Inactive = "inactive",
}

export enum ListType {
  List = "list",
}

export interface IList {
  id?: string;
  createdAt?: Date;
  creatorId?: string;
  updatedAt?: Date;
  updatorId?: string;
  deletedAt?: Date;
  deletorId?: string;
  accountId?: string;
  name: string;
  description?: string;
  status: ListStatus;
  type: ListType;
  creator?: IUser;
  updator?: IUser;
  contacts?: IContact[];
  contactsCount?: number;
  campaignContacts?: any[];
}

export interface IContactsState {
  lists: IList[];
  contacts: IContact[];
  total: number;
  list: IList | null;
  isFetching: boolean;
  isFetchingContacts: boolean;
  contact: IContact | null;
}

export interface IListsAction extends Action {
  payload: Payload;
}
