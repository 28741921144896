import { ChangeEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import EditOutlined from "@mui/icons-material/EditOutlined";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  IconButton,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Link } from "react-router-dom";
import { DeleteOutlined } from "@mui/icons-material";
import { bindActionCreators } from "redux";
import { IRootReducer } from "../../../reducers/reducer.interface";
import * as roleActions from "../actions";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "label", label: "Label", minWidth: 100 },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
  },
  {
    id: "actions",
    label: "",
    minWidth: 50,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "calc(100vh - 160px)",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
  title: {
    position: "relative",
    padding: "16px",
  },
  new: {
    float: "right",
  },
});

const getCell = (
  columnsData: Column[],
  role: any,
  handleEditRole: any,
  handleDeleteRole: any,
  index: number
): JSX.Element[] => {
  return columnsData.map((column) => {
    const value = role[column.id];
    // eslint-disable-next-line no-nested-ternary
    return column.id === "actions" ? (
      <TableCell key={column.id} align={column.align}>
        {index === 0 ? null : (
          <>
            <IconButton
              aria-label="edit"
              onClick={() => handleEditRole(role.id)}
            >
              <EditOutlined />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={() => handleDeleteRole(role.id)}
            >
              <DeleteOutlined />
            </IconButton>
          </>
        )}
      </TableCell>
    ) : (
      <TableCell key={column.id} align={column.align}>
        {value}
      </TableCell>
    );
  });
};

const RolesTable = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { roles, selectedAccount } = useSelector((state: IRootReducer) => ({
    roles: state.roles.roles,
    selectedAccount: state.session.selectedAccount,
  }));

  const { deleteRole, setRole } = bindActionCreators(
    { ...roleActions },
    useDispatch()
  );

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditRole = (id: string): void => {
    dispatch(push(`/roles/${id}`));
  };

  const handleDeleteRole = async (roleId: string): Promise<void> => {
    try {
      const accountId: any = selectedAccount?.id;
      await deleteRole(accountId, roleId);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setRole([]);
  }, []);

  return (
    <Paper className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span>Role</span>
        <Button
          component={Link}
          to="/roles/new"
          variant="contained"
          color="primary"
          className={classes.new}
        >
          New Role
        </Button>
      </Typography>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {roles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((role, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={role?.id}>
                    {getCell(
                      columns,
                      role,
                      handleEditRole,
                      handleDeleteRole,
                      index
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={roles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default RolesTable;
