import { SET_TOKEN } from "../Login/constant";
import {
  INITIAL_STATE,
  REMOVE_TOKEN,
  SET_CURRENT_URL,
  SET_SELECTED_ACCOUNT,
} from "./constants";
import { ISessionState, SessionAction } from "./auth.interface";

export const reducer = (
  state: ISessionState = INITIAL_STATE,
  action: SessionAction
): ISessionState => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case REMOVE_TOKEN:
      return {
        ...state,
        token: undefined,
      };
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload?.account,
      };
    case SET_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.payload?.currentUrl,
      };

    default:
      return state;
  }
};
