import { createActionTypes } from "../../utils/utilities";
import { IRoleState } from "./interface";

export const NAME = "roles";
export const CREATE_ROLE = createActionTypes(`${NAME}/CREATE_ROLE`);
export const GET_ROLES = createActionTypes(`${NAME}/GET_ROLES`);
export const DELETE_ROLE = createActionTypes(`${NAME}/DELETE_ROLE`);
export const FETCH_ROLE = createActionTypes(`${NAME}/FETCH_ROLE`);
export const UPDATE_ROLE = createActionTypes(`${NAME}/UPDATE_ROLE`);
export const FETCH_ROLE_PERMISSION = createActionTypes(
  `${NAME}/FETCH_ROLE_PERMISSION`
);
export const SET_ROLE = `${NAME}/SET_ROLE`;
export const UPDATE_PERMISSION = `${NAME}/UPDATE_PERMISSION`;
export const CREATE_PERMISSION = `${NAME}/CREATE_PERMISSION`;

export const INITIAL_STATE: IRoleState = {
  roles: [],
  role: undefined,
  isFetching: false,
  isCreating: false,
  isDeleting: false,
};
