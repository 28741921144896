import { createActionTypes } from "../../utils/utilities";
import {
  IList,
  IContactsState,
  ListStatus,
  ListType,
  ContactStatus,
  IContact,
} from "./interface";

export const NAME = "contacts";

export const GET = createActionTypes(`${NAME}/GET`);
export const CREATE = createActionTypes(`${NAME}/CREATE`);
export const UPDATE = createActionTypes(`${NAME}/UPDATE`);
export const FETCH = createActionTypes(`${NAME}/FETCH`);
export const DELETE = createActionTypes(`${NAME}/DELETE`);
export const GET_CONTACTS = createActionTypes(`${NAME}/GET_CONTACTS`);

export const INITIAL_STATE: IContactsState = {
  lists: [],
  contacts: [],
  total: 0,
  list: null,
  isFetching: false,
  isFetchingContacts: false,
  contact: null,
};

export const DEFAULT_CONTACT = (accountId: string): IContact => ({
  accountId,
  phoneNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  properties: [],
  status: ContactStatus.Active,
});

export const DEFAULT = (accountId: string): IList => ({
  accountId,
  name: "",
  description: "",
  status: ListStatus.Draft,
  type: ListType.List,
});
