import { IAppSettingAction, IAppSettingState } from "./interface";
import { GET_APP_SETTINGS, INITIAL_STATE } from "./constants";

export const reducer = (
  state: IAppSettingState = INITIAL_STATE,
  action: IAppSettingAction
): IAppSettingState => {
  switch (action.type) {
    case GET_APP_SETTINGS.REQUEST:
      return { ...state, fetching: true };
    case GET_APP_SETTINGS.FAILED:
      return { ...state, fetching: false };
    case GET_APP_SETTINGS.SUCCESS:
      return { ...state, fetching: false, settings: action.payload?.settings };
    default:
      return state;
  }
};
