import { FETCH_LIST, INITIAL_STATE } from "./constants";
import {
  IManagerBasedPermissionState,
  IManagerBasedPermissionAction,
} from "./interface";

export const reducer = (
  state: IManagerBasedPermissionState = INITIAL_STATE,
  action: IManagerBasedPermissionAction
): IManagerBasedPermissionState => {
  switch (action.type) {
    case FETCH_LIST.REQUEST:
      return { ...state, isFetching: true };
    case FETCH_LIST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        permissions: action.permissions,
      };
    case FETCH_LIST.FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
