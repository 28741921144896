import { createActionTypes } from "utils/utilities";
import { IPhoneState, PhoneGroupStatus, UseCase } from "./interface";

export const NAME = "phone";

export const FETCH_PHONE_NUMBERS = createActionTypes(
  `${NAME}/FETCH_PHONE_NUMBERS`
);
export const UPDATE_PHONE_NUMBER = createActionTypes(
  `${NAME}/UPDATE_PHONE_NUMBER`
);
export const DELETE_PHONE_NUMBER = createActionTypes(
  `${NAME}/DELETE_PHONE_NUMBER`
);
export const FETCH_PHONE_GROUPS = createActionTypes(
  `${NAME}/FETCH_PHONE_GROUPS`
);
export const FETCH_PHONE_GROUP = createActionTypes(`${NAME}/FETCH_PHONE_GROUP`);
export const SAVE_PHONE_GROUPS = createActionTypes(`${NAME}/SAVE_PHONE_GROUPS`);
export const DELETE_PHONE_GROUP = createActionTypes(
  `${NAME}/DELETE_PHONE_GROUP`
);
export const PURCHASE_PHONE_NUMBER = createActionTypes(
  `${NAME}/PURCHASE_PHONE_NUMBER`
);
export const SET_PHONE_GROUP_CHANGES = `${NAME}/SET_PHONE_GROUP_CHANGES`;

export const SEARCH_PAYLOAD = "SEARCH_PAYLOAD";
export const RESET_STATE = "RESET_STATE";

export const INITIAL_STATE: IPhoneState = {
  available: [],
  data: [],
  fetching: false,
  saving: false,
  errors: null,
  searchPayload: {
    smsEnabled: true,
    mmsEnabled: true,
    beta: true,
    addressRequirement: "any",
    numberType: [],
    countryCode: "CA",
    contains: "",
    maxResults: 20,
  },
  phoneGroups: [],
  phoneGroup: null,
  phoneGroupChanges: [],
  total: 0,
};

const AutomaticApproval = [
  {
    id: "2fa",
    label: "Two-Factor authentication (2FA)",
  },
  {
    id: "account_notification",
    label: "Account Notification",
  },
  {
    id: "customer_care",
    label: "Customer Care",
  },
  {
    id: "delivery_notification",
    label: "Delivery Notification",
  },
  {
    id: "fraud_alert",
    label: "Fraud Alert Messaging",
  },
  {
    id: "higher_education",
    label: "Higher Education",
  },
  {
    id: "low_volume_mixed",
    label: "Low Volume Mixed",
  },
  {
    id: "marketing",
    label: "Marketing",
  },
  {
    id: "mixed",
    label: "Mixed",
  },
  {
    id: "polling_and_voting",
    label: "Polling and voting",
  },
  {
    id: "public_service_annoucement",
    label: "Public Service Announcement",
  },
  {
    id: "security_alert",
    label: "Security Alert",
  },
];

const RequireCarrierReview = [
  {
    id: "agents_franchises",
    label: "Agents and Franchises",
  },
  {
    id: "emergency",
    label: "Emergency",
  },
  {
    id: "proxy",
    label: "Proxy",
  },
  {
    id: "social",
    label: "Social",
  },
  {
    id: "sweepstake",
    label: "Sweepstake",
  },
];

export const UseCases = [AutomaticApproval, RequireCarrierReview];

export const INBOUND_METHODS = [
  { id: "post", label: "POST" },
  { id: "get", label: "GET" },
];

export const STEPS = ["Properties", "Phone Numbers", "A2P & Compliance"];

export const USE_CASES = [
  {
    value: UseCase.NotifyUsers,
    label: "Notify My Users",
  },
  {
    value: UseCase.MarketService,
    label: "Market My Services",
  },
  {
    value: UseCase.VerifyUsers,
    label: "Verify My Users",
  },
  {
    value: UseCase.EngageDiscussion,
    label: "Engage In Discussion",
  },
  {
    value: UseCase.PollSurvey,
    label: "Poll or survey users",
  },
  {
    value: UseCase.NotListed,
    label: "Not Listed Here",
  },
];

export enum Steps {
  SProperties = 0,
  SPhoneNumber = 1,
  SCompliance = 2,
}
