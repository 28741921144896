import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/requests";
import {
  outboundsFetched,
  outboundsFetchedWithError,
  startLoading,
} from "./reducer";

export const fetchOutbounds = createAsyncThunk(
  "outbounds/get",
  async ({ accountId = "", take = 10, skip = 0 }: any, thunkApi) => {
    thunkApi.dispatch(startLoading());
    try {
      const response = await get(`/accounts/${accountId}/outbounds`, {
        take,
        skip,
      });
      const { data } = response;

      if (data) {
        thunkApi.dispatch(outboundsFetched(data));
      } else {
        throw new Error("Failed to load outbounds.");
      }
    } catch (error) {
      thunkApi.dispatch(outboundsFetchedWithError(error));
    }
  }
);
