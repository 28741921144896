import { IUser } from "../User/interface";
import { createActionTypes } from "../../utils/utilities";
import { AccountUserType, IAccountUser, IAccountUsersState } from "./interface";

export const NAME = "accountUsers";

export const GET_ACCOUNT_USERS = createActionTypes(`${NAME}/GET_ACCOUNT_USERS`);
export const CREATE_ACCOUNT_USER = createActionTypes(
  `${NAME}/CREATE_ACCOUNT_USER`
);
export const UPDATE_ACCOUNT_USER = createActionTypes(
  `${NAME}/UPDATE_ACCOUNT_USER`
);
export const FETCH_ACCOUNT_USER = createActionTypes(
  `${NAME}/FETCH_ACCOUNT_USER`
);
export const DELETE_ACCOUNT_USER = createActionTypes(
  `${NAME}/FETCH_ACCOUNT_USER`
);

export const INITIAL_STATE: IAccountUsersState = {
  accountUsers: [],
  accountUser: null,
  isFetching: false,
};

export const DEFAULT_USER: IUser = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  phoneNumber: "",
};

export const DEFAULT_ACCOUNT_USER = (accountId: string): IAccountUser => ({
  accountId,
  roleId: "",
  isActive: true,
  userType: AccountUserType.User,
  user: {
    ...DEFAULT_USER,
  },
  accountUserPermissions: [],
});

export const ACCOUNT_USER_TYPES = [
  { id: AccountUserType.User, label: "User" },
  { id: AccountUserType.Manager, label: "Manager" },
];
