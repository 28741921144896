import { CommonAction } from "common/common.interface";
import { Dispatch } from "redux";
import { del, get } from "utils/requests";
import {
  SET_NEW_NOTIFICATION,
  FETCH_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  GET_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from "./constants";
import { INotification } from "./interface";

export const setNewNotification = (
  notification: INotification
): CommonAction => ({
  type: SET_NEW_NOTIFICATION,
  payload: { notification },
});

export const fetchNotifications =
  (take = 10, skip = 0, approval = false) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FETCH_NOTIFICATIONS.REQUEST });
      const response = await get(`/notifications`, {
        skip,
        take,
      });
      dispatch({
        type: FETCH_NOTIFICATIONS.SUCCESS,
        payload: {
          data: response.data.results,
          total: response.data.total,
          approval,
        },
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };
export const deleteNotification =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: DELETE_NOTIFICATION.REQUEST });
      const response = await del(`/notifications/${id}`);
      if (id !== "all") {
        dispatch({
          type: DELETE_NOTIFICATION.SUCCESS,
          payload: {
            id,
          },
        });
      } else {
        dispatch({ type: CLEAR_NOTIFICATIONS });
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  };
export const getNotification = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_NOTIFICATION.REQUEST });
    const response = await get(`/notifications/${id}`);
    dispatch({
      type: GET_NOTIFICATION.SUCCESS,
      payload: {
        notification: response.data,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
