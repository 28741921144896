import { ChangeEvent, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import queryString from "query-string";
import { Box, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import Campaigns from "./Campaigns";
import { CampaignType } from "../interface";

interface LinkTabProps {
  label: string;
  to: string;
}

const useStyles = makeStyles(() => ({
  tabs: {
    alignItems: "center",
    background: "#FFFFFF",
    color: "#000000",
    height: 48,
  },
}));

const LinkTab = ({ to, ...props }: LinkTabProps): JSX.Element => {
  return (
    <Tab
      component={Link}
      {...props}
      onClick={(event: any) => {
        event.stopPropagation();
      }}
      to={to}
    />
  );
};

enum TabDef {
  OnDemand = 0,
  Conversation = 1,
}
const Landing = (): JSX.Element => {
  const buttonContainer = useRef(null);
  const parsedHash = queryString.parse(window.location.search);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { campaignType } = parsedHash || {};

  const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  useEffect(() => {
    const selected: CampaignType =
      campaignType === CampaignType.Conversation
        ? CampaignType.Conversation
        : CampaignType.OnDemand;
    setValue(
      {
        [CampaignType.OnDemand]: TabDef.OnDemand,
        [CampaignType.Conversation]: TabDef.Conversation,
      }[selected]
    );
  }, [campaignType]);
  const appBar = document.getElementById("app-bar");
  const renderContent = (): JSX.Element => {
    switch (value) {
      case TabDef.Conversation:
        return (
          <Campaigns
            buttonContainer={buttonContainer}
            campaignType={CampaignType.Conversation}
          />
        );
      default:
        return (
          <Campaigns
            buttonContainer={buttonContainer}
            campaignType={CampaignType.OnDemand}
          />
        );
    }
  };

  return (
    <Box
      sx={{ width: "100%", height: "calc(100vh - 160px)", marginTop: "48px" }}
    >
      {appBar &&
        createPortal(
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            centered
            aria-label="campaigns campaignType"
          >
            <LinkTab label="On-Demand" to="/campaigns?campaignType=on-demand" />
            <LinkTab
              label="Conversation"
              to="/campaigns?campaignType=conversation"
            />
            <div
              ref={buttonContainer}
              style={{ position: "absolute", right: "12px" }}
            />
          </Tabs>,
          appBar
        )}
      {renderContent()}
    </Box>
  );
};

export default Landing;
