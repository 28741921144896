import { Box, Paper, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, ReactNode, useState } from "react";
import WebhookRequests from "../../WebhookRequests";
import EchoWebhooks from "../../EchoWebhook";
import CampaignRequests from "../../CampaignRequests";

import List from "./List";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100vh - 115px)",
    display: "flex",
    flexDirection: "column",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    flex: "1 1 0",
  },
}));

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
}
const TabPanel = (props: TabPanelProps): JSX.Element | null => {
  const { children, value, index } = props;
  const classes = useStyles();

  return value === index ? (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tab-container-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.boxContainer}
    >
      {children}
    </Box>
  ) : null;
};

const a11yProps = (index: number): any => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `tab-container-${index}`,
  };
};

export const Logs = (): JSX.Element => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
    console.log({ newValue });
    setValue(newValue);
  };
  return (
    <Paper className={classes.root}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Portal Logs" {...a11yProps(0)} />
          <Tab label="Inbound API Logs " {...a11yProps(1)} />
          <Tab label="Outbound API Logs" {...a11yProps(2)} />
          <Tab label="Inbound SMS" {...a11yProps(2)} />
          <Tab label="Outbound SMS" {...a11yProps(2)} />
          <Tab label="Webhook LOGS" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <List />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CampaignRequests logType="inbounds" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <WebhookRequests />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <EchoWebhooks />
      </TabPanel>
    </Paper>
  );
};

export default Logs;
