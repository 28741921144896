import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { persistReducer } from "redux-persist";
import { History } from "history";
import storage from "redux-persist/lib/storage";

import * as phone from "modules/Phone";
import * as session from "modules/Auth";
import * as user from "modules/User";
import * as campaigns from "modules/Campaigns";
import * as campaignRequests from "modules/CampaignRequests";
import * as main from "modules/Main";
import * as roles from "modules/Roles";
import * as accountUsers from "modules/AccountUsers";
import * as contacts from "modules/Contacts";
import * as files from "modules/Files";
import * as permissions from "modules/Permissions";
import * as rolePermissions from "modules/RolePermissions";
import * as appSettings from "modules/AppSettings";
import * as accounts from "modules/Accounts";
import * as logs from "modules/Logs";
import * as blacklisted from "modules/BlackListed";
import * as outbounds from "modules/WebhookRequests";
import * as managerBasedPermissions from "modules/ManagerBasedPermissions";
import * as notification from "modules/Notification";
import * as echowebhooks from "modules/EchoWebhook";

const persistConfig = {
  key: "root",
  storage,
};

const persistorReducer = persistReducer(persistConfig, session.reducer);

const createRootReducer = (history: History): any =>
  combineReducers({
    router: connectRouter(history),
    // rest of your reducers
    session: persistorReducer,
    [user.NAME]: user.reducer,
    [main.NAME]: main.reducer,
    [campaigns.NAME]: campaigns.reducer,
    [campaignRequests.NAME]: campaignRequests.reducer,
    [roles.NAME]: roles.reducer,
    [accountUsers.NAME]: accountUsers.reducer,
    [contacts.NAME]: contacts.reducer,
    [files.NAME]: files.reducer,
    [permissions.NAME]: permissions.reducer,
    [rolePermissions.NAME]: rolePermissions.reducer,
    [appSettings.NAME]: appSettings.reducer,
    [accounts.NAME]: accounts.reducer,
    [logs.NAME]: logs.reducer,
    [blacklisted.NAME]: blacklisted.reducer,
    [outbounds.NAME]: outbounds.reducer,
    [managerBasedPermissions.NAME]: managerBasedPermissions.reducer,
    [phone.NAME]: phone.reducer,
    [notification.NAME]: notification.reducer,
    [echowebhooks.NAME]: echowebhooks.reducer,
  });

export default createRootReducer;

export type RootState = ReturnType<typeof createRootReducer>;
