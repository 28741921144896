import { Dispatch } from "redux";
import { FETCH_LIST } from "./constants";
import { get } from "../../utils/requests";

export const getManagerPermissions =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: FETCH_LIST.REQUEST,
    });

    const response = await get(`/manager-permissions`);
    try {
      dispatch({
        type: FETCH_LIST.SUCCESS,
        permissions: response.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_LIST.FAILED,
        errors: err,
      });
    }
  };
