import { CampaignStatus, CampaignType, ICampaign } from "./interface";

export const isValidOptions = (campaign: ICampaign | null): boolean => {
  return campaign?.campaignType === CampaignType.OnDemand
    ? !!campaign?.name &&
        !!campaign?.phoneGroupId &&
        !!campaign?.message &&
        // !!campaign?.executions?.[0]?.schedule &&
        (campaign?.executions?.[0]?.contacts?.length || 0) > 0 &&
        !!campaign?.startDate &&
        !!campaign?.endDate
    : !!campaign?.name && !!campaign?.phoneGroupId && !!campaign?.templateId;
};

export const isValidReplyOptions = (campaign: ICampaign | null): boolean => {
  return (
    !!campaign?.replies?.length &&
    !!campaign?.replies?.every(
      (reply) => !!reply.replyMessage && reply.replyKeywords.length > 0
    )
  );
};

export const isEditable = (status?: CampaignStatus): boolean =>
  !status ||
  [
    CampaignStatus.Draft,
    CampaignStatus.Paused,
    CampaignStatus.Rejected,
  ].includes(status);
