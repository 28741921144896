/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Info as IconInfo } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "reducers/reducer.interface";
import { setSimpleValue } from "modules/Main/actions";
import { makeStyles } from "@mui/styles";
import TableRowNoRows from "components/NoRows/TableRowNoRows";
import { createPortal } from "react-dom";
import { push } from "connected-react-router";
// import { formatDate } from "utils/utilities";
import { Role } from "modules/Permissions/interface";
// import { CampaignStatus, ICampaign } from "modules/Campaigns/interface";
import SortingFields from "components/SortingFields";
import { getPhoneGroups, updatePhoneGroup } from "../actions";
import {
  IPhoneGroup,
  IPhoneGroupKey,
  PhoneGroupStatus,
  UseCase,
} from "../interface";
import TablePaginationActions from "./TablePaginationActions";
import PhoneGroupAction from "./PhoneGroupAction";
import { STEPS, Steps } from "../constants";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const PhoneGroupListing = (): JSX.Element => {
  const statuses: any = Object.values(PhoneGroupStatus);
  const [phoneGroupStatuses] = useState<PhoneGroupStatus[]>(statuses);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [sortBy, setSortBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { selectedAccount, phoneGroups, accountId, total, profile } =
    useSelector((state: IRootReducer) => ({
      profile: state.user.profile,
      accountId: state.session.selectedAccount?.id || "all",
      selectedAccount: state.session.selectedAccount,
      phoneGroups: state.phone.phoneGroups,
      total: state.phone.total,
    }));

  const sortingBy = (fields: string): void => {
    if (fields === "action") return;
    const containsDirections = fields.includes("-");
    const sortByField = containsDirections ? fields.split("-")[1] : fields;
    if (sortByField === sortBy) {
      if (containsDirections) {
        setSortBy(fields.replace("-", ""));
      } else {
        setSortBy(`-${fields}`);
      }
    } else {
      setSortBy(fields);
    }
  };

  const fetchPhoneGroups = async (newPage: number): Promise<void> => {
    try {
      const skip = newPage * rowsPerPage;
      await dispatch(
        getPhoneGroups(
          selectedAccount?.id,
          skip,
          rowsPerPage,
          phoneGroupStatuses,
          sortBy
        )
      );
    } catch (err) {
      dispatch(
        setSimpleValue("snackbar", {
          message: "No result found",
          type: "error",
        })
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    fetchPhoneGroups(0);
  }, [rowsPerPage]);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    fetchPhoneGroups(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    fetchPhoneGroups(page);
  }, [sortBy]);

  useEffect(() => {
    dispatch(setSimpleValue("title", "Phone Numbers"));
    fetchPhoneGroups(page);
    return () => {
      dispatch(setSimpleValue("title", null));
    };
  }, []);

  useEffect(() => {
    fetchPhoneGroups(0);
  }, [selectedAccount]);
  const isAdmin = profile?.userType === Role.Admin;
  const columns = [
    { id: "name", label: "Name" },
    {
      id: "account",
      label: "Account Name",
      align: "left",
      width: 300,
      hide: !isAdmin,
    },
    { id: "phone", label: "Phone Numbers" },
    { id: "status", label: "Status" },
    { id: "campaigns", label: "Assigned Campaign" },
    // { id: "createdAt", label: "Date Created" },
    // { id: "creator", label: "Created By" },
    // { id: "updatedAt", label: "Date Updated" },
    // { id: "updator", label: "Updated By" },
    { id: "action", label: "Action" },
  ];
  const handleAddPhoneGroup = async (): Promise<void> => {
    dispatch(push(`/phone/groups/new`));
  };
  const handleSave = (phoneGroup: IPhoneGroup) => async (): Promise<void> => {
    try {
      if (phoneGroup) {
        if (!phoneGroup.description) {
          phoneGroup.description = UseCase.NotifyUsers;
        }
        if (phoneGroup?.id) {
          await dispatch(
            updatePhoneGroup(accountId, phoneGroup.id, phoneGroup)
          );
        }
        dispatch(
          setSimpleValue("snackbar", {
            message: "Phone group has been saved",
            type: "success",
          })
        );
        fetchPhoneGroups(0);
      }
    } catch (err: any) {
      dispatch(setSimpleValue("snackbar", { message: err?.message }));
    }
  };
  const container = document.getElementById("button-container");
  return (
    <Paper className={classes.root}>
      {selectedAccount?.id &&
        container &&
        createPortal(
          <Button
            style={{ margin: 8 }}
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleAddPhoneGroup}
          >
            Add Phone Group
          </Button>,
          container
        )}
      <TableContainer>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns
                .filter((o) => !o.hide)
                .map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ borderBottom: "0" }}
                    >
                      <SortingFields
                        exclude={["action"]}
                        label={column.label}
                        currentSortField={sortBy}
                        field={column.id}
                        onClick={() => sortingBy(column.id)}
                      />
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {phoneGroups.length ? (
              phoneGroups.map((phone) => (
                <TableRow key={phone.id}>
                  {columns
                    .filter((o) => !o.hide)
                    .map((column: any) => {
                      const assignedCampaign = phone?.campaigns;
                      const key: keyof IPhoneGroup | "phone" | "action" =
                        column.id;
                      let value: any =
                        key !== "action" && key !== "phone" && phone[key];

                      // if (["updatedAt", "createdAt"].includes(key)) {
                      //   value = value ? formatDate(value).fromNow() : null;
                      // } else if (["updator", "creator"].includes(key)) {
                      //   const u =
                      //     key === "updator" ? phone.updator : phone.creator;
                      //   value = `${u?.firstName || ""} ${
                      //     u?.lastName || ""
                      //   }`.trim();
                      if (key === "campaigns") {
                        if (assignedCampaign?.length) {
                          const campaign = assignedCampaign[0];

                          value = (
                            <Link
                              component={RouterLink}
                              to={`/campaigns/${campaign.id}`}
                              underline="none"
                              variant="subtitle1"
                              style={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              {campaign.name}
                            </Link>
                          );
                        } else {
                          value = "--";
                        }
                        // if (["updatedAt", "createdAt"].includes(key)) {
                        //   value = value ? formatDate(value).fromNow() : null;
                      } else if (key === "status") {
                        const { status }: { status?: PhoneGroupStatus } = phone;
                        value =
                          status &&
                          {
                            [PhoneGroupStatus.Draft]: "Draft",
                            [PhoneGroupStatus.Approved]: "Available",
                            [PhoneGroupStatus.AwaitingConfirmation]:
                              "Awaiting Confirmation",
                            [PhoneGroupStatus.OnHold]: "On Hold",
                            [PhoneGroupStatus.PendingApproval]:
                              "Awaiting CCDS Approval",
                            [PhoneGroupStatus.Disabled]: "Disabled",
                            [PhoneGroupStatus.InUse]: "Assigned",
                            [PhoneGroupStatus.Free]: "Free",
                          }[status];

                        return (
                          <TableCell align="center" key={`cell-${phone.id}`}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {value}
                              {phone.reason &&
                                status &&
                                [
                                  PhoneGroupStatus.Draft,
                                  PhoneGroupStatus.OnHold,
                                ].includes(status) && (
                                  <Tooltip
                                    title={`Rejection Reason: ${phone.reason}`}
                                  >
                                    <IconInfo
                                      color="warning"
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                )}
                            </div>
                          </TableCell>
                        );
                      } else if (key === "action") {
                        const complianceKeys: IPhoneGroupKey[] = [
                          "useCase",
                          "useCaseDescription",
                          "message1",
                          "message2",
                          "content",
                        ];
                        const validCompliance = (): boolean =>
                          !phone?.isUs ||
                          (!!phone?.isUs &&
                            complianceKeys.every((k: IPhoneGroupKey) => {
                              return phone?.[k];
                            }));
                        const isComplete = (index: Steps): boolean => {
                          switch (index) {
                            case Steps.SProperties:
                              return !!phone?.description && !!phone?.name;
                            case Steps.SPhoneNumber:
                              return !!phone?.phoneNumbersCount;
                            default:
                              return validCompliance();
                          }
                        };
                        return (
                          <TableCell align="center">
                            <PhoneGroupAction
                              disabled
                              activatable={STEPS.every((step, index) =>
                                isComplete(index)
                              )}
                              phoneGroupFromList={phone}
                              onSave={handleSave(phone)}
                              onReload={() => fetchPhoneGroups(0)}
                            />
                          </TableCell>
                        );
                      } else if (key === "phone") {
                        return (
                          <TableCell align="center">
                            {phone.phoneNumbersCount || 0}
                          </TableCell>
                        );
                      }

                      if (column.id === "account") {
                        value = phone.account?.name;
                      }
                      return (
                        <TableCell align="center" key={`cell--${column.id}`}>
                          {key === "name" ? (
                            <Link
                              component={RouterLink}
                              to={`/phone/groups/${phone.id}`}
                              underline="none"
                              variant="subtitle1"
                              style={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              {value}
                            </Link>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))
            ) : (
              <TableRowNoRows colSpan={7} />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                // rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PhoneGroupListing;
