import { Dispatch } from "redux";
import {
  CREATE_ROLE,
  DELETE_ROLE,
  FETCH_ROLE,
  GET_ROLES,
  SET_ROLE,
  UPDATE_ROLE,
  FETCH_ROLE_PERMISSION,
  UPDATE_PERMISSION,
  CREATE_PERMISSION,
} from "./constants";
import { del, get, patch, post } from "../../utils/requests";
import { IRole, IRoleAction } from "./interface";

export const setRole = (role: IRole | []) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ROLE,
    payload: { role },
  });
};

export const createRole =
  (accountId: string | undefined, role: any) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: CREATE_ROLE.REQUEST,
    });

    try {
      const response = await post(`/accounts/${accountId}/roles`, role);
      dispatch({
        type: CREATE_ROLE.SUCCESS,
        payload: {
          role: response.data,
        },
      });

      return response;
    } catch (err) {
      dispatch({
        type: CREATE_ROLE.FAILED,
      });
      throw err;
    }
  };

export const fetchRole =
  (accountId: any, roleId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: FETCH_ROLE.REQUEST });

    try {
      const response = await get(`/accounts/${accountId}/roles/${roleId}`);
      dispatch({
        type: FETCH_ROLE.SUCCESS,
        payload: {
          role: response.data,
        },
      });
    } catch (err) {
      dispatch({
        type: FETCH_ROLE.FAILED,
        errors: err,
      });
      throw err;
    }
  };

export const fetchRolePermissions =
  (accountId: any, roleId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: FETCH_ROLE_PERMISSION.REQUEST });

    try {
      const response = await get(
        `/accounts/${accountId}/roles/${roleId}/permissions`
      );

      dispatch({
        type: FETCH_ROLE_PERMISSION.SUCCESS,
        payload: {
          role: response.data,
        },
      });
    } catch (err) {
      dispatch({
        type: FETCH_ROLE_PERMISSION.FAILED,
        errors: err,
      });
    }
  };

export const updateRolePermission = (
  id: string,
  value: boolean
): IRoleAction => ({
  type: UPDATE_PERMISSION,
  payload: {
    id,
    value,
  },
});

export const createRolePermission = (
  permissionId: string,
  value: boolean
): IRoleAction => ({
  type: CREATE_PERMISSION,
  payload: {
    permissionId,
    value,
  },
});

export const getRoles =
  (accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET_ROLES.REQUEST,
    });

    const response = await get(`/accounts/${accountId}/roles`);
    try {
      dispatch({
        type: GET_ROLES.SUCCESS,
        payload: { roles: response.data },
      });
    } catch (err) {
      dispatch({
        type: GET_ROLES.FAILED,
        errors: err,
      });
    }
  };

export const deleteRole =
  (accountId: string, roleId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: DELETE_ROLE.REQUEST,
    });

    try {
      await del(`/accounts/${accountId}/roles/${roleId}`);

      dispatch({
        type: DELETE_ROLE.SUCCESS,
        payload: { roleId },
      });
    } catch (err) {
      dispatch({
        type: DELETE_ROLE.FAILED,
        errors: err,
      });
    }
  };

export const updateRole =
  (accountId: any, roleId: any, payload: any) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: UPDATE_ROLE.REQUEST,
    });

    try {
      const response = await patch(
        `/accounts/${accountId}/roles/${roleId}`,
        payload
      );

      dispatch({
        type: UPDATE_ROLE.SUCCESS,
        payload: {
          role: response.data,
        },
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: UPDATE_ROLE.FAILED,
        errors: err,
      });
      throw err;
    }
  };
