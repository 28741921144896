import { Dispatch } from "redux";
import { NEW_ROLE_PERMISSIONS } from "./constants";
import { post } from "../../utils/requests";
import { IRolesPermission } from "./interface";

export const updatePermission =
  (accountId: any, roleId: any, payload?: IRolesPermission[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: NEW_ROLE_PERMISSIONS.REQUEST,
      });
      const response = await post(
        `/accounts/${accountId}/roles/${roleId}/permissions`,
        payload
      );
      dispatch({
        type: NEW_ROLE_PERMISSIONS.SUCCESS,
        rolePermissions: response.data,
      });
    } catch (err) {
      dispatch({
        type: NEW_ROLE_PERMISSIONS.FAILED,
        errors: err,
      });
    }
  };
