import { AccountSettings, IAccountSetting } from "../AccountSettings/interface";
import { GENERATE_API_KEYS, SAVE_SETTING } from "../AccountSettings/constants";
import { IAccountAction, IAccountState } from "./interface";
import {
  LOADING_ACCOUNT,
  GET_ACCOUNTS,
  INITIAL_STATE,
  DEFAULT_ACCOUNT,
} from "./constants";

const replaceApiKeys = (
  state: IAccountState,
  newAccountSettings: IAccountSetting[]
): IAccountState => {
  const account = { ...DEFAULT_ACCOUNT, ...state.account };
  const accountSettings = [...(account?.accountSettings || [])];
  newAccountSettings.forEach((accountSetting) => {
    const index = accountSettings?.findIndex(
      (o) => o.name === accountSetting.name
    );
    if (index >= 0) {
      accountSettings[index] = accountSetting;
    } else {
      accountSettings.push(accountSetting);
    }
  });

  return {
    ...state,
    account: {
      ...account,
      accountSettings: [...accountSettings],
    },
  };
};

export const reducer = (
  state: IAccountState = INITIAL_STATE,
  action: IAccountAction
): IAccountState => {
  switch (action.type) {
    case GET_ACCOUNTS.SUCCESS:
      return {
        ...state,
        data: action.payload.data.results,
        total: action.payload.data.total,
      };
    case LOADING_ACCOUNT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOADING_ACCOUNT.FAILED:
      return {
        ...state,
        loading: false,
      };
    case LOADING_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: action.payload.account,
        loading: false,
      };
    case GENERATE_API_KEYS.SUCCESS:
      return replaceApiKeys(state, action.payload.accountSettings);
    case SAVE_SETTING.SUCCESS:
      return replaceApiKeys(state, [action.payload.accountSetting]);
    default:
      return state;
  }
};
