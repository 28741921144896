import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/requests";
import {
  blacklistedFetched,
  blacklistedFetchedWithError,
  startLoading,
} from "./reducer";

export const fetchBlackListed = createAsyncThunk(
  "reports/fetchBlackListed",
  async ({ accountId = "", take = 10, skip = 0 }: any, thunkApi) => {
    thunkApi.dispatch(startLoading());
    try {
      const response = await get(`/accounts/${accountId}/blacklisted`, {
        take,
        skip,
      });
      const { data } = response;

      if (data) {
        thunkApi.dispatch(blacklistedFetched(data));
      } else {
        throw new Error("Failed to load blacklisted.");
      }
    } catch (error) {
      thunkApi.dispatch(blacklistedFetchedWithError(error));
    }
  }
);
