import { CommonAction, Payload } from "common/common.interface";
import { uniqBy } from "lodash";
import {
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  FETCH_NOTIFICATIONS,
  GET_NOTIFICATION,
  INITIAL_STATE,
  SET_NEW_NOTIFICATION,
} from "./constants";
import { INotificationsState } from "./interface";

const addNewNotification = (
  state: INotificationsState,
  payload: Payload
): INotificationsState => {
  const isDeleted = payload.notification.isRead;
  const notifications = isDeleted
    ? [...state.notifications]
    : [...state.notifications, payload.notification];
  if (isDeleted) {
    const index = notifications.find((o) => o.id === payload.notification.id);
    notifications.splice(index, 1);
  }
  return { ...state, notifications, total: state.total + (isDeleted ? -1 : 1) };
};

const deleteNotification = (
  state: INotificationsState,
  id: string
): INotificationsState => {
  const notifications = [...state.notifications];
  const index = notifications.findIndex((o) => o.id === id);
  if (index >= 0) {
    notifications.splice(index, 1);
  }
  return { ...state, notifications, total: state.total - 1 };
};

export const reducer = (
  state = INITIAL_STATE,
  action: CommonAction
): INotificationsState => {
  switch (action.type) {
    case GET_NOTIFICATION.SUCCESS:
      return { ...state, notification: action.payload.notification };
    case DELETE_NOTIFICATION.SUCCESS:
      return deleteNotification(state, action.payload.id);
    case SET_NEW_NOTIFICATION:
      return addNewNotification(state, action.payload);
    case FETCH_NOTIFICATIONS.REQUEST:
      return { ...state, fetching: true };
    case FETCH_NOTIFICATIONS.FAILED:
      return { ...state, fetching: false };
    case FETCH_NOTIFICATIONS.SUCCESS:
      return action.payload.approval
        ? { ...state, approvals: action.payload.data }
        : {
            ...state,
            notifications: uniqBy(
              [...state.notifications, ...action.payload.data],
              "id"
            ),
            total: action.payload.total,
          };
    case CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [], total: 0 };
    default:
      return state;
  }
};
