import { useEffect, useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Paper,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { Edit, Delete, OpenInNew } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import { deleteAccount, getAccounts } from "../../actions";
import { Role } from "../../../Permissions/interface";
import { IRootReducer } from "../../../../reducers/reducer.interface";
import { setSelectedAccount } from "../../../Auth/actions";
import TableRowNoRows from "../../../../components/NoRows/TableRowNoRows";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "address",
    label: "Address",
    minWidth: 170,
  },
  { id: "phoneNumber", label: "Phone Number", minWidth: 100 },
  { id: "owner", label: "Account Owner", minWidth: 100 },
  {
    id: "actions",
    label: "",
    minWidth: 50,
    align: "center",
  },
];

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 115px)",
  },
  container: {
    flex: "1 1 0%",
  },
  title: {
    position: "relative",
    padding: "16px",
    flex: "1 1 0%",
  },
  new: {
    float: "right",
  },
}));
const Accounts = (): JSX.Element => {
  const [dialog, setDialog] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { profile, accounts } = useSelector((state: IRootReducer) => ({
    profile: state.user.profile,
    accounts: state.account.data,
  }));

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectAccount = (account: any): void => {
    dispatch(setSelectedAccount(account));
  };
  const handleEdit = (account: any): void => {
    dispatch(push(`/accounts/${account.id}`));
  };
  const handleDelete = (account: any): void => {
    setDialog({
      confirmTitle: "Delete Account",
      confirmMessage: "Are you sure you want to delete this account?",
      onConfirm: async () => {
        await dispatch(deleteAccount(account.id));
        setDialog({});
        dispatch(getAccounts());
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
    });
  };

  const isAdmin = profile?.userType === Role.Admin;
  const data = isAdmin
    ? accounts
    : profile?.userAccounts?.map(({ account }) => account) || [];
  console.log(profile);
  const getCell = (columnsData: Column[], account: any): JSX.Element[] => {
    return columnsData.map((column: any) => {
      let value = account[column.id];
      if (column.id === "owner") {
        value = `${value?.firstName} ${value?.lastName}`;
      }
      return column.id === "actions" ? (
        <TableCell key={column.id} align={column.align}>
          <Tooltip title="Edit Account" placement="top">
            <IconButton aria-label="edit" onClick={() => handleEdit(account)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Switch Account" placement="top">
            <IconButton
              aria-label="select"
              onClick={() => handleSelectAccount(account)}
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Account" placement="top">
            <IconButton
              aria-label="delete"
              onClick={() => handleDelete(account)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell key={column.id} align={column.align}>
          {value}
        </TableCell>
      );
    });
  };

  useEffect(() => {
    if (isAdmin) {
      dispatch(getAccounts());
    }
  }, []);

  return (
    <Paper elevation={1} className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <span>Accounts</span>
        </Typography>

        <Button
          component={Link}
          to="/accounts/new"
          variant="contained"
          color="primary"
          className={classes.new}
        >
          New Account
        </Button>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map((account) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={account?.id}
                  >
                    {getCell(columns, account)}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows />
            )}
            {/* {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmDialog {...dialog} />
    </Paper>
  );
};

export default Accounts;
