import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Link,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Info as IconInfo } from "@mui/icons-material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { makeStyles } from "@mui/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { push } from "connected-react-router";
import { createPortal } from "react-dom";
import numeral from "numeral";
import { formatDate } from "utils/utilities";
import { Role } from "modules/Permissions/interface";
import { setSimpleValue } from "modules/Main/actions";
import { HtmlTooltip } from "components/Tooltip";
import { Column } from "../../../common/common.interface";
// import { useWindowSize } from "../../../utils/useWindowSize";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { fetchCampaigns, updateCampaign } from "../actions";

import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";

import {
  CampaignStatus,
  CampaignType,
  CAMPAIGN_STATUS_LABEL,
  ICampaign,
} from "../interface";
import CampaignAction from "./CampaignActions";
import { isValidOptions, isValidReplyOptions } from "../selectors";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
  tableRow: { height: 52 },
  campaignName: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Campaigns = ({
  buttonContainer,
  campaignType,
}: {
  buttonContainer?: React.RefObject<HTMLDivElement>;
  campaignType: CampaignType;
}): JSX.Element => {
  const classes = useStyles();
  // const size = useWindowSize();
  // const params = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(0);
  const tableBody = useRef(null);
  // const pageSize = Math.floor(((size?.height || 0) - 398) / 52);
  const [pageSize, setPageSize] = useState(5);

  // const getPage = params.get("page") || "0";
  // const paramsPage = parseInt(getPage, 10) || 0;

  const {
    campaigns = [],
    selectedAccount,
    total,
    profile,
  } = useSelector(
    (state: IRootReducer) => ({
      profile: state.user.profile,
      campaigns: state.campaigns.data,
      total: state.campaigns.total,
      selectedAccount: state.session.selectedAccount,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const isAdmin = profile?.userType === Role.Admin;
  const columns: Column[] = [
    { id: "name", label: "Campaign Name", align: "left", width: 300 },
    {
      id: "account",
      label: "Account Name",
      align: "left",
      width: 300,
      hide: !isAdmin,
    },
    { id: "status", label: "Status", align: "left", width: 200 },
    { id: "phoneGroup", label: "Phone Group", align: "left", width: 200 },
    {
      id: "sentCountSuccess",
      label: "Sent Count Success",
      align: "center",
      width: 150,
    },
    {
      id: "lists",
      label: "Lists",
      align: "center",
      width: 150,
    },
    {
      id: "createDateTime",
      label: "Date Created",
      align: "right",
      width: 160,
      hideIf: CampaignType.OnDemand,
    },
    {
      id: "creator",
      label: "Created By",
      align: "left",
      width: 160,
      hideIf: CampaignType.OnDemand,
    },
    {
      id: "lastChangedDateTime",
      label: "Date Last Updated",
      align: "right",
      width: 160,
      hideIf: CampaignType.OnDemand,
    },
    {
      id: "updator",
      label: "Updated By",
      align: "left",
      width: 160,
      hideIf: CampaignType.OnDemand,
    },
    {
      id: "action",
      label: "Action",
      align: "right",
      width: 210,
    },
  ];

  const handleChangePage = (event: unknown, newPage: number): void => {
    if (!pageSize) return;

    setPage(newPage);
    const skip = newPage * pageSize;
    dispatch(
      fetchCampaigns({
        accountId: selectedAccount?.id,
        take: pageSize,
        skip,
        campaignType,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const value = parseInt(event?.target?.value, 10) || 1;
    setPageSize(value);
    dispatch(
      fetchCampaigns({
        accountId: selectedAccount?.id,
        take: value,
        skip: 0,
        campaignType,
      })
    );
  };

  const handleAddCampaign = (): void => {
    if (!selectedAccount?.id) {
      dispatch(
        setSimpleValue("snackbar", {
          message: "Please select account to create a campaign",
          type: "error",
        })
      );
      return;
    }
    dispatch(push(`/campaigns/new?campaignType=${campaignType}`));
  };

  useEffect(() => {
    // if (pageSize > 0) {
    dispatch(
      fetchCampaigns({
        accountId: selectedAccount?.id,
        skip: 0,
        take: pageSize,
        campaignType,
      })
    );
    // }
  }, [selectedAccount, campaignType]);

  const handleSave = (campaign: ICampaign) => async (): Promise<void> => {
    await dispatch(updateCampaign(campaign));
    // dispatch(push(`/campaigns?campaignType=${campaignType}`));
  };

  const getCell = (
    columnsData: Column[],
    campaign: ICampaign
  ): JSX.Element[] => {
    return columnsData.map((column: any) => {
      const key: keyof ICampaign = column.id;
      let value: any = campaign?.[key];

      if (["createDateTime", "lastChangedDateTime"].includes(column.id))
        value = formatDate(campaign.createDateTime).format(
          "MM/DD/YYYY HH:mm:ss"
        );

      if (column.id === "creator")
        value =
          campaign.creator &&
          `${campaign.creator?.firstName} ${campaign.creator?.lastName}`;
      if (column.id === "updator")
        value = campaign.updator
          ? `${campaign.updator?.firstName} ${campaign.updator?.lastName}`
          : "";
      const hasAction = [
        CampaignStatus.AwaitingApproval,
        CampaignStatus.Ready,
        CampaignStatus.Active,
        CampaignStatus.Paused,
        CampaignStatus.Ended,
        CampaignStatus.Draft,
        CampaignStatus.Sent,
      ].includes(campaign.status);
      if (column.id === "action" && hasAction) {
        const validOptions = isValidOptions(campaign);
        const validReplyOptions = isValidReplyOptions(campaign);
        value = (
          <CampaignAction
            disabled
            activatable={validOptions && validReplyOptions}
            campaignFromList={campaign}
            handleReload={() => {
              dispatch(
                push(`/campaigns?campaignType=${campaignType}&page=${page}`)
              );
              window.location.reload();
            }}
            onSave={handleSave(campaign)}
          />
        );
      }
      if (column.id === "phoneGroup") {
        value = campaign.phoneGroup?.name;
      }
      if (column.id === "sentCountSuccess") {
        value = numeral(value).format("0,0");
      }
      if (column.id === "account") {
        value = campaign.account?.name;
      }

      if (column.id === "lists") {
        const contactList =
          campaign.executions?.flatMap((execution) =>
            execution.contacts?.map((contact) => contact.list?.name)
          ) || [];

        if (contactList?.length > 1) {
          value = (
            <HtmlTooltip
              arrow
              title={
                <>
                  {contactList.map((list, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={index} color="inherit">
                      - {list}
                    </Typography>
                  ))}
                </>
              }
            >
              <ListAltIcon />
            </HtmlTooltip>
          );
        } else {
          value = contactList?.join(", ");
        }
      }

      return column.id === "status" ? (
        <TableCell key={column.id} align={column.align}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {CAMPAIGN_STATUS_LABEL[campaign.status]}
            {CampaignStatus.Rejected === value && (
              <Tooltip title={`Rejection Reason: ${campaign.reason}`}>
                <IconInfo color="warning" fontSize="small" />
              </Tooltip>
            )}
          </div>
        </TableCell>
      ) : (
        <TableCell key={column.id} align={column.align}>
          {column.id === "name" ? (
            <div className={classes.campaignName}>
              <Link
                component={RouterLink}
                to={`/campaigns/${campaign.id}`}
                underline="none"
                variant="subtitle1"
                style={{ fontWeight: "bold", fontSize: "14px" }}
              >
                {value}
              </Link>
              {campaign.startDate && (
                <>
                  <Typography variant="caption">
                    Start:{" "}
                    {formatDate(campaign.startDate).format(
                      "MM/DD/YYYY hh:mm A"
                    )}{" "}
                  </Typography>
                  <Typography variant="caption">
                    End:{" "}
                    {formatDate(campaign.endDate).format("MM/DD/YYYY hh:mm A")}
                  </Typography>
                  {campaign.smsPlanId && (
                    <Typography variant="caption">
                      SMS Plan Id: {campaign.smsPlanId}
                    </Typography>
                  )}
                </>
              )}
            </div>
          ) : (
            value
          )}
        </TableCell>
      );
    });
  };

  return (
    <Paper className={classes.root}>
      {buttonContainer?.current &&
        createPortal(
          <Button
            style={{ margin: 8 }}
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleAddCampaign}
          >
            Create Campaign
          </Button>,
          buttonContainer.current
        )}
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns
                .filter((x) => x?.hideIf !== campaignType && !x.hide)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align || "left"}
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody ref={tableBody}>
            {campaigns.length ? (
              campaigns.map((campaign) => {
                return (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={campaign.id}
                  >
                    {getCell(
                      columns.filter(
                        (x) => x?.hideIf !== campaignType && !x.hide
                      ),
                      campaign
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows
                colSpan={campaignType === CampaignType.OnDemand ? 8 : 10}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        // rowsPerPageOptions={[{ value: pageSize, label: "Page Size" }]}
        rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]}
        component="div"
        count={total}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Campaigns;
