import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/requests";
import {
  fetchedWebhooksLogs,
  startLoading,
  fetchedWebhooksError,
} from "./reducer";

export const fetchEchoWebhooks = createAsyncThunk(
  "echowebhooks/get",
  async ({ take = 10, skip = 0 }: any, thunkApi) => {
    thunkApi.dispatch(startLoading());
    try {
      const response = await get(`/sendreceive/logs`, {
        take,
        skip,
      });
      const { data } = response;

      if (data) {
        thunkApi.dispatch(fetchedWebhooksLogs(data));
      } else {
        throw new Error("Failed to load outbounds.");
      }
    } catch (error) {
      thunkApi.dispatch(fetchedWebhooksError(error));
    }
  }
);
