import { IAccount } from "modules/Accounts/interface";
import { IUser } from "modules/User/interface";

export enum NotificationType {
  PhoneCreation = "phone_creation",
  PhoneGroup = "phone_group_creation",
  CampaignCreation = "campaign_creation",
  ApprovePhoneCreation = "approved_phone",
  ApprovePhoneGroup = "approve_phone_group",
  ApprovedPhoneGroup = "approved_phone_group",
  ApproveCampaign = "approved_campaign",
  RejectPhoneCreation = "reject_phone",
  RejectPhoneGroup = "reject_phone_group",
  RejectCampaign = "reject_campaign",
}
export interface INotification {
  id?: string;
  createdAt?: Date;
  userId: string;
  mentionedId?: string;
  recordId?: string;
  message: string;
  type: NotificationType;
  isRead: boolean;
  accountId?: string;
  account?: IAccount;
  mentioned?: IUser;
  hasAction?: boolean;
  module?: string;
}

export interface INotificationsState {
  notifications: INotification[];
  approvals: INotification[];
  notification?: INotification;
  total: number;
  fetching: boolean;
}
