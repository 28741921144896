import { createSlice } from "@reduxjs/toolkit";
import { NAME } from "./constants";
import { IBlacklistedState } from "./interface";

const initialState: IBlacklistedState = {
  blacklisted: [],
  total: 0,
  loading: false,
  error: false,
};

export const blacklistedSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    startLoading: (state: IBlacklistedState) => {
      state.loading = true;
    },
    blacklistedFetched: (state: IBlacklistedState, action) => {
      state.loading = false;
      state.blacklisted = action.payload.results;
      state.total = action.payload.total;
    },
    blacklistedFetchedWithError: (state: IBlacklistedState, action) => {
      state.loading = false;
      state.blacklisted = [];
      state.error = action.payload;
    },
  },
});

// Actions
export const { startLoading, blacklistedFetched, blacklistedFetchedWithError } =
  blacklistedSlice.actions;

// Reducer
export const { reducer } = blacklistedSlice;
