import { createActionTypes } from "../../utils/utilities";
import { IAccount, IAccountState } from "./interface";

export const NAME = "account";
export const LOADING_ACCOUNT = createActionTypes(`${NAME}/LOADING_ACCOUNT`);
export const GET_ACCOUNTS = createActionTypes(`${NAME}/GET_ACCOUNTS`);

export const INITIAL_STATE: IAccountState = {
  data: [],
  account: null,
  loading: false,
  total: 0,
};

export const DEFAULT_ACCOUNT: IAccount = {
  name: "",
  website: "",
  address: "",
  phoneNumber: "",
};
