import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Typography,
  Autocomplete,
  Grid,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CustomInput from "components/CustomInput";
import { makeStyles } from "@mui/styles";
import CountryCodeList from "country-codes-list";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer } from "reducers/reducer.interface";
import { setSimpleValue } from "modules/Main/actions";
import { PhonePayload } from "../interface";
import { updateSearchPayload } from "../actions";

const useStyles = makeStyles(() => ({
  rootSelect: {
    padding: "8.5px 14px",
  },
}));

const PhoneListing = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedAccount, searchPayload } = useSelector(
    (state: IRootReducer) => ({
      selectedAccount: state.session.selectedAccount,
      searchPayload: state.phone.searchPayload,
    })
  );
  const [expanded, setExpanded] = useState(true);
  const [countryOptions, setCountryOptions]: any = useState([]);
  const [countryValue, setCountryValue]: any = useState({
    label: "(+1) Canada - CA",
    code: "CA",
  });

  const [numberType, setNumberType]: any = useState({
    local: true,
    mobile: true,
    tollFree: false,
  });

  const [matchCondition, setMatchCondition]: any = useState("first");

  const initialPayload = {
    smsEnabled: true,
    mmsEnabled: true,
    beta: true,
    addressRequirement: "any",
    numberType: [],
    countryCode: "CA",
    contains: "",
    maxResults: searchPayload.maxResults,
  };

  const [payload, setPayload] = useState<PhonePayload>(initialPayload);

  const [criteria, setCriteria] = useState("contains");

  const onChange =
    (type: string) =>
    ({ target: { checked, name, value } }: any): void => {
      switch (type) {
        case "capabilities":
        case "beta":
          setPayload({
            ...payload,
            [name]: checked,
          });
          break;

        case "numberType":
          setNumberType({
            ...numberType,
            [name]: checked,
          });
          break;

        case "addressRequirement":
        case "maxResults":
        case "contains":
          setPayload({
            ...payload,
            [type]: value,
          });
          break;

        case "matchCondition":
          setMatchCondition(value);
          break;
        default:
      }
    };

  const onCountryChange = (e: any, value: any): void => {
    setPayload({
      ...payload,
      countryCode: value?.code || "CA",
    });

    setCountryValue(value);
  };

  const handleChange = (type: string) => (event: SelectChangeEvent) => {
    setCriteria(event.target.value);
  };

  const searchAvailablePhone = (): void => {
    if (!selectedAccount) {
      dispatch(
        setSimpleValue("snackbar", {
          message: "Please select account to purchase a phone number",
          type: "error",
        })
      );
      return;
    }
    setExpanded(false);

    if (!payload?.maxResults || +payload?.maxResults <= 0) {
      payload.maxResults = 20;
      setPayload({
        ...payload,
        maxResults: 20,
      });
    }

    const newPayload: PhonePayload = { ...payload };
    const stringLength = 10;

    const nt: any = Object.keys(numberType)
      .map((key: string) => {
        return numberType[key] ? key : false;
      })
      .filter(Boolean);

    newPayload.numberType = nt;
    if (newPayload.addressRequirement === "any") {
      delete newPayload.addressRequirement;
    }

    if (newPayload.contains === "") {
      delete newPayload.contains;
    } else {
      if (matchCondition === "first") {
        const containsValue = newPayload?.contains || "";
        const l = stringLength - containsValue.length;
        const additionalString = l > 0 ? "*".repeat(l) : "";

        newPayload.contains = containsValue + additionalString;
      }
      if (matchCondition === "last") {
        const containsValue = newPayload?.contains || "";
        const l = stringLength - containsValue.length;
        const additionalString = l > 0 ? "*".repeat(l) : "";

        newPayload.contains = additionalString + containsValue;
      }
    }

    dispatch(updateSearchPayload(newPayload));
  };

  const resetFilter = (): void => {
    setCountryValue({
      label: "(+1) Canada - CA",
      code: "CA",
    });
    setNumberType({
      local: true,
      mobile: true,
      tollFree: true,
    });
    setMatchCondition("first");
    setPayload({
      smsEnabled: true,
      mmsEnabled: true,
      beta: false,
      addressRequirement: "any",
      numberType: [],
      countryCode: "CA",
      contains: "",
      maxResults: 20,
    });
    setCriteria("contains");
  };

  useEffect(() => {
    const dataEnum = CountryCodeList.customList(
      "countryCode",
      "(+{countryCallingCode}) {countryNameEn} - {countryCode}"
    );

    const opt = Object.keys(dataEnum).map((key: string) => {
      return {
        label: dataEnum[key],
        code: key,
      };
    });

    setCountryOptions(opt);
  }, []);

  return (
    <Box mb={2}>
      <Typography variant="h5" gutterBottom>
        <Box fontWeight={600} color="rgb(97, 97, 97)">
          Buy a Phone Number
        </Box>
      </Typography>
      <Box mb={4}>
        <FormControl component="fieldset" sx={{ marginRight: "20px" }}>
          <FormLabel component="legend">Country</FormLabel>
          <FormGroup aria-label="position" row>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              defaultValue={countryValue}
              value={countryValue}
              getOptionLabel={(option) => option.label}
              options={countryOptions}
              sx={{ width: 350 }}
              renderInput={(params) => <CustomInput {...params} size="small" />}
              onChange={onCountryChange}
            />
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset" sx={{ marginRight: "20px" }}>
          <FormLabel component="legend">Capabilities</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={payload.smsEnabled}
                  onChange={onChange("capabilities")}
                />
              }
              label="SMS"
              name="smsEnabled"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={payload.mmsEnabled}
                  onChange={onChange("capabilities")}
                />
              }
              label="MMS"
              name="mmsEnabled"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset" sx={{ marginRight: "20px" }}>
          <FormLabel component="legend">&nbsp;</FormLabel>
          <FormGroup aria-label="position" row>
            <Button variant="contained" onClick={searchAvailablePhone}>
              Search
            </Button>
            <Button variant="text" onClick={resetFilter}>
              Reset Filter
            </Button>
          </FormGroup>
        </FormControl>
      </Box>
      <Box mb={4}>
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Advance Search</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
              <Grid item md={2} xs={6}>
                <FormControl component="fieldset" sx={{ marginRight: "20px" }}>
                  <FormLabel component="legend">
                    Max # of Results to return
                  </FormLabel>
                  <FormGroup aria-label="position" row>
                    <CustomInput
                      variant="outlined"
                      size="small"
                      value={payload.maxResults}
                      onChange={onChange("maxResults")}
                      type="text"
                      placeholder="Max # of Results to return"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={10} xs={6}>
                <Box mb={2}>
                  <FormControl
                    component="fieldset"
                    sx={{ marginRight: "20px" }}
                  >
                    <FormLabel component="legend">Search criteria</FormLabel>
                    <FormGroup aria-label="position" row>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={criteria}
                        classes={{ select: classes.rootSelect }}
                        onChange={handleChange("criteria")}
                      >
                        <MenuItem value="contains">Number</MenuItem>
                      </Select>
                    </FormGroup>
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    sx={{ marginRight: "20px" }}
                  >
                    <FormLabel component="legend">&nbsp;</FormLabel>
                    <FormGroup aria-label="position" row>
                      <CustomInput
                        variant="outlined"
                        size="small"
                        value={payload.contains}
                        onChange={onChange("contains")}
                        type="text"
                        placeholder="Search by digits or phrases"
                      />
                    </FormGroup>
                  </FormControl>
                  {criteria === "contains" && (
                    <FormControl
                      component="fieldset"
                      sx={{ marginRight: "20px" }}
                    >
                      <FormLabel component="legend">Match to</FormLabel>
                      <FormGroup aria-label="position" row>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={matchCondition}
                          classes={{ select: classes.rootSelect }}
                          onChange={onChange("matchCondition")}
                        >
                          <MenuItem value="first">
                            First part of number
                          </MenuItem>
                          <MenuItem value="anyware">Anyware in number</MenuItem>
                          <MenuItem value="last">Last part of number</MenuItem>
                        </Select>
                      </FormGroup>
                    </FormControl>
                  )}

                  <FormControl
                    component="fieldset"
                    sx={{ marginRight: "20px" }}
                  >
                    <FormLabel component="legend">Number Type</FormLabel>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={numberType.local}
                            onChange={onChange("numberType")}
                          />
                        }
                        label="Local"
                        name="local"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={numberType.mobile}
                            onChange={onChange("numberType")}
                          />
                        }
                        label="Mobile"
                        name="mobile"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={numberType.tollFree}
                            onChange={onChange("numberType")}
                          />
                        }
                        label="Toll-free"
                        name="tollFree"
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>

                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ marginTop: "20px" }}
                  >
                    Search by area code, prefix, or characters you want in your
                    phone number.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                <FormControl component="fieldset" sx={{ marginRight: "20px" }}>
                  <FormLabel component="legend">Address requirements</FormLabel>
                  <FormGroup aria-label="position" row>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue="any"
                      value={payload?.addressRequirement || "any"}
                      classes={{ select: classes.rootSelect }}
                      onChange={onChange("addressRequirement")}
                    >
                      <MenuItem value="any">Any</MenuItem>
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="excludeLocalAddressRequired">
                        Exclude local requirements
                      </MenuItem>
                      <MenuItem value="excludeForeignAddressRequired">
                        Exclude foreign requirements
                      </MenuItem>
                    </Select>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={10} xs={12}>
                <FormControl component="fieldset" sx={{ marginRight: "20px" }}>
                  <FormLabel component="legend">Beta Numbers</FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<Checkbox onChange={onChange("beta")} />}
                      label="Exclude beta phone numbers in search results."
                      name="beta"
                      checked={payload.beta}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{ marginRight: "20px" }}>
                  <FormLabel component="legend">Emergency Calling</FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Checkbox />}
                      label="Only include phone numbers capable of emergency calling."
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default PhoneListing;
