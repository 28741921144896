/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  Tooltip,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  FormControlLabel,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Backdrop,
} from "@mui/material";
import { ArrowDropDown, Info as IconInfo } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import { useEffect, useState, ChangeEvent, MouseEvent, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { IRootReducer } from "reducers/reducer.interface";
import {
  faCommentDots,
  faFax,
  faPhoneAlt,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setSimpleValue } from "modules/Main/actions";
import { makeStyles } from "@mui/styles";
import { orderBy } from "lodash";
import TableRowNoRows from "components/NoRows/TableRowNoRows";
import ReactDOM from "react-dom";
import { push } from "connected-react-router";

import { Role } from "modules/Permissions/interface";
import axios from "axios";
import AuthenticateDialog from "components/AuthenticateDialog/AuthenticateDialog";
import { useElementSize } from "usehooks-ts";
import { AccountUserType } from "modules/AccountUsers/interface";
import SortingFields from "components/SortingFields";
import TablePaginationActions from "./TablePaginationActions";
import {
  getPhoneNumbers,
  buyPhoneNumber,
  addPhoneGroupPhoneNumber,
  setPhoneGroup,
  massBuyPhoneNumber,
  update,
  remove,
} from "../actions";
import { IPhoneNumber, PhoneNumberStatus, UseCase } from "../interface";
import PhoneActions from "./PhoneActions";
import { RESET_STATE } from "../constants";

interface Props {
  // eslint-disable-next-line react/require-default-props
  type?: "purchased" | undefined;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const PhoneListing = ({ type }: Props): JSX.Element => {
  const [dialog, setDialog] = useState({});
  const cancelToken = useRef(axios.CancelToken.source());
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPhones, setSelectedPhones] = useState<IPhoneNumber[]>([]);
  const isSearchListing = type !== "purchased";
  const [anchorRef, { width }] = useElementSize();
  const buttonGroupRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [showBlocker, setShowBlocker] = useState(false);
  const [sortBy, setSortBy] = useState("");

  const { selectedAccount, data, searchPayload, phoneGroup, total, profile } =
    useSelector(
      (state: IRootReducer) => ({
        profile: state.user.profile,
        selectedAccount: state.session.selectedAccount,
        data: state.phone.data,
        searchPayload: state.phone.searchPayload,
        phoneGroup: state.phone.phoneGroup,
        total: state.phone.total,
      }),
      shallowEqual
    );
  const accountId = selectedAccount?.id || "all";
  const isAdmin = profile?.userType === Role.Admin;

  const userAccount = profile?.userAccounts?.find(
    (account: any) => account.accountId === accountId
  );
  const isManager =
    userAccount?.userType === AccountUserType.Manager || userAccount?.isOwner;

  const sortingBy = (fields: string): void => {
    if (["actions", "sms", "mms"].includes(fields)) return;

    const containsDirections = fields.includes("-");
    const sortByField = containsDirections ? fields.split("-")[1] : fields;
    if (sortByField === sortBy) {
      if (containsDirections) {
        setSortBy(fields.replace("-", ""));
      } else {
        setSortBy(`-${fields}`);
      }
    } else {
      setSortBy(fields);
    }
  };
  console.log({ sortBy });

  const PHONE_KEY_PAIR = [
    {
      key: "checkbox",
    },
    {
      key: "account",
      label: "Account Name",
      hide: !isAdmin,
    },
    {
      label: "",
      key: "friendlyName",
      child: [
        {
          label: "Number",
          key: "friendlyName",
        },
      ],
    },
    {
      label: "Capabilities",
      key: "capabilities",
      child: [
        {
          label: "SMS",
          key: "sms",
        },
        {
          label: "MMS",
          key: "mms",
        },
      ],
    },
    {
      label: "",
      key: "addressRequirements",
      child: [
        {
          label: "Address Requirement",
          key: "addressRequirements",
        },
      ],
    },
    {
      label: "",
      key: "status",
      child: [
        {
          label: "Status",
          key: "status",
        },
      ],
    },
    {
      label: "",
      key: "actions",
      child: [
        {
          label: "Action",
          key: "actions",
        },
      ],
    },
  ];
  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (type === "purchased") {
      const params = {
        type,
        skip: 0,
        take: event.target.value,
        token: cancelToken.current.token,
        sortByField: sortBy,
      };
      dispatch(getPhoneNumbers(params, selectedAccount?.id, type));
    }
    setPage(0);
  };
  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
    if (type === "purchased") {
      const skip = newPage * rowsPerPage;
      const params = {
        type,
        skip,
        take: rowsPerPage,
        sortByField: sortBy,
      };
      dispatch(getPhoneNumbers(params, selectedAccount?.id, type));
    }
  };

  const fetchPhoneNumbers = async (): Promise<void> => {
    try {
      if (cancelToken.current) {
        cancelToken.current = axios.CancelToken.source();
      }
      const params = {
        numberType: ["local"],
        countryCode: "CA",
        smsEnabled: true,
        faxEnabled: true,
        mmsEnabled: true,
        voiceEnabled: true,
        take: rowsPerPage,
        token: cancelToken.current.token,
        sortByField: sortBy,
      };

      await dispatch(getPhoneNumbers(params, selectedAccount?.id, type));
    } catch (err) {
      dispatch(
        setSimpleValue("snackbar", {
          message: "No result found",
          type: "error",
        })
      );
    }
  };

  const purchasePhoneNumber = async (phone: any): Promise<void> => {
    setShowBlocker(true);
    try {
      const params = {
        phoneNumber: phone.phoneNumber,
        friendlyName: phone.friendlyName,
        capabilities: phone.capabilities,
        addressRequirement: phone.addressRequirement,
      };

      const result: any = await dispatch(
        buyPhoneNumber(params, selectedAccount?.id)
      );

      if (result.status === PhoneNumberStatus.WaitingForConfirmation) {
        dispatch(
          setSimpleValue("snackbar", {
            message: "Number will be purchased once manager approved it",
            type: "success",
          })
        );
      } else if (result.status === PhoneNumberStatus.Available) {
        dispatch(
          setSimpleValue("snackbar", {
            message: "You have purchased the phone number",
            type: "success",
          })
        );
      }
    } catch (err: any) {
      dispatch(
        setSimpleValue("snackbar", {
          message: `Purchase unsuccessful: ${err?.message}`,
          type: "error",
        })
      );
    }
    setShowBlocker(false);
  };

  const capabilitiesIcon = (key: any): JSX.Element => {
    const ICON_ENUM: any = {
      SMS: faSms,
      MMS: faCommentDots,
      sms: faSms,
      mms: faCommentDots,
      voice: faPhoneAlt,
      fax: faFax,
    };

    return (
      <FontAwesomeIcon
        icon={ICON_ENUM[key]}
        size="1x"
        fixedWidth
        color="rgba(0, 0, 0, 0.54)"
      />
    );
  };
  const handleAddPhoneGroup = async (): Promise<void> => {
    try {
      const pg: any = phoneGroup?.id
        ? await Promise.all(
            selectedPhones.map(async (phone) => {
              if (!phoneGroup?.id) return;
              if (!phone?.id) return;
              await dispatch(
                addPhoneGroupPhoneNumber(accountId, phoneGroup.id, phone.id)
              );
            })
          )
        : await dispatch(
            setPhoneGroup({
              accountId,
              description: UseCase.NotifyUsers,
              phoneNumbers: selectedPhones.filter(
                (x) => x.status === PhoneNumberStatus.Available
              ),
            })
          );
      dispatch(push(`/phone/groups/${phoneGroup?.id || pg.id || "new"}`));
    } catch (err) {
      dispatch(
        setSimpleValue("snackbar", {
          message: "Something went wrong",
        })
      );
    }
  };

  const container = document.getElementById("button-container");

  useEffect(() => {
    dispatch(setSimpleValue("title", "Phone Numbers"));
    return () => {
      dispatch(setSimpleValue("title", null));
    };
  }, []);

  useEffect(() => {
    cancelToken.current.cancel("cancelled");
    setSelectedPhones([]);

    return () => {
      if (type !== "purchased") {
        dispatch({ type: RESET_STATE });
      }
    };
  }, [type]);

  useEffect(() => {
    if (cancelToken.current) {
      cancelToken.current = axios.CancelToken.source();
    }
    dispatch(
      getPhoneNumbers(
        {
          ...searchPayload,
          token: cancelToken.current.token,
          sortByField: sortBy,
        },
        selectedAccount?.id,
        type
      )
    );
  }, [selectedAccount, searchPayload, type]);

  useEffect(() => {
    if (cancelToken.current) {
      cancelToken.current = axios.CancelToken.source();
    }
    if (type === "purchased") {
      dispatch(
        getPhoneNumbers(
          {
            ...searchPayload,
            token: cancelToken.current.token,
            sortByField: sortBy,
          },
          selectedAccount?.id,
          type
        )
      );
    }
  }, [sortBy]);

  // mass phone selected
  const handleSelectedPhone = (e: any, phone: any): void => {
    if (e.target.checked) {
      setSelectedPhones([...selectedPhones, phone]);
    } else {
      const updatedPhone = selectedPhones.filter(
        (x) => x.phoneNumber !== phone.phoneNumber
      );
      setSelectedPhones(updatedPhone);
    }
  };

  const handleCheckAllPhone = (e: any): void => {
    if (e.target.checked) {
      const selected: any = [];
      const phoneData = isSearchListing // phone search
        ? data.filter((x) => !x.purchased)
        : data.filter((x) => x.status === PhoneNumberStatus.Available);

      phoneData.forEach((o) => {
        selected.push(o);
      });
      setSelectedPhones(selected);
    } else {
      setSelectedPhones([]);
    }
  };

  const massBuyNumbers = async (): Promise<void> => {
    setShowBlocker(true);

    try {
      const params: any = {
        phoneNumbers: selectedPhones,
      };

      const response: any = await dispatch(
        massBuyPhoneNumber(params, selectedAccount?.id)
      );
      setSelectedPhones([]);

      let totalPending = 0;
      let totalPurchased = 0;
      let message = "";

      if (Array.isArray(response)) {
        response.forEach((phone: IPhoneNumber) => {
          if (phone.status === PhoneNumberStatus.WaitingForConfirmation) {
            totalPending += 1;
          }

          if (phone.status === PhoneNumberStatus.Available) {
            totalPurchased += 1;
          }
        });

        if (totalPending > 0) {
          message = `${totalPending} Phone Number will be purchased once manager approved it`;
        }

        if (totalPurchased > 0 && totalPending > 0) {
          message = `${message}. And a total of ${totalPurchased} have purchased.`;
        } else if (totalPurchased > 0) {
          message = `You have purchased ${totalPurchased} phone number`;
        }

        dispatch(
          setSimpleValue("snackbar", {
            message,
            type: "success",
          })
        );
      }
    } catch (err: any) {
      dispatch(
        setSimpleValue("snackbar", {
          message: `Purchase unsuccessful: ${
            err?.data?.message || err?.message
          }`,
          type: "error",
        })
      );
    }
    setShowBlocker(false);
  };
  const waiting = selectedPhones?.filter(
    (o) => o.status === PhoneNumberStatus.WaitingForConfirmation
  );
  const available = selectedPhones?.filter(
    (o) => o.status === PhoneNumberStatus.Available
  );
  const updateStatus = async (
    phoneData: any,
    status: PhoneNumberStatus,
    additionalInfo: any = {}
  ): Promise<void> => {
    await dispatch(
      update(accountId, phoneData.id, {
        status,
        ...additionalInfo,
      })
    );
    fetchPhoneNumbers();
  };
  const handleApprovePhoneNumber = (): void => {
    setDialog({
      confirmTitle: "Approve Phone Numbers",
      confirmMessage: `Are you sure you want to approve ${waiting.length} phone numbers?`,
      onConfirm: async (e: any, value: any) => {
        await Promise.all(
          waiting.map(async (phone) => {
            await updateStatus(phone, PhoneNumberStatus.Available, value);
          })
        );

        setSelectedPhones([]);
        setDialog({});
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "Cancel",
      onCancel: () => setDialog({}),
    });
  };
  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };
  const release = async (phoneData: any): Promise<void> => {
    await dispatch(remove(accountId, phoneData.id));
    fetchPhoneNumbers();
  };
  const handleClose = (event: Event): void => {
    if (buttonGroupRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  const options = [`Delete all`];
  const handleMenuItemClick = (
    event: MouseEvent<HTMLLIElement>,
    index: number
  ): void => {
    if (["Delete all"].includes(options[index])) {
      setDialog({
        confirmTitle: "Delete Phone Number",
        confirmMessage: `Are you sure you want to delete ${available.length} phone numbers?`,
        onConfirm: async (e: any, value: any) => {
          await Promise.all(available.map((phone) => release(phone)));
          setSelectedPhones([]);
          setDialog({});
        },
        onClose: () => setDialog({}),
        isDialogOpen: true,
        hideButton: false,
        acceptText: "Yes",
        cancelText: "Cancel",
        onCancel: () => setDialog({}),
      });
    }
  };
  const allChecked = data.every((o) =>
    selectedPhones.find((o2) => o2.phoneNumber === o.phoneNumber)
  );

  const searchData = (phoneData: IPhoneNumber[]): any => {
    const direction = sortBy.includes("-") ? "desc" : "asc";
    const sortByField = sortBy.replace("-", "");

    const filteredData =
      sortByField !== ""
        ? orderBy(phoneData, [sortByField], [direction])
        : phoneData;
    return filteredData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  return (
    <Paper className={classes.root}>
      <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={showBlocker}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {container &&
        selectedPhones?.length > 0 &&
        !isSearchListing &&
        ReactDOM.createPortal(
          <>
            {available?.length ? (
              <>
                <ButtonGroup
                  style={{ margin: 8 }}
                  variant="contained"
                  ref={(node) => {
                    anchorRef(node);
                    if (node !== null) buttonGroupRef.current = node;
                  }}
                  aria-label="split button"
                >
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={handleAddPhoneGroup}
                  >
                    Create Phone Group for {available.length} selected
                  </Button>

                  <Button
                    color="error"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  anchorEl={buttonGroupRef.current}
                  role={undefined}
                  transition
                  style={{ zIndex: 9999 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            dense
                            id="split-button-menu"
                            sx={{ minWidth: width }}
                          >
                            {options.map((option, index) => (
                              <MenuItem
                                dense
                                key={option}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            ) : null}
            {waiting?.length && (isManager || isAdmin) ? (
              <Button
                style={{ margin: 8 }}
                size="small"
                color="secondary"
                variant="contained"
                onClick={handleApprovePhoneNumber}
              >
                Approve all {waiting.length} selected
              </Button>
            ) : null}
          </>,
          container
        )}
      <TableContainer>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {PHONE_KEY_PAIR.filter((o) => !o.hide).map((pair, index) => {
                const totalIndex = index === PHONE_KEY_PAIR.length - 2;
                if (pair.key === "capabilities") {
                  return (
                    <TableCell key={pair.key} align="center" colSpan={2}>
                      {pair.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={pair.key}
                    align="center"
                    style={{ borderBottom: "0" }}
                  >
                    {totalIndex && isSearchListing ? (
                      <div style={{ marginLeft: "20%" }}>
                        <Button
                          variant="contained"
                          style={{ paddingLeft: 20 }}
                          onClick={massBuyNumbers}
                          disabled={selectedPhones.length === 0}
                        >
                          buy all {selectedPhones.length} selected
                        </Button>
                      </div>
                    ) : (
                      <SortingFields
                        exclude={["actions", "sms", "mms"]}
                        label={pair.label}
                        currentSortField={sortBy}
                        field={pair.key}
                        onClick={() => sortingBy(pair.key)}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {PHONE_KEY_PAIR.filter((o) => !o.hide).map(
                ({ key, child = [] }, index) => {
                  if (key === "capabilities") {
                    return child.map((c) => {
                      return (
                        <TableCell
                          key={`${c.key}-child1`}
                          align="center"
                          style={{ top: 57 }}
                        >
                          <SortingFields
                            exclude={["actions", "sms", "mms"]}
                            label={c.label}
                            currentSortField={sortBy}
                            field={c.key}
                            onClick={() => sortingBy(c.key)}
                          />
                        </TableCell>
                      );
                    });
                  }
                  if (key === "status") {
                    return child.map((c) => {
                      return type === "purchased" ? (
                        <TableCell key={`${c.key}-child2`} align="center">
                          <SortingFields
                            exclude={["actions", "sms", "mms"]}
                            label={c.label}
                            currentSortField={sortBy}
                            field={c.key}
                            onClick={() => sortingBy(c.key)}
                          />
                        </TableCell>
                      ) : null;
                    });
                  }
                  return (
                    <TableCell
                      key={`${child[0]?.key || index}-child3`}
                      align="center"
                      style={{ top: 57 }}
                      colSpan={child[0]?.label === "Action" ? 2 : 1}
                      width={index === 0 ? 40 : undefined}
                    >
                      {index === 0 ? (
                        <Checkbox
                          onClick={handleCheckAllPhone}
                          checked={allChecked}
                        />
                      ) : (
                        <SortingFields
                          exclude={["actions", "sms", "mms"]}
                          label={child[0]?.label}
                          currentSortField={sortBy}
                          field={child[0]?.key}
                          onClick={() => sortingBy(child[0]?.key)}
                        />
                      )}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              (type !== "purchased" && rowsPerPage > 0
                ? searchData(data)
                : data
              ).map((phone: any) => (
                <TableRow key={`row-${phone.phoneNumber}`}>
                  {PHONE_KEY_PAIR.filter((o) => !o.hide).map(
                    ({ key, child = [] }, listIndex: number) => {
                      if (key === "capabilities") {
                        return child.map((c) => {
                          return (
                            <TableCell
                              key={`${c.key}-${phone.id}`}
                              align="center"
                            >
                              {(phone.capabilities?.[c.key] ||
                                phone.capabilities?.[c.key.toUpperCase()]) &&
                                capabilitiesIcon(c.key)}
                            </TableCell>
                          );
                        });
                      }
                      if (key === "actions") {
                        return type === "purchased" ? (
                          <TableCell key={`${key}-${phone.id}`} align="center">
                            <PhoneActions
                              phone={phone}
                              reFetchPhoneNumbers={() => fetchPhoneNumbers()}
                            />
                          </TableCell>
                        ) : (
                          <TableCell
                            key={`${key}-${phone.id}`}
                            align="center"
                            colSpan={2}
                          >
                            {phone.purchased ? (
                              <Button variant="contained" color="success">
                                Purchased
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={() => purchasePhoneNumber(phone)}
                              >
                                buy
                              </Button>
                            )}
                          </TableCell>
                        );
                      }
                      if (
                        key === "checkbox" &&
                        (isSearchListing ||
                          phone.status ===
                            PhoneNumberStatus.WaitingForConfirmation ||
                          phone.status === PhoneNumberStatus.Available)
                      ) {
                        return (
                          <TableCell
                            key={`${key}-${phone.phoneNumber}`}
                            align="center"
                          >
                            <Checkbox
                              onClick={() => {
                                const newSelected: IPhoneNumber[] = [
                                  ...selectedPhones,
                                ];
                                const index = newSelected.findIndex(
                                  (o) => o.phoneNumber === phone.phoneNumber
                                );
                                if (index === -1) {
                                  newSelected.push(phone);
                                } else {
                                  newSelected.splice(index, 1);
                                }
                                setSelectedPhones(newSelected);
                              }}
                              checked={
                                selectedPhones.findIndex(
                                  (o) => o.phoneNumber === phone.phoneNumber
                                ) !== -1
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (key === "status" && type !== "purchased") {
                        return null;
                      }

                      if (key === "status" && type === "purchased") {
                        const statusLabels: any = {
                          [PhoneNumberStatus.WaitingForConfirmation]:
                            "Waiting for Approval",
                          [PhoneNumberStatus.Assigned]: "Number in use",
                          [PhoneNumberStatus.Available]: "Available",
                          [PhoneNumberStatus.Released]: "Released",
                          [PhoneNumberStatus.Rejected]: "Rejected",
                        };
                        return (
                          <TableCell key={`${key}-${phone.id}`} align="center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              {statusLabels[phone.status]}{" "}
                              {PhoneNumberStatus.Rejected === phone.status && (
                                <Tooltip
                                  title={`Rejection Reason: ${phone.reason}`}
                                >
                                  <IconInfo color="warning" fontSize="small" />
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        );
                      }
                      const value =
                        key === "account" ? phone?.account?.name : phone[key];
                      return (
                        <TableCell key={`${key}-${phone.id}`} align="center">
                          {listIndex === 0 &&
                          isSearchListing &&
                          !phone?.purchased ? (
                            <Checkbox
                              checked={
                                selectedPhones.filter(
                                  (x) => x.phoneNumber === phone.phoneNumber
                                ).length > 0
                              }
                              onClick={(e) => handleSelectedPhone(e, phone)}
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              ))
            ) : (
              <TableRowNoRows colSpan={7 + (isAdmin ? 1 : 0)} />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                // rowsPerPageOptions={[
                //   2,
                //   10,
                //   25,
                //   50,
                //   100,
                //   { label: "All", value: -1 },
                // ]}
                colSpan={7 + (isAdmin ? 1 : 0)}
                count={type === "purchased" ? total : data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AuthenticateDialog {...dialog} />
    </Paper>
  );
};

export default PhoneListing;
