import { Dispatch } from "redux";
import { patch, get, post, del } from "../../utils/requests";
import {
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_REQUESTS,
  SAVING,
  DELETE_CAMPAIGN_REQUEST,
} from "./constants";
import { ICampaignRequest, ICampaignRequestAction } from "./interface";

export const setCampaignRequests = (
  data: ICampaignRequest[]
): ICampaignRequestAction => ({
  type: FETCH_CAMPAIGN_REQUESTS.SUCCESS,
  payload: {
    data,
  },
});

export const setCampaignRequest = (
  data?: ICampaignRequest,
  isParent = false
): ICampaignRequestAction => {
  return {
    type: FETCH_CAMPAIGN_REQUEST.SUCCESS,
    payload: {
      data,
      isParent,
    },
  };
};

export const removeCampaignRequest = (id: string): ICampaignRequestAction => ({
  type: DELETE_CAMPAIGN_REQUEST.SUCCESS,
  payload: {
    id,
  },
});

export const fetchCampaignRequests =
  ({
    accountId,
    campaignId,
    logType,
    take = 10,
    skip = 0,
  }: {
    accountId: string;
    campaignId?: string;
    take?: number;
    skip?: number;
    logType?: "inbounds" | "outbounds";
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FETCH_CAMPAIGN_REQUESTS.REQUEST });
      const response = await get(
        campaignId
          ? `/accounts/${accountId}/campaigns/${campaignId}/requests`
          : `/accounts/${accountId}/campaigns/${logType}`,
        {
          take,
          skip,
        }
      );

      dispatch(setCampaignRequests(response.data.results));
    } catch {
      dispatch({ type: FETCH_CAMPAIGN_REQUESTS.FAILED });
    }
  };

export const fetchCampaignRequest =
  (accountId: string, id: string, isParent = false) =>
  async (dispatch: Dispatch): Promise<ICampaignRequest | null> => {
    try {
      dispatch({ type: FETCH_CAMPAIGN_REQUEST.REQUEST });
      const response = await get(
        `/accounts/${accountId}/campaigns/request/${id}`
      );

      dispatch(setCampaignRequest(response.data, isParent));
      return response.data;
    } catch {
      dispatch({ type: FETCH_CAMPAIGN_REQUEST.FAILED });
      return null;
    }
  };

export const createCampaignRequest =
  (accountId: string, campaignRequest: ICampaignRequest) =>
  async (dispatch: Dispatch): Promise<ICampaignRequest | null> => {
    try {
      dispatch({ type: SAVING.REQUEST });
      const response = await post(
        `/accounts/${accountId}/campaigns/request`,
        campaignRequest
      );

      dispatch(setCampaignRequest(response.data));
      return response.data;
    } catch (err) {
      dispatch({ type: SAVING.FAILED, payload: { errors: err } });
      throw err;
    }
  };

export const updateCampaignRequest =
  (campaign: ICampaignRequest) =>
  async (dispatch: Dispatch): Promise<ICampaignRequest | null> => {
    try {
      dispatch({ type: SAVING.REQUEST });
      const response = await patch(
        `/accounts/${campaign.accountId}/campaigns/${campaign.id}`,
        campaign
      );

      dispatch(setCampaignRequest(response.data));
      return response.data;
    } catch (err) {
      dispatch({ type: SAVING.FAILED, payload: { errors: err } });
      return null;
    }
  };

export const deleteCampaignRequest =
  (accountId: string, id: string) =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      if (id) {
        dispatch({ type: DELETE_CAMPAIGN_REQUEST.REQUEST });
        await del(`/accounts/${accountId}/campaigns/${id}`);

        dispatch(removeCampaignRequest(id));
        return true;
      }
      return false;
    } catch (err) {
      dispatch({
        type: DELETE_CAMPAIGN_REQUEST.FAILED,
        payload: { errors: err },
      });
      return false;
    }
  };
