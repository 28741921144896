import { ChangeEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import numeral from "numeral";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  IconButton,
  Paper,
  CircularProgress,
} from "@mui/material";

import { Link } from "react-router-dom";
import { Delete, Edit, Save } from "@mui/icons-material";
import CustomInput from "components/CustomInput";
import { formatDate } from "utils/utilities";
import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { Column } from "../../../common/common.interface";
import { remove, getFiles, update } from "../actions";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";

const columns: Column[] = [
  { id: "path", label: "Original Filename", minWidth: 170 },
  { id: "filename", label: "Friendly Name", minWidth: 170 },
  { id: "fileType", label: "File Type", minWidth: 100 },
  { id: "fileSize", label: "File Size", minWidth: 100 },
  { id: "createdAt", label: "Date Created", minWidth: 100 },
  { id: "creator", label: "Created By", minWidth: 100 },
  {
    id: "actions",
    label: "",
    minWidth: 50,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
  title: {
    position: "relative",
    padding: "16px",
  },
  new: {
    float: "right",
    marginLeft: 8,
  },
  cell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

const Files = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [editId, setEditId] = useState(null);
  const [dialog, setDialog] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { selectedAccount, files, total } = useSelector(
    (state: IRootReducer) => ({
      files: state.files.files,
      total: state.files.total,
      selectedAccount: state.session.selectedAccount,
    })
  );

  const fetch = (skip = 0, take = 10): void => {
    if (selectedAccount?.id) {
      dispatch(getFiles(selectedAccount.id, skip, take));
    }
  };
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    fetch(skip, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetch(0, newRowsPerPage);
  };

  const handleDelete = (file: any): void => {
    setDialog({
      confirmTitle: "Delete Account File",
      confirmMessage: "Are you sure you want to delete this account file?",
      onConfirm: async () => {
        await dispatch(remove(file.id, file.accountId));
        setDialog({});
        fetch();
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
    });
  };
  // const handleDownload = (file: IFile) => () => {
  //   console.log("selectedAccount", selectedAccount);
  //   if (selectedAccount?.id) {
  //     window.open(
  //       `/api/accounts/${selectedAccount.id}/files/${file.id}/${file.filename}`,
  //       "_blank"
  //     );
  //   }
  // };

  const getCell = (file: any): JSX.Element[] => {
    return columns.map((column: any) => {
      let value: any = file?.[column.id];

      switch (column.id) {
        case "creator":
          value = `${file.creator.firstName || ""} ${
            file.creator.lastName || ""
          }`.trim();
          break;
        case "createdAt":
          value = formatDate(file.createdAt).fromNow();
          break;
        case "fileSize":
          value = numeral(value).format("0.0 b");
          break;
        default:
      }

      const edit = editId ? <Save /> : <Edit />;
      return column.id === "actions" ? (
        <TableCell key={column.id} align={column.align}>
          {/* <IconButton
            disabled={file.isOwner}
            aria-label="download"
            onClick={handleDownload(file)}
          >
            <Download />
          </IconButton> */}
          <IconButton
            disabled={file.isOwner}
            aria-label="delete"
            onClick={() => handleDelete(file)}
          >
            <Delete />
          </IconButton>
        </TableCell>
      ) : (
        <TableCell key={column.id} align={column.align}>
          <div className={classes.cell}>
            {editId === file?.id && column.id === "filename" ? (
              <CustomInput
                size="small"
                placeholder="My List"
                type="text"
                value={editId ? newValue : value}
                fullWidth
                onChange={(e) => {
                  setNewValue(e.target.value);
                }}
              />
            ) : (
              value
            )}
            {column.id === "filename" && (
              <IconButton
                disabled={loading}
                size="small"
                onClick={async () => {
                  if (!editId) {
                    setNewValue(value);
                    setEditId(file?.id);
                  } else if (file?.id && selectedAccount?.id) {
                    setLoading(true);
                    await dispatch(
                      update(file.id, selectedAccount.id, newValue)
                    );
                    setNewValue("");
                    setEditId(null);
                    setLoading(false);
                  }
                }}
              >
                {loading ? <CircularProgress size="small" /> : edit}
              </IconButton>
            )}
          </div>
        </TableCell>
      );
    });
  };
  useEffect(() => {
    fetch();
  }, [selectedAccount]);
  return (
    <Paper className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span>Account Files</span>
        <Button
          component={Link}
          to="/files/upload"
          variant="contained"
          color="primary"
          className={classes.new}
        >
          Upload Files
        </Button>
      </Typography>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.length ? (
              files.map((file) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={file?.id}>
                    {getCell(file)}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows colSpan={7} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmDialog {...dialog} />
    </Paper>
  );
};

export default Files;
