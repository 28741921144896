import {
  Avatar,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { setSimpleValue } from "modules/Main/actions";
import { useEffect, useState, ChangeEvent, MouseEvent } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { IRootReducer } from "reducers/reducer.interface";
import TableRowNoRows from "components/NoRows/TableRowNoRows";
import TablePaginationActions from "modules/Phone/components/TablePaginationActions";
import AuthenticateDialog from "components/AuthenticateDialog/AuthenticateDialog";
import {
  PhoneGroupStatus,
  PhoneNumberStatus,
  PHONE_STATUS_LABELS,
  STATUS_LABELS,
} from "modules/Phone/interface";
import {
  getPhoneGroups,
  getPhoneNumbers,
  update,
  updatePhoneGroup,
} from "modules/Phone/actions";
import { uniqBy, orderBy } from "lodash";
import { fetchNotifications, deleteNotification } from "../actions";
import { TABLE_HEADERS } from "../constants";
import { INotification, NotificationType } from "../interface";
import { getType } from "./Notification";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

interface IProps {
  approval?: boolean;
  fromDashboard?: boolean;
}

const Listing = ({ approval, fromDashboard }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialog, setDialog] = useState({});

  const hasAction = [
    NotificationType.PhoneCreation,
    NotificationType.PhoneGroup,
    NotificationType.ApprovePhoneGroup,
    NotificationType.CampaignCreation,
  ];

  const { _notifications, _total } = useSelector(
    (s: IRootReducer) => ({
      _notifications: orderBy(
        uniqBy(
          [...s.notifications.notifications, ...s.notifications.approvals],
          "recordId"
        ),
        ["createdAt"],
        ["desc"]
      ),
      _total: s.notifications.total,
    }),
    shallowEqual
  );
  const notifications = !fromDashboard
    ? _notifications
    : _notifications.filter((x) => hasAction.includes(x.type));
  const total = !fromDashboard ? _total : notifications.length;

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(setSimpleValue("title", "Notifications"));
    dispatch(fetchNotifications(fromDashboard ? 20 : 10, 0, approval));
  }, []);

  const fetchPhoneNumbers = async (accountId: string): Promise<void> => {
    try {
      const params = {
        numberType: ["local"],
        countryCode: "CA",
        smsEnabled: true,
        faxEnabled: true,
        mmsEnabled: true,
        voiceEnabled: true,
      };
      await dispatch(getPhoneNumbers(params, accountId, "purchased"));
    } catch (err) {
      dispatch(
        setSimpleValue("snackbar", {
          message: "No result found",
          type: "error",
        })
      );
    }
  };

  const updatePhoneStatus = (
    notification: INotification,
    status: PhoneNumberStatus
  ): void => {
    setDialog({
      confirmTitle: `${PHONE_STATUS_LABELS[status]} phone number?`,
      confirmMessage: `Are you sure you want to ${PHONE_STATUS_LABELS[
        status
      ]?.toLowerCase()} this phone number?`,
      onConfirm: async () => {
        try {
          if (!notification.recordId || !notification?.accountId) return;
          await dispatch(
            update(notification.accountId, notification.recordId, {
              status,
            })
          );
          fetchPhoneNumbers(notification?.accountId);
          if (notification?.id) dispatch(deleteNotification(notification.id));
          setDialog({});
        } catch (err) {
          dispatch(setSimpleValue("snackbar", { message: err.message }));
        }
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
      type: getType(status),
    });
  };
  const updatePhoneGroupStatus = (
    notification: INotification,
    status: PhoneGroupStatus
  ): void => {
    setDialog({
      confirmTitle: `${STATUS_LABELS[status]}`,
      confirmMessage: `Are you sure you want to ${STATUS_LABELS[
        status
      ]?.toLowerCase()}`,
      onConfirm: async () => {
        try {
          if (!notification.recordId || !notification?.accountId) return;
          await dispatch(
            updatePhoneGroup(
              notification.accountId,
              notification.recordId,
              {
                status,
              },
              true
            )
          );
          const statuses: any = Object.keys(PhoneGroupStatus);
          await dispatch(
            getPhoneGroups(notification.accountId, 0, 10, statuses)
          );
          // if (notification?.id) dispatch(deleteNotification(notification.id));
          setDialog({});
        } catch (err) {
          dispatch(setSimpleValue("snackbar", { message: err.message }));
        }
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
      type: getType(status),
    });
  };

  const handleAccept = (notification: INotification) => () => {
    if (notification.type === NotificationType.PhoneCreation) {
      updatePhoneStatus(notification, PhoneNumberStatus.Available);
    } else if (notification.type === NotificationType.PhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.PendingApproval);
    } else if (notification.type === NotificationType.ApprovePhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.Approved);
    }
  };
  const handleReject = (notification: INotification) => () => {
    if (notification.type === NotificationType.PhoneCreation) {
      updatePhoneStatus(notification, PhoneNumberStatus.Rejected);
    } else if (notification.type === NotificationType.PhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.OnHold);
    } else if (notification.type === NotificationType.ApprovePhoneGroup) {
      updatePhoneGroupStatus(notification, PhoneGroupStatus.Draft);
    }
  };

  const renderRows = (
    notif: INotification,
    key: string
  ): JSX.Element | null => {
    const { mentioned } = notif;
    const name = `${mentioned?.firstName} ${mentioned?.lastName}`;
    switch (key) {
      case "profile-pic-notification":
        return (
          <TableCell key={`${key}-${notif.id}`} align="center">
            <Grid container justifyContent="center" alignItems="center">
              <Grid>
                <Avatar
                  alt={`${name}`}
                  // src={mentioned?.profile_pic}
                  sx={{ margin: "auto" }}
                />
              </Grid>
              <Grid>
                <Typography
                  variant="body1"
                  display="inline"
                  gutterBottom
                  sx={{ marginLeft: "10px" }}
                >
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        );

      case "message-notification":
        return (
          <TableCell key={`${key}-${notif.id}`} align="center">
            <Typography
              variant="subtitle1"
              display="block"
              gutterBottom
              align="center"
            >
              {
                {
                  [NotificationType.PhoneCreation]: "Phone Creation",
                  [NotificationType.PhoneGroup]: "Phone Group",
                  [NotificationType.CampaignCreation]: "Campaign Creation",
                  [NotificationType.ApprovePhoneCreation]:
                    "Approve Phone Creation",
                  [NotificationType.ApprovePhoneGroup]: "Approve Phone Group",
                  [NotificationType.ApprovedPhoneGroup]: "Approved Phone Group",
                  [NotificationType.ApproveCampaign]: "Approve Campaign",
                  [NotificationType.RejectPhoneCreation]:
                    "Reject Phone Creation",
                  [NotificationType.RejectPhoneGroup]: "Reject Phone Group",
                  [NotificationType.RejectCampaign]: "Reject Campaign",
                }[notif.type]
              }
            </Typography>
            <Typography
              variant="body2"
              display="block"
              gutterBottom
              align="center"
            >
              {notif.message}
            </Typography>
          </TableCell>
        );

      case "actions-notification":
        return (
          <TableCell key={`${key}-${notif.id}`} align="center">
            {[
              NotificationType.PhoneCreation,
              NotificationType.PhoneGroup,
              NotificationType.ApprovePhoneGroup,
              NotificationType.CampaignCreation,
            ].includes(notif.type) && (
              <Box>
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={handleReject(notif)}
                >
                  Reject
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ marginLeft: "10px" }}
                  onClick={handleAccept(notif)}
                >
                  Accept
                </Button>
              </Box>
            )}
          </TableCell>
        );

      default:
        return null;
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map((head) => {
                return (
                  <TableCell key={head.key} align="center">
                    {head.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.length ? (
              notifications.map((notif: any) => (
                <TableRow key={`row-${notif.id}`}>
                  {TABLE_HEADERS.map(({ key }) => {
                    return renderRows(notif, key);
                  })}
                </TableRow>
              ))
            ) : (
              <TableRowNoRows colSpan={7} />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={7}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AuthenticateDialog {...dialog} />
    </Paper>
  );
};

export default Listing;
