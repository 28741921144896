import { memoize } from "lodash";
import { IAccountUser } from "../AccountUsers/interface";
import { FormattedPermission, IPermission, Role } from "./interface";

export const hasAccess = (
  accountUser?: IAccountUser,
  permissionRequired?: string[],
  userType?: Role
): boolean => {
  return (
    userType === Role.Admin ||
    accountUser?.isOwner === true ||
    permissionRequired?.every(
      (requiredPermission) =>
        !!accountUser?.role?.permissions?.find(
          (permission) =>
            requiredPermission === permission.permission?.name &&
            permission.value === true
        )
    ) ||
    !permissionRequired
  );
};

export const formatPermission = memoize(
  (permissions: IPermission[]): FormattedPermission[] => {
    if (!permissions) return [];
    const parentPermissions: FormattedPermission[] = [];
    const childPermissions: FormattedPermission[] = [];
    // console.log({ permissions });
    permissions.forEach((p) => {
      const permission = p;
      if (!permission.parentId) {
        parentPermissions.push({ ...permission, permissions: [] });
      } else {
        childPermissions.push({ ...permission, permissions: [] });
      }
    });

    childPermissions.forEach((p: FormattedPermission) => {
      parentPermissions.map((data: FormattedPermission): any => {
        if (data.id === p.parentId) {
          return {
            ...data,
            permissions: data.permissions.push(p),
          };
        }
        return data;
      });
    });
    return parentPermissions;
  }
);
