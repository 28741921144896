import { createSlice } from "@reduxjs/toolkit";
import { NAME } from "./constants";
import { IWebhookRequestLogs } from "./interface";

const initialState: IWebhookRequestLogs = {
  data: [],
  total: 0,
  loading: false,
  error: false,
};

export const webhookRequestLogs = createSlice({
  name: NAME,
  initialState,
  reducers: {
    startLoading: (state: IWebhookRequestLogs) => {
      state.loading = true;
    },
    fetchedWebhooksLogs: (state: IWebhookRequestLogs, action) => {
      state.loading = false;
      state.data = action.payload.results;
      state.total = action.payload.total;
    },
    fetchedWebhooksError: (state: IWebhookRequestLogs, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

// Actions
export const { startLoading, fetchedWebhooksLogs, fetchedWebhooksError } =
  webhookRequestLogs.actions;

// Reducer
export const { reducer } = webhookRequestLogs;
