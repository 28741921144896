import { createActionTypes } from "../../utils/utilities";
import { ICampaignRequestState } from "./interface";

export const NAME = "campaignRequest";
export const FETCH_CAMPAIGN_REQUESTS = createActionTypes(
  `${NAME}/FETCH_CAMPAIGN_REQUESTS`
);
export const FETCH_CAMPAIGN_REQUEST = createActionTypes(
  `${NAME}/FETCH_CAMPAIGN_REQUEST`
);
export const SAVING = createActionTypes(`${NAME}/SAVING`);
export const DELETE_CAMPAIGN_REQUEST = createActionTypes(
  `${NAME}/DELETE_CAMPAIGN_REQUEST`
);

export const INITIAL_STATE: ICampaignRequestState = {
  data: [],
  fetching: false,
  campaignRequest: null,
  errors: null,
  total: 0,
};
