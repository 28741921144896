import { ChangeEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { QuestionAnswer, Delete, EditOutlined } from "@mui/icons-material";

import { makeStyles } from "@mui/styles";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Paper,
} from "@mui/material";

import { formatDate } from "utils/utilities";
import { HtmlTooltip } from "components/Tooltip";
import TableRowNoRows from "../../../components/NoRows/TableRowNoRows";
import { IRootReducer } from "../../../reducers/reducer.interface";
import { Column } from "../../../common/common.interface";
import { remove, getContacts } from "../actions";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import UploadContact from "./UploadContact";

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "type", label: "Type", minWidth: 100, align: "center" },
  // { id: "status", label: "Status", minWidth: 100 },
  {
    id: "contactsCount",
    label: "Contacts Count",
    minWidth: 70,
    align: "center",
  },
  { id: "campaignlist", label: "Campaigns", minWidth: 100, align: "center" },
  { id: "createdAt", label: "Date Created", minWidth: 100 },
  { id: "creator", label: "Created By", minWidth: 100 },
  {
    id: "actions",
    label: "Actions",
    minWidth: 50,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: "1 1 0%",
  },
  title: {
    position: "relative",
    padding: "16px",
    display: "flex",
  },
  new: {
    float: "right",
    marginLeft: 8,
  },
});

const Contacts = (): JSX.Element => {
  const [dialog, setDialog] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { selectedAccount, lists, total } = useSelector(
    (state: IRootReducer) => ({
      lists: state.contacts.lists,
      total: state.contacts.total,
      selectedAccount: state.session.selectedAccount,
    })
  );

  const fetch = (skip = 0, take = 10): void => {
    if (selectedAccount?.id) {
      dispatch(getContacts(selectedAccount.id, skip, take));
    }
  };
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    fetch(skip, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetch(0, newRowsPerPage);
  };

  const handleEdit = (contact: any): void => {
    dispatch(push(`/contact/${contact.id}`));
  };
  const handleDelete = (contact: any): void => {
    setDialog({
      confirmTitle: "Delete Account Contact",
      confirmMessage: "Are you sure you want to delete this account contact?",
      onConfirm: async () => {
        await dispatch(remove(contact.id, contact.accountId));
        setDialog({});
        fetch();
      },
      onClose: () => setDialog({}),
      isDialogOpen: true,
      hideButton: false,
      acceptText: "Yes",
      cancelText: "No, Cancel",
      onCancel: () => setDialog({}),
    });
  };

  const getCell = (contact: any): JSX.Element[] => {
    return columns.map((column: any) => {
      let value: any = contact?.[column.id];

      if (column.id === "creator") {
        value = `${contact.creator.firstName || ""} ${
          contact.creator.lastName || ""
        }`.trim();
      } else if (column.id === "createdAt") {
        value = formatDate(contact.createdAt).fromNow();
      } else if (column.id === "campaignlist") {
        const connectedCampaign = contact.campaignContacts.length
          ? contact.campaignContacts.flatMap((c: any) => {
              if (
                c?.execution?.campaign &&
                !c?.execution?.campaign?.isArchived
              ) {
                return c.execution?.campaign?.name;
              }
              return [];
            })
          : [];

        if (connectedCampaign?.length > 1) {
          value = (
            <HtmlTooltip
              arrow
              title={
                <>
                  {connectedCampaign.map((list: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={index} color="inherit">
                      - {list}
                    </Typography>
                  ))}
                </>
              }
            >
              <QuestionAnswer />
            </HtmlTooltip>
          );
        } else {
          value =
            connectedCampaign.length > 0 ? connectedCampaign.join(", ") : "--";
        }
      }

      return column.id === "actions" ? (
        <TableCell key={column.id} align={column.align}>
          <IconButton aria-label="edit" onClick={() => handleEdit(contact)}>
            <EditOutlined />
          </IconButton>
          <IconButton
            disabled={contact.isOwner}
            aria-label="edit"
            onClick={() => handleDelete(contact)}
          >
            <Delete />
          </IconButton>
        </TableCell>
      ) : (
        <TableCell key={column.id} align={column.align}>
          {value}
        </TableCell>
      );
    });
  };
  useEffect(() => {
    fetch();
  }, [selectedAccount]);

  return (
    <Paper className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span style={{ flex: "1 1 0%" }}>Contact List Management</span>
        <UploadContact />
      </Typography>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lists.length ? (
              lists.map((list) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={list?.id}>
                    {getCell(list)}
                  </TableRow>
                );
              })
            ) : (
              <TableRowNoRows colSpan={6} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        colSpan={6}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmDialog {...dialog} />
    </Paper>
  );
};

export default Contacts;
