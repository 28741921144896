import { Dispatch } from "redux";
import { GET, CREATE, UPDATE, FETCH, DELETE, GET_CONTACTS } from "./constants";
import { del, get, patch, post } from "../../utils/requests";
import { ListType, IList, IListsAction } from "./interface";

export const create =
  (list: IList) =>
  async (dispatch: Dispatch): Promise<IList> => {
    dispatch({
      type: CREATE.REQUEST,
    });

    try {
      const response = await post(`/accounts/${list.accountId}/list`, list);

      dispatch({
        type: CREATE.SUCCESS,
        payload: { list: response.data },
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: CREATE.FAILED,
      });
      throw err;
    }
  };

export const update =
  (id: string, list: IList) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: UPDATE.SUCCESS,
    });

    try {
      const response = await patch(
        `/accounts/${list.accountId}/list/${id}`,
        list
      );

      dispatch({
        type: UPDATE.SUCCESS,
        payload: { list: response.data },
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: UPDATE.FAILED,
      });
      throw err;
    }
  };

export const set = (list?: Partial<IList>): IListsAction => ({
  type: FETCH.SUCCESS,
  payload: { list },
});

export const fetch =
  (id: string, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: FETCH.REQUEST,
    });

    try {
      const response = await get(`/accounts/${accountId}/list/${id}`);

      dispatch(set(response.data));

      return response;
    } catch (err) {
      dispatch({
        type: FETCH.FAILED,
      });
      throw err;
    }
  };

export const remove =
  (id: string, accountId: string | undefined) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: DELETE.REQUEST,
    });

    try {
      const response = await del(`/accounts/${accountId}/list/${id}`);

      dispatch({
        type: DELETE.SUCCESS,
        payload: { id },
      });

      return response;
    } catch (err) {
      dispatch({
        type: DELETE.FAILED,
      });
      throw err;
    }
  };

export const getContacts =
  (accountId: string | undefined, skip = 0, take = 10, listType?: ListType) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET.REQUEST,
    });

    try {
      const params = { skip, take, listType };
      const response = await get(`/accounts/${accountId}/list`, params);

      dispatch({
        type: GET.SUCCESS,
        payload: {
          lists: response.data.results,
          total: response.data.total,
        },
      });
    } catch (err) {
      dispatch({
        type: GET.FAILED,
        errors: err,
      });
    }
  };

export const getContactsList =
  (
    accountId: string | undefined,
    listId: string,
    skip = 0,
    take = 10,
    listType?: ListType
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET_CONTACTS.REQUEST,
    });

    try {
      const params = { skip, take, listType };
      console.log({ params });
      const response = await get(
        `/accounts/${accountId}/list/${listId}/contacts`,
        params
      );

      dispatch({
        type: GET_CONTACTS.SUCCESS,
        payload: {
          contacts: response.data.results,
          total: response.data.total,
          params,
        },
      });
    } catch (err) {
      dispatch({
        type: GET_CONTACTS.FAILED,
        errors: err,
      });
    }
  };
