import { createActionTypes } from "../../utils/utilities";
import { IPermissionState } from "./interface";

export const NAME = "permissions";
export const GET_PERMISSIONS = createActionTypes(`${NAME}/GET_PERMISSIONS`);

export const INITIAL_STATE: IPermissionState = {
  permissions: [],
  isFetching: false,
};
