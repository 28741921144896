import { lazy } from "react";

import { Permissions } from "../modules/Permissions/interface";

export const MainRoute = [
  {
    path: "/user",
    exact: true,
    component: lazy(() => import("../modules/AccountUsers")),
    key: "user",
    permissions: [Permissions.user_management],
    hasAccount: true,
  },
  {
    path: "/user/:id",
    exact: false,
    component: lazy(
      () => import("../modules/AccountUsers/components/AccountUserForm")
    ),
    key: "user-form",
    permissions: [],
    hasAccount: true,
  },
  {
    path: "/user",
    exact: true,
    component: lazy(() => import("../modules/User")),
    key: "user",
    permissions: [],
    hasAccount: false,
  },
  {
    path: "/user/:id",
    exact: false,
    component: lazy(() => import("../modules/User/components/UserForm")),
    key: "user-form",
    permissions: [],
    hasAccount: false,
  },
  {
    path: "/roles",
    exact: true,
    component: lazy(() => import("../modules/AccountUsers")),
    key: "roles",
    permissions: [Permissions.user_management],
    hasAccount: true,
  },
  {
    path: "/roles/:id",
    exact: true,
    component: lazy(() => import("../modules/Roles/components/RoleForm")),
    key: "roles",
    permissions: [Permissions.user_management],
    hasAccount: true,
  },
  {
    path: "/campaigns",
    exact: true,
    component: lazy(() => import("../modules/Campaigns")),
    key: "campaigns",
    permissions: [Permissions.campaign_management],
  },
  {
    path: "/campaigns/:id",
    exact: true,
    component: lazy(
      () => import("../modules/Campaigns/components/CampaignForm")
    ),
    key: "campaign-form",
    permissions: [Permissions.edit_campaign],
    fullSize: true,
  },
  {
    path: "/contact",
    exact: true,
    component: lazy(() => import("../modules/Contacts")),
    key: "contact",
    permissions: [Permissions.read_contacts],
    hasAccount: true,
  },
  {
    path: "/contact/:id",
    exact: true,
    component: lazy(() => import("../modules/Contacts/components/ContactForm")),
    key: "contact-form",
    permissions: [Permissions.edit_contacts],
    hasAccount: true,
    fullSize: true,
  },
  {
    path: "/files",
    exact: true,
    component: lazy(() => import("../modules/Files")),
    key: "files",
    permissions: [Permissions.read_files],
    hasAccount: true,
  },
  {
    path: "/files/:id",
    exact: true,
    component: lazy(() => import("../modules/Files/components/UploadFile")),
    key: "files-form",
    permissions: [Permissions.edit_files],
    hasAccount: true,
    fullSize: true,
  },
  {
    path: "/campaigns/:id/request/:campaignRequestId",
    exact: true,
    component: lazy(
      () => import("../modules/Campaigns/components/CampaignForm")
    ),
    key: "campaign-form",
    permissions: [Permissions.edit_campaign],
    hasAccount: true,
    fullSize: true,
  },
  {
    path: "/accounts",
    exact: true,
    component: lazy(() => import("../modules/Accounts")),
    key: "account",
    permissions: [Permissions.read_account],
  },
  {
    path: "/accounts/:id",
    exact: true,
    component: lazy(
      () => import("../modules/Accounts/components/AccountsLanding/AccountForm")
    ),
    key: "new-account",
    permissions: [Permissions.read_account],
  },
  {
    path: "/logs",
    exact: true,
    component: lazy(() => import("../modules/Logs")),
    key: "logs",
    hasAccount: false,
  },
  {
    path: "/logs",
    exact: true,
    component: lazy(() => import("../modules/Logs")),
    key: "logs",
    permissions: [Permissions.access_logs],
    hasAccount: true,
  },
  {
    path: "/reports",
    exact: true,
    component: lazy(() => import("../modules/Reports")),
    key: "reports",
    hasAccount: false,
  },
  {
    path: "/reports",
    exact: true,
    component: lazy(() => import("../modules/Reports")),
    key: "reports",
    permissions: [Permissions.access_reports],
    hasAccount: true,
  },
  {
    path: "/profile",
    exact: true,
    component: lazy(() => import("../modules/Profile")),
    key: "reports",
    hasAccount: true,
  },
  {
    path: "/phone/groups/:id",
    exact: true,
    component: lazy(() => import("../modules/Phone/components/PhoneGroup")),
    key: "phone-group",
  },
  {
    path: "/phone",
    exact: false,
    component: lazy(() => import("../modules/Phone")),
    key: "phones",
  },
  {
    path: "/notifications",
    exact: true,
    component: lazy(() => import("../modules/Notification/components/Listing")),
    key: "notifications",
    hasAccount: true,
  },
  {
    path: "/notification",
    exact: true,
    component: lazy(
      () => import("../modules/Notification/components/NotificationDetails")
    ),
    key: "notification-details",
    hasAccount: true,
  },
  {
    path: "/",
    exact: true,
    component: lazy(() => import("../modules/Dashboard")),
    key: "dashboard",
    permissions: [],
  },
];

export const routes = [
  {
    path: "/",
    exact: false,
    component: lazy(() => import("../modules/Main")),
    key: "home",
    isPrivate: true,
  },
  {
    path: "/signin",
    exact: true,
    component: lazy(() => import("../modules/Login")),
    key: "login",
  },
  {
    path: "/login-success",
    exact: true,
    component: lazy(() => import("../modules/Login/LoginSuccess")),
    key: "login-success",
  },
  {
    path: "/account/new",
    exact: true,
    component: lazy(() => import("../modules/Signup")),
    key: "signup",
  },
  {
    path: "/change-password",
    exact: true,
    component: lazy(() => import("../modules/ChangePassword")),
    key: "change-password",
  },
  {
    path: "/forgot-password",
    exact: true,
    component: lazy(() => import("../modules/ForgotPassword")),
    key: "forgot-password",
  },
];
