import {
  faAddressBook,
  faBuilding,
  faChartLine,
  faColumns,
  faExclamationCircle,
  faProjectDiagram,
  faUsers,
  faUsersCog,
  faFileImage,
  faPhoneSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Permissions } from "../Permissions/interface";
import { IMainState } from "./interface";

export const NAME = "main";

export const INITIAL_STATE: IMainState = {
  tabIndex: 0,
  toggleTab: false,
  tabs: [],
  simpleValues: {},
};

export const USER_LIST = [
  {
    path: "/accounts",
    name: "Accounts",
    key: "accounts",
  },
  {
    path: "/accounts/new",
    name: "Account",
    key: "account",
  },
];

export const MENU_LIST = [
  {
    path: "/",
    name: "Dashboard",
    icon: faColumns,
    key: "dashboard",
    permission: [Permissions.access_dashboard],
  },
  {
    path: "/phone/groups",
    name: "Phone Number Management",
    icon: faPhoneSquareAlt,
    key: "phone",
    permission: [Permissions.read_phone_group_numbers],
    tabs: true,
    accountSelectedRequired: false,
  },
  {
    path: "/contact",
    name: "Contact List Management",
    icon: faAddressBook,
    key: "contact",
    permission: [Permissions.read_contacts],
    tabs: true,
    accountSelectedRequired: true,
  },
  {
    path: "/files",
    name: "Files Management",
    icon: faFileImage,
    key: "files",
    permission: [Permissions.edit_files],
    tabs: true,
    accountSelectedRequired: true,
  },
  {
    path: "/campaigns",
    name: "Campaign Management",
    icon: faProjectDiagram,
    key: "campaigns",
    permission: [Permissions.campaign_management],
    accountSelectedRequired: false,
  },
  {
    path: "/reports",
    name: "Reporting",
    icon: faChartLine,
    key: "reports",
    permission: [Permissions.access_reports],
    tabs: true,
  },

  {
    path: "/accounts",
    name: "Account Management",
    icon: faBuilding,
    permission: [Permissions.edit_account],
    key: "accounts",
  },
  {
    path: "/user",
    name: "User Management",
    icon: faUsers,
    key: "user",
    permission: [Permissions.user_management],
    tabs: true,
  },
  {
    path: "/roles",
    name: "User Group Management",
    icon: faUsersCog,
    key: "roles",
    permission: [Permissions.edit_roles],
    tabs: true,
    accountSelectedRequired: true,
    visible: false,
  },

  {
    path: "/logs",
    name: "System Logs",
    icon: faExclamationCircle,
    key: "logs",
    permission: [Permissions.access_logs],
    tabs: true,
  },
];

export const SETTING_LIST = [
  // {
  //   path: "/accounts/settings",
  //   name: "User Group Management",
  //   icon: faUsersCog,
  //   key: "settings",
  //   permission: [Permissions.edit_roles],
  //   tabs: true,
  // },
];

/* TAB CONSTANT */
export const SET_TAB_INDEX = "SET_TAB_INDEX";
export const TOGGLE_TAB = "TOGGLE_TAB";
export const SET_SIMPLE_VALUE = "SET_SIMPLE_VALUE";
